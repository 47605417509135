import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  typography: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  // the idea of making the children of grid have 100% height came from this post
  // https://stackoverflow.com/questions/50743402/material-ui-grid-item-height
  fullHeight: {
    height: "100%",
    maxHeight: "800px",

    "& > div": {
      height: "100%",
      maxHeight: "800px",
    },
  },
  fullCalendar: {
    padding: "5px",
  },

  shape: {
    backgroundColor: theme.palette.primary.main,
    width: 20,
    height: 20,
  },
  shapeCircle: {
    borderRadius: '50%',
  },
}));
