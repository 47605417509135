import {useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Grid,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import {updateValuations, getUser} from "../../../actions/users";
import {getValuations} from "../../../actions/valuations";
import {resetLoading} from "../../../actions/general";

import {redirectScroll} from "../../../helpers/misc";

import useStyles from "../styles";

export default function UpdateValuations() {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const valuations = useSelector((state) => state.valuations);
  const user = useSelector((state) => state.user);

  const isLoading = useSelector((state) => state.loading?.UPDATE_USER_VALUATIONS?.request || false);
  const isSuccess = useSelector((state) => state.loading?.UPDATE_USER_VALUATIONS?.success || false);

  const userId = location.pathname.split("/").pop();

  const initialState = {
    valuations: user?.valuations || [],
  };

  const [formData, setFormData] = useState(initialState);

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updateValuations(user?._id, formData));
  };

  const handleChangeCheckbox = (e) => {
    const currentValuations = formData.valuations;

    // removes the valuation if it exists
    const filteredValuations = currentValuations.filter((valuationId) => String(valuationId) !== String(e.target.id));
    if (e.target.checked) {
      // adds the valuation to the array
      const newValuations = [...filteredValuations, e.target.id];
      const newFormData = {...formData, valuations: newValuations};
      setFormData(newFormData);
    } else {
      const newFormData = {...formData, valuations: filteredValuations};
      setFormData(newFormData);
    }

  };

  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getValuations());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getUser(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      redirectScroll(history)("/administrador/usuarios");
    }
  }, [isSuccess]);
  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Grid container direction="column" justifyContent="center" alignItems="center" >
            <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
              {`Editar Avaluos de ${user?.firstName} ${user?.lastName}`}
            </Typography>
          </Grid>
        </Grid>


        <form className={classes.form} noValidate onSubmit={handleSubmit} >
          <Grid container spacing={3} >

            {valuations.map((valuation, i) => (
              <Grid container key={i} >
                <Grid item xs={valuation.indentation} >
                </Grid>

                {/* In order to assure a minimum size, use Math.min() */}
                <Grid item xs={Math.min(12 - valuation.indentation, 6)} >
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={valuation.name}
                          id={valuation._id}
                          inputProps={{'aria-label': valuation.name}}
                          checked={formData.valuations.includes(valuation._id)}
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label={valuation.name}
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            ))}


            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
              >
                {isLoading
                  ? (<>
                    <CircularProgress />
                  </>)
                  : "Editar"
                }
              </Button>
            </Grid>

          </Grid>
        </form>
      </Grid>
    </>
  );
}
