import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router-dom";

import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button
} from '@material-ui/core';

import NO_AVATAR from "../../assets/images/no_avatar.png";

import CleanHTML from "../Utilities/CleanHTML";

import {getCouncilMember} from "../../actions/councilMembers";
import {redirectScroll} from "../../helpers/misc";
import useStyles from "./styles";

//////////////////////////////////////////////////////////////////////////////

export default function IndividualCouncilMember() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const councilMember = useSelector((state) => state.councilMember);
  const history = useHistory();
  const {councilMemberId, councilId} = useParams();

  // effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getCouncilMember(councilMemberId));
  }, [dispatch, councilMemberId]);
  // effects /////////////////////////////////////////////////////////////////

  return (
    <>
      {councilMember &&
        <Grid container justifyContent="center" alignItems="center" >
          <Grid item xs={12} md={8} xl={6} >
            <Card className={classes.root}>
              <Grid container alignItems="center" justifyContent="center" style={{marginTop: "1em", marginBottom: "1em"}}>
                <CardMedia
                  component="img"
                  alt="Foto usuario"
                  image={councilMember.avatar ? councilMember.avatar : NO_AVATAR}
                  title={`${councilMember?.firstName} ${councilMember?.lastName}`}
                  className={classes.img}
                />

              </Grid>

              <Grid container alignItems="center" justifyContent="center" style={{textAlign: "center"}} >
                <Grid item xs={12} >
                  <Typography gutterBottom variant="h4" component="h4">
                    {councilMember?.fullName}
                  </Typography>
                </Grid>

                <Grid item xs={12} >
                  <Typography gutterBottom variant="body1" component="h5" color="textSecondary" >
                    {councilMember?.boardTitle}
                  </Typography>
                </Grid>
              </Grid>

              <CardContent>
                <Grid container spacing={4} >
                  {councilMember.personalData &&
                    <Grid item xs={12} style={{paddingLeft: "15%", paddingRight: "15%"}} >
                      <Typography gutterBottom variant="body1" component="h5" >
                        <strong>Datos Personales</strong>
                      </Typography>

                      <Typography gutterBottom variant="body1" component="h5" >
                        <CleanHTML text={councilMember.personalData} />
                      </Typography>
                    </Grid>
                  }

                  {councilMember.curriculum &&
                    <Grid item xs={12} style={{paddingLeft: "15%", paddingRight: "15%"}} >
                      <Typography gutterBottom variant="body1" component="h5" >
                        <strong>Semblanza</strong>
                      </Typography>

                      <Typography gutterBottom variant="body1" component="h5" >
                        <CleanHTML text={councilMember?.curriculum} />
                      </Typography>
                    </Grid>
                  }

                  <Grid container justifyContent="center" alignItems="center" >
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => redirectScroll(history)(`/consejos/${councilId}`)}
                    >
                      Ver Consejo
                    </Button>

                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </>
  );
}
