import {
  FETCH_SINGLE_COUNCIL_MEMBER_SUCCESS,
} from "../../constants/actionTypes";

const councilMemberReducer = (councilMember = null, action) => {
  switch (action.type) {
    case FETCH_SINGLE_COUNCIL_MEMBER_SUCCESS:
      return action.payload;

    default:
      return councilMember;

  }
}

export default councilMemberReducer;
