import {
  FETCH_ALL_PRESIDENTS_REQUEST,
  FETCH_ALL_PRESIDENTS_SUCCESS,
  FETCH_ALL_PRESIDENTS_FAILURE,
  FETCH_ALL_COUNCIL_MEMBERS_REQUEST,
  FETCH_ALL_COUNCIL_MEMBERS_SUCCESS,
  FETCH_ALL_COUNCIL_MEMBERS_FAILURE,
  FETCH_SINGLE_COUNCIL_MEMBER_REQUEST,
  FETCH_SINGLE_COUNCIL_MEMBER_SUCCESS,
  FETCH_SINGLE_COUNCIL_MEMBER_FAILURE,
  CREATE_COUNCIL_MEMBER_REQUEST,
  CREATE_COUNCIL_MEMBER_SUCCESS,
  CREATE_COUNCIL_MEMBER_FAILURE,
  EDIT_COUNCIL_MEMBER_REQUEST,
  EDIT_COUNCIL_MEMBER_SUCCESS,
  EDIT_COUNCIL_MEMBER_FAILURE,
  DELETE_COUNCIL_MEMBER_REQUEST,
  DELETE_COUNCIL_MEMBER_SUCCESS,
  DELETE_COUNCIL_MEMBER_FAILURE,
} from '../constants/actionTypes';
import * as api from '../api/index.jsx';
import {snackbar} from "../actions/notifications";
import {getMembersOfCouncil} from "./councils";

export const getPresidents = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_ALL_PRESIDENTS_REQUEST});

    const {data} = await api.fetchPresidents();
    dispatch({type: FETCH_ALL_PRESIDENTS_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_ALL_PRESIDENTS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getCouncilMembers = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_ALL_COUNCIL_MEMBERS_REQUEST});

    const {data} = await api.fetchCouncilMembers();
    dispatch({type: FETCH_ALL_COUNCIL_MEMBERS_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_ALL_COUNCIL_MEMBERS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getCouncilMember = (id) => async (dispatch) => {
  try {
    dispatch({type: FETCH_SINGLE_COUNCIL_MEMBER_REQUEST});

    const {data} = await api.fetchCouncilMember(id);
    dispatch({type: FETCH_SINGLE_COUNCIL_MEMBER_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_SINGLE_COUNCIL_MEMBER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const createCouncilMember = (fd) => async (dispatch) => {
  try {
    dispatch({type: CREATE_COUNCIL_MEMBER_REQUEST});

    const {data} = await api.createCouncilMember(fd);
    dispatch({type: CREATE_COUNCIL_MEMBER_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: CREATE_COUNCIL_MEMBER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

export const editCouncilMember = (fd, councilMemberId) => async (dispatch) => {
  try {
    dispatch({type: EDIT_COUNCIL_MEMBER_REQUEST});

    const {data} = await api.editCouncilMember(fd, councilMemberId);
    dispatch({type: EDIT_COUNCIL_MEMBER_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: EDIT_COUNCIL_MEMBER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

export const deleteCouncilMember = (id) => async (dispatch) => {
  try {
    dispatch({type: DELETE_COUNCIL_MEMBER_REQUEST});

    const {data} = await api.deleteCouncilMember(id);
    dispatch({type: DELETE_COUNCIL_MEMBER_SUCCESS, payload: data.data});
    if (data.data?._id) {
      dispatch(getMembersOfCouncil(data.data._id));
    }
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: DELETE_COUNCIL_MEMBER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};
