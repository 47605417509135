import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';

import groupPhoto from '../../assets/images/groupPhoto.jpg';
import Council from '../Council/Council';
import ContactUs from '../ContactUs/ContactUs';

import SearchIcon from '@material-ui/icons/Search';

import useStyles from "./styles";

export default function AboutUs() {
  const classes = useStyles();


  return (
    <>
      <Typography
        component="h2"
        variant="h2"
        color="inherit"
        align="center"
        gutterBottom
        noWrap
        className={classes.sectionTitle}
      >
        <strong>QUIENES SOMOS</strong>
      </Typography>


      <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={12} >
          <Typography
            component="p"
            variant="body1"
            color="inherit"
            align="center"
            gutterBottom
          >
            COMAVIIN es Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in ligula nec enim dapibus fermentum. Curabitur egestas vehicula leo, quis venenatis quam suscipit vitae. Duis felis dolor, laoreet vitae gravida nec, laoreet at eros. Sed egestas nunc magna, eget rutrum justo fringilla sit amet. Donec semper laoreet odio quis scelerisque. Nulla vehicula varius ante, ut pretium quam lacinia sed. Integer blandit metus non nibh tincidunt mollis. Donec tincidunt blandit lacus vitae elementum. Nulla congue ligula commodo lacus pulvinar lobortis. Sed accumsan lectus non libero lacinia, et elementum erat posuere. Nullam nec lorem ex.

          </Typography>
        </Grid>

        <Grid item xs={12} >
          <CardMedia
            component="img"
            image={groupPhoto}
            title="Miembros COMAVIIN"
            className={classes.groupPhoto}
          />

          <Divider className={classes.sectionSeparator} />
        </Grid>

        <Grid item xs={12} >
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" >
            <Grid item xs={3} >
              <Card className={classes.noBorder} >
                <SearchIcon style={{fontSize: '96'}} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    MISION
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Vivamus ultrices sodales scelerisque. Duis efficitur turpis sit amet ante blandit, eu auctor risus malesuada. Pellentesque quam tellus, interdum quis neque ultrices, ornare dignissim ligula.
                  </Typography>
                </CardContent>
              </Card>
            </Grid >

            <Grid item xs={3} >
              <Card className={classes.noBorder} >
                <SearchIcon style={{fontSize: '96'}} />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    VISION
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    Ut volutpat eleifend nisl, eget accumsan mauris elementum quis    In elementum tempor lacus, id consectetur ipsum luctus sit amet. Aliquam neque nisl, accumsan vitae porta at, vulputate at nulla.
                  </Typography>
                </CardContent>
              </Card>
            </Grid >
          </Grid>
          <Divider className={classes.sectionSeparator} />
        </Grid>


        <Grid item xs={12} >
          <Council />
          <Divider className={classes.sectionSeparator} />
        </Grid>


        <Grid item xs={12} >
          <ContactUs />
        </Grid>

      </Grid>

    </>
  );
}

