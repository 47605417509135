import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';

import useStyles from "../Dashboard/styles";

export default function Home() {
  const classes = useStyles();

  return (
    <>
      <Typography align="center" variant="h4" gutterBottom className={classes.typography} >
        <strong>ADMINISTRADOR</strong>
      </Typography>


      <Grid container direction="row" className={classes.root} justifyContent="center" alignItems="center" spacing={2} >

        <Typography variant="body1" component="p" >
          En esta sección se puede controlar distintos elementos del sitio web a través del menu de la izquierda:

          <ul>
            <li>
              Crear Eventos
            </li>
            <li>
              Ver Lista de Eventos y pasar lista de asistencia, editarlos o borrarlos
            </li>

            <li>
              Crear Asambleas
            </li>
            <li>
              Ver Lista de Asambleas y pasar lista de asistencia, editarlas o borrarlas
            </li>

            <li>
              Crear Cursos
            </li>
            <li>
              Ver Lista de Cursos y pasar lista de asistencia, editarlos o borrarlos
            </li>

            <li>
              Ver lista de usuarios y editarlos, habilitar/deshabilitar contenido de paga, editar los avaluos que realizan o deshabilitarlos
            </li>

            <li>
              Ver lista de usuarios deshabilitados y editarlos, o habilitarlos
            </li>

            <li>
              Acceso a una sección de documentos de google drive
            </li>

            <li>
              Modificar los consejos y los miembros de consejo.
            </li>

          </ul>
        </Typography>


      </Grid>

    </>
  );
}
