import {useState} from "react";
import useStyles from './styles';

import {
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Button,
  Link,
  IconButton,
} from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu";

import sections from "./sections";

export default function DrawerNavbar(props) {
  const classes = useStyles();
  const {user} = props;
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          {sections.map((section, i) => (
            !section.condition || section.condition(user?.data)
              ? <ListItem onClick={() => setOpenDrawer(false)} key={i} >
                <ListItemText>
                  <Button >
                    <Link
                      color="inherit"
                      variant="h5"
                      href={section.url}
                      className={classes.toolbarLink}
                    >
                      <strong>{section.title}</strong>
                    </Link>
                  </Button>
                </ListItemText>
              </ListItem>
              : null
          ))}
        </List>
      </Drawer>

      <Grid container justifyContent="flex-end" >
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <MenuIcon />
        </IconButton>
      </Grid>
    </>
  );
}
