import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';

import useStyles from "./styles";

export default function SuccessModal(props) {
  const classes = useStyles();

  const {open, setOpen, loggedInUser} = props;
  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.modalDialog}>
            <Typography variant="h3" component="h3" id="transition-modal-title" gutterBottom>
              Compra Exitosa
            </Typography>

            <div id="transition-modal-description" >
              <Typography variant="body1" component="h5" align="justify" paragraph >
                ¡Gracias por tu compra!
              </Typography>

              <Typography variant="body1" component="h5" align="justify" paragraph >
                {!loggedInUser ? "Se te ha enviado un correo a tu cuenta de correo proveida" : "Puedes revisar tus cursos comprados en la sección de miembros."}
              </Typography>

              <Grid container justifyContent="space-between">
                <Grid item >
                  <Typography variant="body1" component="h5" align="justify" paragraph >
                    <Button variant="contained" color="default" onClick={handleModalClose}>
                      Cerrar
                    </Button>
                  </Typography>
                </Grid >

                {loggedInUser &&
                  <Grid item >
                    <Typography variant="body1" component="h5" align="justify" paragraph >
                      <Button variant="contained" color="primary" href={"/miembros/compras/eventos"}>
                        Ir a mis compras
                      </Button>
                    </Typography>
                  </Grid >
                }
              </Grid >
            </div>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
}



