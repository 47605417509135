import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation, useHistory} from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import EventTabs from './EventTabs';
import Calendar from '../Calendar/Calendar';
import IndividualEvent from "./IndividualEvent";

import {getEvent} from "../../actions/events";
import {getBirthday} from "../../actions/users";
import {EVENT, ASSEMBLY, COURSE, BIRTHDAY} from "../../constants/events";

import clsx from "clsx";
import useStyles from "./styles";

export default function CalendarView(props) {
  const classes = useStyles();
  const {autoScroll, } = props;

  const event = useSelector((state) => state.event.event);

  const history = useHistory();
  let arrPath = useLocation().pathname.split("/");
  const [eventInfo, setEventInfo] = useState({
    type: arrPath[arrPath.length - 2],
    id: arrPath[arrPath.length - 1],
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if ([EVENT, ASSEMBLY, COURSE, BIRTHDAY].includes(eventInfo.type) && eventInfo.id) {
      switch (eventInfo.type) {
        case EVENT:
        case ASSEMBLY:
        case COURSE:
          dispatch(getEvent(eventInfo.id));
          break;

        case BIRTHDAY:
          dispatch(getBirthday(eventInfo.id));
          break;

        default:
          console.log("ERROR, no correct type!");
          break;
      }

    }

    //return () => {
    //source.cancel("Unmounting component...");
    //}
  }, [dispatch, eventInfo]);

  const onEventClick = (event) => {
    setEventInfo({type: event.type, id: event._id});
    history.push(`/eventos/calendario/${event.type}/${event._id}`);
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        className={classes.root}
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} className={classes.section} >
          <EventTabs />
        </Grid>


        <Grid item xs={12} md={4} className={clsx(classes.section, classes.sticky)} >
          <Calendar onEventClick={onEventClick} />
        </Grid>


        <Grid item xs={12} md={8} className={classes.section} >
          <IndividualEvent event={event} autoScroll={autoScroll} />
        </Grid>

      </Grid>

    </>
  );
}
