import {makeStyles} from "@material-ui/core/styles";
import {deepPurple} from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 56,
  },


  paginationBttns: {
    //width: "80%",
    //height: 40,
    listStyle: "none",
    display: "flex",
    justifyContent: "center",

    "& a": {
      padding: 10,
      margin: 8,
      borderRadius: 5,
      border: "#2b2eff solid 1px",
      color: "#2b2eff",
      cursor: "pointer",

      "&:hover": {
        color: "white",
        backgroundColor: "#2b2eff",
      }
    }
  },

  previousBttn: {

  },

  nextBttn: {

  },

  paginationDisabled: {

  },

  paginationActive: {
    "& a": {
      color: "white",
      backgroundColor: "#2b2eff",
    }
  },

}));

