import {FETCH_ALL_EMAILS_SUCCESS, } from "../../constants/actionTypes";

const emailsReducer = (emails = [], action) => {
  switch (action.type) {
    case FETCH_ALL_EMAILS_SUCCESS:
      return action.payload;

    default:
      return emails;

  }
}

export default emailsReducer;
