import {makeStyles} from '@material-ui/core/styles';
import {deepOrange, deepPurple, brown, teal, } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  control: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  typography: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },


  // pagination style
  paginationBttns: {
    //width: "80%",
    //height: 40,
    listStyle: "none",
    display: "flex",
    justifyContent: "center",

    "& a": {
      padding: 10,
      margin: 8,
      borderRadius: 5,
      border: "#2b2eff solid 1px",
      color: "#2b2eff",
      cursor: "pointer",

      "&:hover": {
        color: "white",
        backgroundColor: "#2b2eff",
      }
    }
  },

  previousBttn: {

  },

  nextBttn: {

  },

  paginationDisabled: {

  },

  paginationActive: {
    "& a": {
      color: "white",
      backgroundColor: "#2b2eff",
    }
  },

  img: {
    height: 200,
    width: 200,
    //left: '25%',
    marginLeft: "auto",
    marginRight: "auto",
    position: 'relative',
    padding: theme.spacing(1),
  },

  council: {
    color: theme.palette.getContrastText("#293076"),

    backgroundColor: "#293076",
  },

}));
