import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  child: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  style: {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
  },

  sticky: {
    [theme.breakpoints.up('md')]: {
      position: "-webkit-sticky",
      position: "sticky",
      top: 0,
      backgroundColor: "yellow",
      // padding: "50px",
    },
  },
}));
