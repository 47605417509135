import {useState, useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {
  Grid,
  Paper,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  TextField,
  Tooltip,
} from '@material-ui/core';

// wysiwyg editor.
// customize toolbar documentation https://github.com/zenoamaro/react-quill#custom-toolbar
// this is for react-quill (https://github.com/zenoamaro/react-quill) but there are other alternatives:
// React Draft Wysiwyg (https://github.com/jpuri/react-draft-wysiwyg#readme)
// slate, but its still in beta (https://github.com/ianstormtaylor/slate#readme)
// react-jodit, lightweight, but not as popular with not many features (https://www.npmjs.com/package/jodit-react)
// more alterlatives: https://ourcodeworld.com/articles/read/1065/top-15-best-rich-text-editor-components-wysiwyg-for-reactjs
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {DropzoneArea} from 'material-ui-dropzone';

// datepicker
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';

import CircularProgress from '@material-ui/core/CircularProgress';

import {createEvent, editEvent} from "../../../actions/events";
import {resetLoading} from "../../../actions/general";
import {EVERYONE} from "../../../constants/roles";
import {EVENT, ASSEMBLY} from "../../../constants/events";

import {redirectScroll} from "../../../helpers/misc";

import useStyles from "../styles";

export default function CreateEvents(props) {
  const classes = useStyles();
  const {eventType = EVENT} = props;

  const location = useLocation();
  const history = useHistory();
  const event = location.state?.event;
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading?.CREATE_EVENT?.request || state.loading?.EDIT_EVENT?.request || false);
  const isSuccess = useSelector((state) => state.loading?.CREATE_EVENT?.success || state.loading?.EDIT_EVENT?.success || false);


  const initialState = {
    title: event?.title || "",
    // subtitle: "",
    price: event?.price || "",
    body: event?.body || "",
    type: eventType,
    purchasable: event?.purchasable || false,
    date: event?.date || moment().format("YYYY/MM/DD"),
    published: event?.published ? event?.published : true,
    attendable: event?.attendable || false,
    onlyVisibleTo: EVERYONE,
    // author: "",
    featured: event?.featured || false,
    imageName: "",
    uploadedImage: null,
    paywallContentDescription: event?.paywallContentDescription || "",
    isExtraordinary: event?.isExtraordinary || false,
  };

  const [formData, setFormData] = useState(initialState);

  const maxFileSize = 5242880; // 5mb

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.price === "") formData.price = "0";
    const purchasable = parseInt(formData.price) > 0 ? true : false;
    formData.purchasable = purchasable;


    const fd = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      fd.append(key, value);
    }

    if (event) {
      dispatch(editEvent(fd, event._id));
    } else {
      dispatch(createEvent(fd));
    }
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleChangeDate = (date) => {
    setFormData({...formData, date: moment(date).format("YYYY/MM/DD")});
  }

  const handleChangeCheckbox = (e) => {
    setFormData({...formData, [e.target.name]: e.target.checked});
  };

  const handleChangeRichText = (name) => (val) => {
    setFormData({...formData, [name]: val});
  };

  const handleOnchangeDropzone = (files) => {
    setFormData({
      ...formData,
      ["uploadedImage"]: files[0],
      ["imageName"]: files[0]?.name,
    });
  };
  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      redirectScroll(history)(`/administrador/${eventType.toLowerCase()}s/lista`);
    }
  }, [isSuccess]);
  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Grid container direction="column" justifyContent="center" alignItems="center" >
            <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
              {event
                ? `Editar ${eventType}`
                : `Crear ${eventType}`}
            </Typography>
          </Grid>
        </Grid>


        <form className={classes.form} noValidate onSubmit={handleSubmit} >
          <Grid container spacing={3} >

            <Grid item xs={12} >
              <Tooltip title="Titulo del evento" arrow >
                <Paper>
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    name="title"
                    variant="outlined"
                    id="title"
                    label="Titulo"
                    onChange={handleChange}
                    defaultValue={formData.title}
                  />
                </Paper>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Precio del evento. Para desbloquear el contenido de pago, los usuarios deben pagar la cantidad definida aqui. Si el evento no es de paga, ignore este campo" arrow >
                <Paper>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="price"
                    id="price"
                    label="Precio"
                    type="number"
                    onChange={handleChange}
                    defaultValue={formData.price}
                  />
                </Paper>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Fecha en la que el evento sucedera" arrow >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    required
                    name="date"
                    id="date"
                    variant="outlined" //inline ?
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Fecha"
                    KeyboardButtonProps={{
                      'aria-label': 'Cambiar fecha',
                    }}
                    value={formData.date}
                    onChange={handleChangeDate}
                  />

                </MuiPickersUtilsProvider>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>

              <div style={{color: "rgb(2 -1 0 / 0.54)"}}>
                <Typography variant="h5" component="legend" gutterBottom > Descripción </Typography>
                <Typography variant="body1" component="legend" gutterBottom >
                  Escriba aqui la descripcion del evento. Informacion tal como: que sucedera en el evento, el lugar donde ocurrira, si existe algun invitado especial, etc.
                </Typography>
              </div>

              <Paper>
                <ReactQuill
                  theme="snow"
                  defaultValue={formData.body}
                  onChange={handleChangeRichText("body")}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <FormGroup row>
                <Tooltip title="Si selecciona esta opcion el evento sera creado pero estara escondido hasta que un administrador edite el evento y seleccione esta opcion" arrow >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="published"
                        id="published"
                        inputProps={{'aria-label': 'Publicar'}}
                        checked={formData.published}
                        onChange={handleChangeCheckbox}
                      />
                    }
                    label="Publicar"
                  />
                </Tooltip>
              </FormGroup>
            </Grid>

            <Grid item xs={12}>
              <FormGroup row>
                <Tooltip title="Seleccionar esta opcion habilitara pase de lista a los miembros. Los administradores podran capturar si el miembro asistio y cuanto tiempo" arrow >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="attendable"
                        id="attendable"
                        inputProps={{'aria-label': 'Paso de lista'}}
                        checked={formData.attendable}
                        onChange={handleChangeCheckbox}
                      />
                    }
                    label="Paso de lista"
                  />
                </Tooltip>
              </FormGroup>
            </Grid>

            <Grid item xs={12} >
              <FormGroup row>
                <Tooltip title="Seleccionar esta opcion mostrara el evento en el carrusel de la primer seccion de la pagina" arrow >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="featured"
                        id="featured"
                        inputProps={{'aria-label': 'Mostrar en slideshow'}}
                        checked={formData.featured}
                        onChange={handleChangeCheckbox}
                      />
                    }
                    label="Mostrar en slideshow"
                  />
                </Tooltip>
              </FormGroup>
            </Grid>

            {eventType === ASSEMBLY &&
              <Grid item xs={12}>
                <FormGroup row>
                  <Tooltip title="Si selecciona esta opción, el evento será también de tipo Extraordinario" arrow >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isExtraordinary"
                          id="isExtraordinary"
                          inputProps={{'aria-label': 'Extraordinario'}}
                          checked={formData.isExtraordinary}
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label="Extraordinario"
                    />
                  </Tooltip>
                </FormGroup>
              </Grid>
            }

            <Grid item xs={12}>


              <div style={{color: "rgb(2 -1 0 / 0.54)"}}>
                <Typography variant="h5" component="legend" gutterBottom >Imagen</Typography>
                <Typography variant="body1" component="legend" gutterBottom >
                  Si sube una imagen, esta se mostrara cuando se vea la informacion del evento.
                </Typography>

                <Typography variant="body2" component="legend" gutterBottom >
                  <strong><em>NOTA:</em></strong> Dependiendo de las dimensiones de la imagen, es posible que no se pueda visualizar completamente
                </Typography>

                <Typography variant="body2" component="legend" gutterBottom >
                  <em>Máximo tamaño de imágen: 5mb</em>
                </Typography>
              </div>


              <DropzoneArea
                filesLimit={1}
                onChange={handleOnchangeDropzone}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/jpg']}
                //showPreviews={true}
                maxFileSize={maxFileSize}
                name="uploadedImage"
                dropzoneText="Arrastra el archivo o haz click aqui"
                inputProps={{name: "uploadedImage"}}
              />

            </Grid>


            <Grid container direction="row" spacing={3}>

              <Grid item xs={12} >

                <div style={{color: "rgb(2 -1 0 / 0.54)"}}>
                  <Typography variant="h5" component="legend" gutterBottom >
                    Seccion de contenido de paga
                  </Typography>
                  <Typography variant="body1" component="legend" gutterBottom >
                    Si el evento es de paga, esta seccion sera bloqueada hasta que el usuario pague por el evento.
                  </Typography>

                </div>


              </Grid>


              <Grid item xs={12}>

                <Paper>
                  <ReactQuill
                    theme="snow"
                    defaultValue={formData.paywallContentDescription}
                    onChange={handleChangeRichText("paywallContentDescription")}
                  />
                </Paper>
              </Grid>


            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              {isLoading
                ? (<>
                  <CircularProgress />
                </>)
                : event
                  ? "EDITAR"
                  : "CREAR"
              }
            </Button>

          </Grid>
        </form>
      </Grid>
    </>
  );
}
