import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Typography, Grid, CircularProgress} from '@material-ui/core';
import Carousel from "react-material-ui-carousel"; // library from https://www.npmjs.com/package/react-material-ui-carousel
import moment from "moment";

import UserCard from '../UserCard';

import {getCurrentCouncil} from "../../actions/councils";

import useStyles from "./styles";
import '../FeaturedEvents/Example.scss';

export default function Council() {
  const classes = useStyles();

  const autoPlay = true;
  const animation = "slide";
  const indicators = true;
  const timeout = 800;
  const interval = 4000;
  const navButtonsAlwaysVisible = true;
  const navButtonsAlwaysInvisible = false;
  const cycleNavigation = true;

  const dispatch = useDispatch();
  const currentCouncil = useSelector((state) => state.council);

  ////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getCurrentCouncil());
  }, [dispatch]);

  ////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Typography align="center" variant="h4" gutterBottom className={classes.typography}><strong>
        CONSEJO DIRECTIVO {currentCouncil?.membersContent?.length > 0
          ? `${moment(currentCouncil.startDate).format("YYYY")}-${moment(currentCouncil.endDate).format("YYYY")}`
          : null}
      </strong></Typography>

      <Grid container alignItems="center" justifyContent="center" >
        <Grid item xs={10} >
          {currentCouncil?.membersContent?.length > 0
            ? (<Carousel
              className="Example"
              autoPlay={autoPlay}
              animation={animation}
              indicators={indicators}
              timeout={timeout}
              cycleNavigation={cycleNavigation}
              navButtonsAlwaysVisible={navButtonsAlwaysVisible}
              navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
              interval={interval}
            >
              {currentCouncil?.membersContent?.map((member, i) => (
                <div key={i}>
                  <Grid container alignItems="center" justifyContent="center" >
                    <Grid item xs={10} >
                      <UserCard
                        name={member.fullName}
                        description={member.boardTitle}
                        img={member.avatar}
                        displayEducation={false}
                        className={classes.control}
                        userId={member._id}
                        redirectRoute={`miembrosConsejo/${currentCouncil._id}/${member._id}`}
                      />
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Carousel>)
            : (<Grid container justifyContent="center" >
              <CircularProgress />
            </Grid>)
          }
        </Grid>
      </Grid>
    </>
  );
}
