import {useState, useEffect} from "react";
import {Alert, AlertTitle} from '@material-ui/lab';
import {Fade} from '@material-ui/core';

import useStyles from "./errorHandlerStyles";

export default function ErrorHandler(props) {
  const classes = useStyles();

  const {errorHandler} = props
  const [show, setShow] = useState(false); // todo change to false

  useEffect(() => {
    setShow(false);
    if (errorHandler.hasError) {
      setShow(true);
    }
  }, [errorHandler]);

  return (
    <>
      {show &&
        <Fade in={true} timeout={1200}>
          <Alert onClose={() => {setShow(false)}} severity="error" className={classes.errorAlert}>
            <AlertTitle>Error</AlertTitle>
            {errorHandler.message}
          </Alert>
        </Fade>
      }
    </>
  );

}

