import {
  Grid,
  Paper,
  Typography,
  TextField,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import SearchFilter from '../../SearchFilter/SearchFilter';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import useStyles from "../styles";
import clsx from "clsx";

export default function MarkAttendance(props) {
  const classes = useStyles();
  const {boardList, filteredBoardList, setFilteredBoardList, handleChange, handleSendToMembers} = props

  return (
    <>
      <Grid item xs={12} style={{padding: "0"}}>
        <Typography gutterBottom variant="h4" component="h4" align="center" className={classes.typography} gutterBottom>
          Miembros del Consejo
        </Typography>

        <SearchFilter
          list={boardList}
          setFilteredList={setFilteredBoardList}
          filter="users"
          placeholder="Buscar Miembro Consejo"
        />
      </Grid>

      <List className={classes.root} >
        {filteredBoardList.map((user) => (
          <div key={user?._id} >
            <Grid item xs={12} >
              <ListItem alignItems="flex-start" >
                <ListItemAvatar>
                  <Avatar alt={`${user?.firstName} ${user?.lastName}`} src={user?.avatar} />
                </ListItemAvatar>

                <ListItemText primary={`${user?.firstName} ${user?.lastName}`} secondary={user?.title} />

                <ListItemSecondaryAction onClick={handleSendToMembers(user?._id)}>
                  <Tooltip title="Mandar a miembros" arrow >
                    <IconButton >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            </Grid>

            <Grid container >
              <Grid item xs={12} >
                <Tooltip title="Tiempo de asistencia al evento (puede ser vacio)" arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      name={user._id}
                      variant="outlined"
                      label="Posición en el consejo"
                      onChange={handleChange}
                      defaultValue={user?.boardTitle}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

            </Grid>

          </div>)
        )}
      </List>
    </>
  );
}
