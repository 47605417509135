import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import esLocale from '@fullcalendar/core/locales/es';
import {getAllEvents} from "../../actions/events";
import {getUsers} from "../../actions/users";
import {deepOrange} from '@material-ui/core/colors';
import {deepPurple} from '@material-ui/core/colors';
import {teal} from '@material-ui/core/colors';
import {brown} from '@material-ui/core/colors';
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import moment from "moment";
import {EVENT, BIRTHDAY, ASSEMBLY, COURSE} from "../../constants/events";
import clsx from "clsx";

import useStyles from "./styles";

import "./styles.scss";

export default function Calendar(props) {
  const classes = useStyles();
  const {
    onEventClick = () => {},
    showLegend = true,
  } = props;

  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [plugins, setPlugins] = useState([dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]);
  const [rightToolbar, setRightToolbar] = useState('dayGridMonth,timeGridWeek,timeGridDay');

  const allEvents = useSelector((state) => state.events)
    .map((event) => {
      return {
        ...event,
        date: moment(event.date).format("YYYY-MM-DD"),
        type: event.type
      };
    });

  const events = allEvents.filter((event) => event.type === EVENT);
  const assemblies = allEvents.filter((event) => event.type === ASSEMBLY);
  const courses = allEvents.filter((event) => event.type === COURSE);


  const birthdays = useSelector((state) => state.users)
    .filter((user) => user.birthday)
    .map((user) => {
      const birthday = moment(user.birthday).format("YYYY-MM-DD");
      return {
        title: `${user.firstName} ${user.lastName} Cumpleaños hoy`,
        _id: user._id,
        date: birthday,
        rrule: {
          dtstart: birthday,
          freq: 'yearly',
        },
        type: BIRTHDAY,
        avatar: user.avatar,
      };
    });

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    dispatch(getAllEvents());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) {
      setPlugins([dayGridPlugin, interactionPlugin, rrulePlugin]);
      setRightToolbar('dayGridMonth');
    } else {
      setPlugins([dayGridPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]);
      setRightToolbar('dayGridMonth,timeGridWeek,timeGridDay');
    }
  }, [isMobile]);

  ////////////////////////////////////////////////////////////////////////////

  const handleEventClick = (clickInfo) => {
    const clickedEvent = clickInfo.event.extendedProps;

    onEventClick(clickedEvent);
  }

  const eventSelector = [
    {
      events: events,
      color: deepOrange[500],
    },
    {
      events: birthdays,
      color: deepPurple[500],
    },
    {
      events: assemblies,
      color: brown[500],
    },
    {
      events: courses,
      color: teal[500],
    },
  ];


  return (
    <Grid container direction="column" >
      <Grid item xs={12} >
        <Paper className={clsx(classes.fullHeight, classes.fullCalendar)} >
          <FullCalendar
            plugins={plugins}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView="dayGridMonth"
            locale={esLocale}
            selectable={true}
            eventSources={eventSelector}
            dayMaxEvents={true}
            eventClick={handleEventClick}
          />
        </Paper>
      </Grid>

      {
        showLegend &&
        <Grid item >
          <List className={classes.root}>
            <Grid container direction="row" >
              <Grid item xs={6} >
                <ListItem>
                  <ListItemAvatar>
                    <div className={clsx(classes.shape, classes.shapeCircle)} style={{backgroundColor: deepOrange[500]}} />
                  </ListItemAvatar>
                  <ListItemText primary="Eventos" />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <div className={clsx(classes.shape, classes.shapeCircle)} style={{backgroundColor: brown[500]}} />
                  </ListItemAvatar>
                  <ListItemText primary="Asambleas" />
                </ListItem>
              </Grid>

              <Grid item xs={6} >
                <ListItem>
                  <ListItemAvatar>
                    <div className={clsx(classes.shape, classes.shapeCircle)} style={{backgroundColor: teal[500]}} />
                  </ListItemAvatar>
                  <ListItemText primary="Cursos" />
                </ListItem>

                <ListItem>
                  <ListItemAvatar>
                    <div className={clsx(classes.shape, classes.shapeCircle)} style={{backgroundColor: deepPurple[500]}} />
                  </ListItemAvatar>
                  <ListItemText primary="Cumpleaños" />
                </ListItem>
              </Grid>
            </Grid>
          </List>
        </Grid>
      }
    </Grid >
  );
}

