// type of event
export const EVENT = "EVENTO";
export const ASSEMBLY = "ASAMBLEA";
export const COURSE = "CURSO";
export const BIRTHDAY = "CUMPLEAÑOS";


export const ALL_EVENTS = "ALL_EVENTS";
export const PURCHASED_EVENTS = "PURCHASED_EVENTS";
export const ATTENDED_EVENTS = "ATTENDED_EVENTS";
export const PURCHASABLE_EVENTS = "PURCHASABLE_EVENTS";
