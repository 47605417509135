import {
  FETCH_ALL_EVENTS,
  FETCH_PURCHASED_EVENTS_SUCCESS,
  FETCH_ATTENDED_EVENTS_SUCCESS,
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  UPLOAD_IMAGE,
  FETCH_PURCHASABLE_EVENTS_SUCCESS,
} from '../../constants/actionTypes';

const eventsReducer = (events = [], action) => {
  switch (action.type) {
    case FETCH_ALL_EVENTS:
    case FETCH_PURCHASED_EVENTS_SUCCESS:
    case FETCH_ATTENDED_EVENTS_SUCCESS:
    case FETCH_PURCHASABLE_EVENTS_SUCCESS:
      return action.payload;

    case CREATE_EVENT:
      return [...events, action.payload];

    case UPDATE_EVENT:
      return events.map((event) => (event._id === action.payload._id ? action.payload : event));

    case DELETE_EVENT:
      return events.filter((event) => event._id !== action.payload);

    case UPLOAD_IMAGE:
      return events;

    default:
      return events;
  }
};

export default eventsReducer;
