import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  sectionTitle: {
    padding: theme.spacing(1),
  },

  sectionSeparator: {
    marginTop: 20,
    marginBottom: 20,
  },

  typography: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  noBorder: {
    border: "none",
    boxShadow: "none",
    marginLeft: "auto",
    marginRight: "auto",
    background: "inherit",
    textAlign: "center",
  },

  cardMedia: {
    //maxWidth: 300,
    maxHeight: 300,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  submitButton: {
    textAlign: "center"
  },
}));

