import axios from 'axios';

import {ADMIN} from "../constants/roles";

const currentDate = new Date();

const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3001/api" : "/api";
const API = axios.create({baseURL: baseURL});
API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    const token = JSON.parse(localStorage.getItem("profile")).token;
    req.headers.Authorization = `Bearer ${token}`;
  }

  return req;
});
export const source = axios.CancelToken.source();
const config = {cancelToken: source.token};

// USERS
export const fetchUsers = () => API.get("/users", config);
export const fetchNonHiddenUsers = () => API.get("/users/nonHiddenUsers", config);
export const fetchUser = (id) => API.get(`/users/${id}`, config);
export const fetchDisabledUser = (id) => API.get(`/DisabledUsers/${id}`, config);
export const fetchDisabledUsers = () => API.get("/DisabledUsers", config);
export const fetchPremiumUsers = (eventId) => API.get(`/users/premiumUsers/${eventId}`, config);
export const approveUser = (id) => API.put(`/users/approve/${id}`, config);
export const enableUser = (id) => API.get(`/users/enable/${id}`, config);
export const disableUser = (id) => API.get(`/users/disable/${id}`, config);
export const fetchBirthday = (id) => API.get(`/users/birthday/${id}`, config);
export const upsertAttendance = (attendance, eventId) => API.post(`users/${eventId}/eventAttendance`, attendance, config);
export const updatePassword = (id, formData) => API.put(`users/updatePassword/${id}`, formData, config);
export const resetPassword = (formData) => API.post("/users/resetPassword", {formData: formData}, config)
export const updateProfile = (id, formData) => API.put(`users/updateProfile/${id}`, formData, {
  config, headers: {
    'Content-Type': 'multipart/form-data',
  }
});
export const updateDisabledProfile = (id, formData) => API.put(`DisabledUsers/updateProfile/${id}`, formData, {
  config, headers: {
    'Content-Type': 'multipart/form-data',
  }
});
export const updateStudies = (id, formData) => API.put(`users/updateStudies/${id}`, formData, config);
export const updateDisabledStudies = (id, formData) => API.put(`DisabledUsers/updateStudies/${id}`, formData, config);
export const updateBoardMembers = (formData) => API.patch("users/updateBoardMembers/", formData, config);
export const getNumberAttendedEvents = (id, typeEvent) => API.get(`/users/numberAttendedEvents/${id}/${typeEvent}`, config);
export const updateValuations = (id, formData) => API.put(`users/updateValuations/${id}`, formData, config);
export const deleteDisabledUser = (id) => API.delete(`DisabledUsers/${id}`, config);

// EVENTS
export const fetchEvents = (startDate, endDate, title, condition) => API.get('/events', {
  params: {startDate: startDate, endDate: endDate, title: title, condition: condition},
  ...config
});
export const fetchEvent = (id) => API.get(`/events/${id}`, config);
export const createEvent = (newEvent) => API.post('/events', newEvent, {...config, ...newEvent});
export const editEvent = (updatedEvent, id) => API.put(`/events/${id}`, updatedEvent, {
  ...config, headers: {
    'Content-Type': 'multipart/form-data',
  }
});
export const deleteEvent = (id) => API.delete(`/events/${id}`, config);
export const fetchPublishedEvents = () => API.get('/events/published', config);
export const fetchFeaturedEvents = () => API.get('/events/featured', config);
export const fetchFeaturedEventsAndBirthdays = () => API.post('/events/featuredAndBirthdays', {day: currentDate.getDate(), month: currentDate.getMonth() + 1}, config);
export const fetchPurchasedEvents = (id) => API.get(`/users/${id}/purchasedEvents`, config);
export const fetchPurchasableEvents = () => API.get("/events/purchasableEvents", config);
export const fetchAttendedEvents = (id) => API.get(`/users/${id}/attendedEvents`, config);

// AUTH
export const signin = (formData) => API.post("/users/signin", formData, config);
export const signup = (formData) => API.post("/users/signup", formData, config);

export const isLoggedIn = (user) => {return user};
export const isAdmin = (user) => {return user?.roles?.includes(ADMIN)};

export const isAuthenticatedAdmin = () => API.get("/users/isAuthenticatedAdmin", config);

export const enableEventForUser = (userId, productId) => API.post(`/users/${userId}/event/${productId}`, config);
export const disableEventForUser = (userId, productId) => API.post(`/users/${userId}/event/${productId}/disable`, config);

export const uploadImage = (formData) => API.post("/events/upload/image", formData, {
  ...config, headers: {
    'Content-Type': 'multipart/form-data',
  }
});

// TRANSACTIONS
export const saveTransaction = (transaction) => API.post("/transactions", transaction, config);
export const fetchTransactions = () => API.get("/transactions", config);
export const fetchTransaction = (id) => API.get(`/transactions/${id}`, config);

// EMAILS
export const fetchEmails = () => API.get("/emails", config);
export const fetchEmail = (id) => API.get(`/emails/${id}`, config);

// valuations
export const fetchValuations = () => API.get("/valuations", config);

// misc
export const error400 = () => API.get("/error400", config);
export const sendEmail = (email, title, content, contentType = "html") => API.post("/emails/sendMail", {to: email, subject: title, message: content, contentType: contentType}, config)
export const sendContactEmail = (service, name, email, message) => API.post("/emails/sendContactMail", {service, name, email, message}, config)
export const sendForgotPasswordMail = (email) => API.post("/emails/sendForgotPasswordMail", {email: email}, config)

// councils
export const fetchCouncils = () => API.get("/councils", config);
export const fetchCouncilsAndMembers = () => API.get("/councils/councilAndMembers", config);
export const fetchCouncil = (id) => API.get(`/councils/${id}`, config);
export const fetchCurrentCouncil = () => API.get("/councils/currentCouncil", config);
export const fetchMembersOfCouncil = (id) => API.get(`/councils/councilMembers/${id}`, config);
export const createCouncil = (formData) => API.post("/councils", formData, config);
export const editCouncil = (id, formData) => API.put(`/councils/${id}`, formData, config);
export const deleteCouncil = (id) => API.delete(`/councils/${id}`, config);


//council members
export const fetchCouncilMembers = () => API.get("/councilMembers", config);
export const fetchPresidents = () => API.get("/councilMembers/presidents", config);
export const fetchCouncilMember = (id) => API.get(`/councilMembers/${id}`, config);
export const createCouncilMember = (formData) => API.post("/councilMembers", formData, {
  config, headers: {
    'Content-Type': 'multipart/form-data',
  }
});
export const editCouncilMember = (formData, councilMemberId) => API.put(`/councilMembers/${councilMemberId}`, formData, {
  config, headers: {
    'Content-Type': 'multipart/form-data',
  }
});
export const deleteCouncilMember = (id) => API.delete(`/councilMembers/${id}`, config);
