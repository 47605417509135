import {ADMIN_EMAIL} from "../constants/misc";
import {
  RESET_LOADING,
  RESET_USERS,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
} from "../constants/actionTypes";
import * as API from "../api/index.jsx";
import {snackbar} from "../actions/notifications";

export const resetLoading = () => async (dispatch) => {
  dispatch({type: RESET_LOADING});
};

export const resetUsers = () => async (dispatch) => {
  dispatch({type: RESET_USERS});
};

export const sendEmail = (email = "", title = "", content = "", contentType = "html") => async (dispatch) => {
  try {
    dispatch({type: SEND_EMAIL_REQUEST});

    const {data} = await API.sendEmail(email, title, content, contentType);
    dispatch({type: SEND_EMAIL_SUCCESS, payload: data.data});
    dispatch(snackbar(data?.message, "success"));
  } catch (error) {
    dispatch({type: SEND_EMAIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const requestService = (formData) => async (dispatch) => {
  const senderEmail = formData.email;
  const {service, name, email, message} = formData;

  const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  if (emailRegex.test(senderEmail)) {
    try {
      dispatch({type: SEND_EMAIL_REQUEST});
      const {data} = await API.sendContactEmail(service, name, email, message);
      dispatch({type: SEND_EMAIL_SUCCESS, payload: data.data});
      dispatch(snackbar(data?.message, "success"));
    } catch (error) {
      dispatch({type: SEND_EMAIL_FAILURE});
      dispatch(snackbar(error.response?.data?.message, "error"));
    }
  } else {
    dispatch(snackbar("Formato de correo invalido, ¿Está escrito correctamente?", "error"));
  }

};
