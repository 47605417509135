export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_NON_HIDDEN_USERS = 'FETCH_NON_HIDDEN_USERS';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const APPROVE_USER = 'APPROVE_USER';
export const FETCH_BIRTHDAY = 'FETCH_BIRTHDAY';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const EDIT_EVENT_REQUEST = 'EDIT_EVENT_REQUEST';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const UPDATE_EVENT = 'UPDATE_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const FETCH_ALL_EVENTS = 'FETCH_ALL_EVENTS';
export const FETCH_SINGLE_EVENT = 'FETCH_SINGLE_EVENT';
export const FETCH_SINGLE_ASSEMBLY = 'FETCH_SINGLE_ASSEMBLY';
export const FETCH_SINGLE_COURSE = 'FETCH_SINGLE_COURSE';
export const FETCH_PUBLISHED_EVENTS = 'FETCH_PUBLISHED_EVENTS';
export const FETCH_FEATURED_EVENTS = 'FETCH_FEATURED_EVENTS';
export const FETCH_FEATURED_EVENTS_AND_BIRTHDAYS = 'FETCH_FEATURED_EVENTS_AND_BIRTHDAYS';
export const CLEAR_EVENT = 'CLEAR_EVENT';

export const AUTH = 'AUTH';
export const AUTH_SIGNUP = 'AUTH_SIGNUP';
export const AUTH_SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST';
export const AUTH_SIGNUP_SUCCESS = 'AUTH_SIGNUP_SUCCESS';
export const AUTH_SIGNUP_FAILURE = 'AUTH_SIGNUP_FAILURE';
export const LOGOUT = 'LOGOUT';
export const UNAPPROVED = 'UNAPPROVED';

export const SAVE_TRANSACTION_REQUEST = 'SAVE_TRANSACTION_REQUEST';
export const SAVE_TRANSACTION_SUCCESS = 'SAVE_TRANSACTION_SUCCESS';
export const SAVE_TRANSACTION_FAILURE = 'SAVE_TRANSACTION_FAILURE';
export const ENABLE_EVENT_USER_REQUEST = 'ENABLE_EVENT_USER_REQUEST';
export const ENABLE_EVENT_USER_SUCCESS = 'ENABLE_EVENT_USER_SUCCESS';
export const ENABLE_EVENT_USER_FAILURE = 'ENABLE_EVENT_USER_FAILURE';
export const DISABLE_EVENT_USER_REQUEST = 'DISABLE_EVENT_USER_REQUEST';
export const DISABLE_EVENT_USER_SUCCESS = 'DISABLE_EVENT_USER_SUCCESS';
export const DISABLE_EVENT_USER_FAILURE = 'DISABLE_EVENT_USER_FAILURE';


export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const FETCH_PURCHASED_EVENTS_REQUEST = 'FETCH_PURCHASED_EVENTS_REQUEST';
export const FETCH_PURCHASED_EVENTS_SUCCESS = 'FETCH_PURCHASED_EVENTS_SUCCESS';
export const FETCH_PURCHASED_EVENTS_FAILURE = 'FETCH_PURCHASED_EVENTS_FAILURE';

export const FETCH_ATTENDED_EVENTS_REQUEST = 'FETCH_ATTENDED_EVENTS_REQUEST';
export const FETCH_ATTENDED_EVENTS_SUCCESS = 'FETCH_ATTENDED_EVENTS_SUCCESS';
export const FETCH_ATTENDED_EVENTS_FAILURE = 'FETCH_ATTENDED_EVENTS_FAILURE';

export const MARK_ATTENDANCE_REQUEST = 'MARK_ATTENDANCE_REQUEST';
export const MARK_ATTENDANCE_SUCCESS = 'MARK_ATTENDANCE_SUCCESS';
export const MARK_ATTENDANCE_FAILURE = 'MARK_ATTENDANCE_FAILURE';

export const RESET_LOADING = 'RESET_LOADING';
export const RESET_USERS = 'RESET_USERS';
export const RESET_USER = 'RESET_USER';

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const UPDATE_DISABLED_USER_PROFILE_REQUEST = 'UPDATE_DISABLED_USER_PROFILE_REQUEST';
export const UPDATE_DISABLED_USER_PROFILE_SUCCESS = 'UPDATE_DISABLED_USER_PROFILE_SUCCESS';
export const UPDATE_DISABLED_USER_PROFILE_FAILURE = 'UPDATE_DISABLED_USER_PROFILE_FAILURE';

export const UPDATE_STUDIES_REQUEST = 'UPDATE_STUDIES_REQUEST';
export const UPDATE_STUDIES_SUCCESS = 'UPDATE_STUDIES_SUCCESS';
export const UPDATE_STUDIES_FAILURE = 'UPDATE_STUDIES_FAILURE';

export const UPDATE_DISABLED_USER_STUDIES_REQUEST = 'UPDATE_DISABLED_USER_STUDIES_REQUEST';
export const UPDATE_DISABLED_USER_STUDIES_SUCCESS = 'UPDATE_DISABLED_USER_STUDIES_SUCCESS';
export const UPDATE_DISABLED_USER_STUDIES_FAILURE = 'UPDATE_DISABLED_USER_STUDIES_FAILURE';

export const FETCH_PREMIUM_USERS_REQUEST = 'FETCH_PREMIUM_USERS_REQUEST';
export const FETCH_PREMIUM_USERS_SUCCESS = 'FETCH_PREMIUM_USERS_SUCCESS';
export const FETCH_PREMIUM_USERS_FAILURE = 'FETCH_PREMIUM_USERS_FAILURE';

export const UPDATE_BOARD_MEMBERS_REQUEST = 'UPDATE_BOARD_MEMBERS_REQUEST';
export const UPDATE_BOARD_MEMBERS_SUCCESS = 'UPDATE_BOARD_MEMBERS_SUCCESS';
export const UPDATE_BOARD_MEMBERS_FAILURE = 'UPDATE_BOARD_MEMBERS_FAILURE';

export const FETCH_NUMBER_ATTENDED_EVENTS_REQUEST = 'FETCH_NUMBER_ATTENDED_EVENTS_REQUEST';
export const FETCH_NUMBER_ATTENDED_EVENTS_SUCCESS = 'FETCH_NUMBER_ATTENDED_EVENTS_SUCCESS';
export const FETCH_NUMBER_ATTENDED_EVENTS_FAILURE = 'FETCH_NUMBER_ATTENDED_EVENTS_FAILURE';

export const ENABLE_USER_REQUEST = 'ENABLE_USER_REQUEST';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE';

export const DISABLE_USER_REQUEST = 'DISABLE_USER_REQUEST';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE';

export const FETCH_PURCHASABLE_EVENTS_REQUEST = 'FETCH_PURCHASABLE_EVENTS_REQUEST';
export const FETCH_PURCHASABLE_EVENTS_SUCCESS = 'FETCH_PURCHASABLE_EVENTS_SUCCESS';
export const FETCH_PURCHASABLE_EVENTS_FAILURE = 'FETCH_PURCHASABLE_EVENTS_FAILURE';

export const FETCH_SINGLE_DISABLED_USER_REQUEST = 'FETCH_SINGLE_DISABLED_USER_REQUEST';
export const FETCH_SINGLE_DISABLED_USER_SUCCESS = 'FETCH_SINGLE_DISABLED_USER_SUCCESS';
export const FETCH_SINGLE_DISABLED_USER_FAILURE = 'FETCH_SINGLE_DISABLED_USER_FAILURE';

export const FETCH_CURRENT_COUNCIL_REQUEST = 'FETCH_CURRENT_COUNCIL_REQUEST';
export const FETCH_CURRENT_COUNCIL_SUCCESS = 'FETCH_CURRENT_COUNCIL_SUCCESS';
export const FETCH_CURRENT_COUNCIL_FAILURE = 'FETCH_CURRENT_COUNCIL_FAILURE';

export const FETCH_DISABLED_USERS_REQUEST = 'FETCH_DISABLED_USERS_REQUEST';
export const FETCH_DISABLED_USERS_SUCCESS = 'FETCH_DISABLED_USERS_SUCCESS';
export const FETCH_DISABLED_USERS_FAILURE = 'FETCH_DISABLED_USERS_FAILURE';

export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE';

export const FETCH_VALUATIONS_REQUEST = 'FETCH_VALUATIONS_REQUEST';
export const FETCH_VALUATIONS_SUCCESS = 'FETCH_VALUATIONS_SUCCESS';
export const FETCH_VALUATIONS_FAILURE = 'FETCH_VALUATIONS_FAILURE';

export const UPDATE_USER_VALUATIONS_REQUEST = 'UPDATE_USER_VALUATIONS_REQUEST';
export const UPDATE_USER_VALUATIONS_SUCCESS = 'UPDATE_USER_VALUATIONS_SUCCESS';
export const UPDATE_USER_VALUATIONS_FAILURE = 'UPDATE_USER_VALUATIONS_FAILURE';

export const FETCH_ALL_COUNCILS_REQUEST = 'FETCH_ALL_COUNCILS_REQUEST';
export const FETCH_ALL_COUNCILS_SUCCESS = 'FETCH_ALL_COUNCILS_SUCCESS';
export const FETCH_ALL_COUNCILS_FAILURE = 'FETCH_ALL_COUNCILS_FAILURE';

export const FETCH_ALL_COUNCILS_AND_MEMBERS_REQUEST = 'FETCH_ALL_COUNCILS_AND_MEMBERS_REQUEST';
export const FETCH_ALL_COUNCILS_AND_MEMBERS_SUCCESS = 'FETCH_ALL_COUNCILS_AND_MEMBERS_SUCCESS';
export const FETCH_ALL_COUNCILS_AND_MEMBERS_FAILURE = 'FETCH_ALL_COUNCILS_AND_MEMBERS_FAILURE';

export const FETCH_SINGLE_COUNCIL_REQUEST = 'FETCH_SINGLE_COUNCIL_REQUEST';
export const FETCH_SINGLE_COUNCIL_SUCCESS = 'FETCH_SINGLE_COUNCIL_SUCCESS';
export const FETCH_SINGLE_COUNCIL_FAILURE = 'FETCH_SINGLE_COUNCIL_FAILURE';

export const FETCH_ALL_COUNCIL_MEMBERS_REQUEST = 'FETCH_ALL_COUNCIL_MEMBERS_REQUEST';
export const FETCH_ALL_COUNCIL_MEMBERS_SUCCESS = 'FETCH_ALL_COUNCIL_MEMBERS_SUCCESS';
export const FETCH_ALL_COUNCIL_MEMBERS_FAILURE = 'FETCH_ALL_COUNCIL_MEMBERS_FAILURE';

export const FETCH_SINGLE_COUNCIL_MEMBER_REQUEST = 'FETCH_SINGLE_COUNCIL_MEMBER_REQUEST';
export const FETCH_SINGLE_COUNCIL_MEMBER_SUCCESS = 'FETCH_SINGLE_COUNCIL_MEMBER_SUCCESS';
export const FETCH_SINGLE_COUNCIL_MEMBER_FAILURE = 'FETCH_SINGLE_COUNCIL_MEMBER_FAILURE';

export const FETCH_ALL_PRESIDENTS_REQUEST = 'FETCH_ALL_PRESIDENTS_REQUEST';
export const FETCH_ALL_PRESIDENTS_SUCCESS = 'FETCH_ALL_PRESIDENTS_SUCCESS';
export const FETCH_ALL_PRESIDENTS_FAILURE = 'FETCH_ALL_PRESIDENTS_FAILURE';

export const CREATE_COUNCIL_MEMBER_REQUEST = 'CREATE_COUNCIL_MEMBER_REQUEST';
export const CREATE_COUNCIL_MEMBER_SUCCESS = 'CREATE_COUNCIL_MEMBER_SUCCESS';
export const CREATE_COUNCIL_MEMBER_FAILURE = 'CREATE_COUNCIL_MEMBER_FAILURE';

export const EDIT_COUNCIL_MEMBER_REQUEST = 'EDIT_COUNCIL_MEMBER_REQUEST';
export const EDIT_COUNCIL_MEMBER_SUCCESS = 'EDIT_COUNCIL_MEMBER_SUCCESS';
export const EDIT_COUNCIL_MEMBER_FAILURE = 'EDIT_COUNCIL_MEMBER_FAILURE';

export const FETCH_MEMBERS_OF_COUNCIL_REQUEST = 'FETCH_MEMBERS_OF_COUNCIL_REQUEST';
export const FETCH_MEMBERS_OF_COUNCIL_SUCCESS = 'FETCH_MEMBERS_OF_COUNCIL_SUCCESS';
export const FETCH_MEMBERS_OF_COUNCIL_FAILURE = 'FETCH_MEMBERS_OF_COUNCIL_FAILURE';

export const CREATE_COUNCIL_REQUEST = 'CREATE_COUNCIL_REQUEST';
export const CREATE_COUNCIL_SUCCESS = 'CREATE_COUNCIL_SUCCESS';
export const CREATE_COUNCIL_FAILURE = 'CREATE_COUNCIL_FAILURE';

export const EDIT_COUNCIL_REQUEST = 'EDIT_COUNCIL_REQUEST';
export const EDIT_COUNCIL_SUCCESS = 'EDIT_COUNCIL_SUCCESS';
export const EDIT_COUNCIL_FAILURE = 'EDIT_COUNCIL_FAILURE';

export const DELETE_COUNCIL_REQUEST = 'DELETE_COUNCIL_REQUEST';
export const DELETE_COUNCIL_SUCCESS = 'DELETE_COUNCIL_SUCCESS';
export const DELETE_COUNCIL_FAILURE = 'DELETE_COUNCIL_FAILURE';

export const DELETE_COUNCIL_MEMBER_REQUEST = 'DELETE_COUNCIL_MEMBER_REQUEST';
export const DELETE_COUNCIL_MEMBER_SUCCESS = 'DELETE_COUNCIL_MEMBER_SUCCESS';
export const DELETE_COUNCIL_MEMBER_FAILURE = 'DELETE_COUNCIL_MEMBER_FAILURE';

export const SEND_FORGOT_PASSWORD_MAIL_REQUEST = 'SEND_FORGOT_PASSWORD_MAIL_REQUEST';
export const SEND_FORGOT_PASSWORD_MAIL_SUCCESS = 'SEND_FORGOT_PASSWORD_MAIL_SUCCESS';
export const SEND_FORGOT_PASSWORD_MAIL_FAILURE = 'SEND_FORGOT_PASSWORD_MAIL_FAILURE';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const SEND_EMAIL_WITH_EVENT_REQUEST = 'SEND_EMAIL_WITH_EVENT_REQUEST';
export const SEND_EMAIL_WITH_EVENT_SUCCESS = 'SEND_EMAIL_WITH_EVENT_SUCCESS';
export const SEND_EMAIL_WITH_EVENT_FAILURE = 'SEND_EMAIL_WITH_EVENT_FAILURE';

export const FETCH_ALL_TRANSACTIONS_REQUEST = 'FETCH_ALL_TRANSACTIONS_REQUEST';
export const FETCH_ALL_TRANSACTIONS_SUCCESS = 'FETCH_ALL_TRANSACTIONS_SUCCESS';
export const FETCH_ALL_TRANSACTIONS_FAILURE = 'FETCH_ALL_TRANSACTIONS_FAILURE';
export const FETCH_SINGLE_TRANSACTION_REQUEST = 'FETCH_SINGLE_TRANSACTION_REQUEST';
export const FETCH_SINGLE_TRANSACTION_SUCCESS = 'FETCH_SINGLE_TRANSACTION_SUCCESS';
export const FETCH_SINGLE_TRANSACTION_FAILURE = 'FETCH_SINGLE_TRANSACTION_FAILURE';

export const FETCH_ALL_EMAILS_REQUEST = 'FETCH_ALL_EMAILS_REQUEST';
export const FETCH_ALL_EMAILS_SUCCESS = 'FETCH_ALL_EMAILS_SUCCESS';
export const FETCH_ALL_EMAILS_FAILURE = 'FETCH_ALL_EMAILS_FAILURE';
export const FETCH_SINGLE_EMAIL_REQUEST = 'FETCH_SINGLE_EMAIL_REQUEST';
export const FETCH_SINGLE_EMAIL_SUCCESS = 'FETCH_SINGLE_EMAIL_SUCCESS';
export const FETCH_SINGLE_EMAIL_FAILURE = 'FETCH_SINGLE_EMAIL_FAILURE';

export const DELETE_DISABLED_USER_REQUEST = 'DELETE_DISABLED_USER_REQUEST';
export const DELETE_DISABLED_USER_SUCCESS = 'DELETE_DISABLED_USER_SUCCESS';
export const DELETE_DISABLED_USER_FAILURE = 'DELETE_DISABLED_USER_FAILURE';

