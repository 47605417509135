import {
  AUTH,
  AUTH_SIGNUP,
  AUTH_SIGNUP_SUCCESS,
  LOGOUT,
  UNAPPROVED
} from "../constants/actionTypes";

const authReducer = (state = {authData: null}, action) => {
  switch (action.type) {
    case AUTH:
      localStorage.setItem("profile", JSON.stringify({...action?.data}));
      return {...state, authData: action?.data};

    case AUTH_SIGNUP:
    case AUTH_SIGNUP_SUCCESS:
      return state;

    case LOGOUT:
      localStorage.clear();
      return {...state, authData: null};

    case UNAPPROVED:
      return {...state, authData: null};

    default:
      return state;

  }
}

export default authReducer;
