import {
  Card,
  CardMedia,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import {Link} from "react-router-dom";
import moment from "moment";

import {EVENT, BIRTHDAY, COURSE, ASSEMBLY} from "../../constants/events";
import NO_AVATAR from "../../assets/images/no_avatar.png"

import useStyles from './styles';
import './Example.scss';

function Banner(props) {
  const classes = useStyles();
  const event = props.event;

  const eventColor = {
    [EVENT]: "eventColor",
    [BIRTHDAY]: "birthdayColor",
    [COURSE]: "courseColor",
    [ASSEMBLY]: "assemblyColor",
  };
  const classColor = eventColor[event.type] || "";

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const media = (
    <Grid item xs={12} key="media" >
      <CardMedia
        className="Media"
        title={event.title}
        style={event.type === BIRTHDAY
          ? {
            backgroundImage: `url(${event.image}),url(${event.avatar ? event.avatar : NO_AVATAR})`,
            backgroundPosition: isMobile ? "top, bottom" : "left, right",
            backgroundSize: isMobile ? "100% 50%, 100% 50%" : "50%, 50%",
          }
          : {
            backgroundImage: `url(${event.image})`,
          }
        }
      >
        <Grid container direction="column" className={`MediaCaption ${classColor}`} justifyContent="center" alignItems="center" >
          <Typography variant="h4" >
            <em>{event.type}</em>
          </Typography>

          <Typography variant="h2" className="mediaTitle" style={{textShadow: "1px 1px #000", fontSize: "32px"}}>
            <strong>{event.title}</strong>
          </Typography>

          <Typography variant="subtitle2" >
            {moment(event.date).format("YYYY-MM-DD")}
          </Typography>

        </Grid>

      </CardMedia>

    </Grid >
  );

  return (
    <Link to={`/eventos/calendario/${event.type}/${event._id}`} className={classes.bannerEvent}>
      <Card raised className="Banner" >
        <Grid container spacing={0} className="BannerGrid">
          {media}
        </Grid>
      </Card>
    </Link>
  );
}

export default Banner;
