import {useState} from "react";
import {
  Grid,
  Typography,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Box,
} from '@material-ui/core';
import ReactPaginate from 'react-paginate';
import SearchFilter from '../../SearchFilter/SearchFilter';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import useStyles from "../styles";
import clsx from "clsx";

export default function MarkAttendance(props) {
  const classes = useStyles();
  const {normalList, filteredNormalList, setFilteredNormalList, handleSendToBoard} = props

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 8;
  const pagesVisited = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(filteredNormalList.length / itemsPerPage);
  function changePage({selected}) {
    setPageNumber(selected);
  }


  return (
    <>
      <Grid item xs={12} style={{padding: "0"}}>
        <Typography gutterBottom variant="h4" component="h4" align="center" className={classes.typography} gutterBottom>
          Miembros Normales
        </Typography>

        <SearchFilter
          list={normalList}
          setFilteredList={setFilteredNormalList}
          filter="users"
          placeholder="Buscar Miembro"
        />
      </Grid>

      <List className={classes.root} >

        {filteredNormalList
          .slice(pagesVisited, pagesVisited + itemsPerPage)
          .map((user) => (
            <div key={user?._id} >
              <Grid item xs={12} >
                <ListItem alignItems="flex-start" >
                  <ListItemAvatar>
                    <Avatar alt={`${user?.firstName} ${user?.lastName}`} src={user?.avatar} />
                  </ListItemAvatar>

                  <ListItemText primary={`${user?.firstName} ${user?.lastName}`} secondary={user?.title ? user.title : null} />

                  <ListItemSecondaryAction onClick={handleSendToBoard(user?._id)}>
                    <Tooltip title="Mandar a consejo" arrow >
                      <IconButton >
                        <ArrowBackIosIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>

              <Grid item xs={12} >
              </Grid>

            </div>)
          )}
      </List>

      {filteredNormalList.length !== 0
        ? (<ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={classes.paginationBttns}
          previousLinkClassName={clsx(classes.previousBttn, "MuiPaper-root", "MuiPaper-elevation1")}
          nextLinkClassName={clsx(classes.nextBttn, "MuiPaper-root", "MuiPaper-elevation1")}
          disabledClassName={classes.paginationDisabled}
          activeClassName={classes.paginationActive}
        />)
        : (<Box textAlign="center"><Typography>
          {`NO SE ENCONTRARON RESULTADOS`}
        </Typography></Box>)
      }
    </>
  );
}
