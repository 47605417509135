import {Route, Redirect} from "react-router-dom";
import {isLoggedIn, isAdmin} from "../../api/index";

export function MemberRoute ({ children, ...rest }) {
  return (
    <Route {...rest} render={() => {
      const user = JSON.parse(localStorage.getItem("profile"));

      return isLoggedIn(user)
        ? children
        : <Redirect to={'/acceder'} />
    }} />
  )
}

export function AdminRoute ({ children, ...rest }) {
  return (
    <Route {...rest} render={() => {
      const user = JSON.parse(localStorage.getItem("profile"))?.data;

      return isAdmin(user)
        ? children
        : <Redirect to={'/acceder'} />
    }} />
  )
}
