import {useState} from "react";
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventIcon from '@material-ui/icons/Event';
import DvrIcon from '@material-ui/icons/Dvr';
import PeopleIcon from '@material-ui/icons/People';
import PeopleTwoToneIcon from '@material-ui/icons/PeopleTwoTone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MessageIcon from '@material-ui/icons/Message';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
//
import AdminEventSublist from "./AdminEventSublist";
//import Sublist from "./Sublist";
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';
import ForumIcon from '@material-ui/icons/Forum';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {DRIVE_DOCUMENTS_LINK} from "../../constants/misc";

import useStyles from "./styles";

export function AdminListItems() {
  const classes = useStyles();

  return (
    <List>
      <Link href={"/administrador"} >
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>
      </Link>

      <AdminEventSublist type="eventos" Icon={EventIcon} />
      <AdminEventSublist type="asambleas" Icon={ForumIcon} />
      {/*<Link href={"/administrador/asambleas/extraordinarias/lista"} >
        <ListItem button>
          <ListItemIcon>
            <ForumIcon />
          </ListItemIcon>
          <ListItemText
            primary="Asambleas Extraordinarias"
            primaryTypographyProps={{style: {whiteSpace: "normal"}}}
          />
        </ListItem>
      </Link>*/}
      <AdminEventSublist type="cursos" Icon={DvrIcon} />

      <Link href={"/administrador/usuarios"} >
        <ListItem button>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
        </ListItem>
      </Link>

      <Link href={"/administrador/usuarios/deshabilitados"} >
        <ListItem button>
          <ListItemIcon>
            <PeopleTwoToneIcon />
          </ListItemIcon>
          <ListItemText
            primary="Usuarios Deshabilitados"
            primaryTypographyProps={{style: {whiteSpace: "normal"}}}
          />
        </ListItem>
      </Link>

      <Link href={DRIVE_DOCUMENTS_LINK} target="_blank" >
        <ListItem button>
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          <ListItemText primary="Documentos" />
        </ListItem>
      </Link>

      <Link href={"/administrador/consejos"} >
        <ListItem button>
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText primary="Lista Consejos" />
        </ListItem>
      </Link>


    </List>
  );
}

export function MemberListItems() {
  const classes = useStyles();

  const [openProfile, setOpenProfile] = useState(false);
  const [openUserEvents, setOpenUserEvents] = useState(false);
  const [openUserAttendance, setOpenUserAttendance] = useState(false);


  return (
    <List>
      <Link href={"/miembros"} >
        <ListItem button>
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Inicio" />
        </ListItem>
      </Link>

      <ListItem button onClick={() => setOpenProfile(!openProfile)} >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Perfil" />
        {openProfile ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={openProfile} timeout="auto" unmountOnExit>
        <List disablePadding>
          <Link href={"/miembros/perfil"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={"Modificar perfil"} />
            </ListItem>
          </Link>

          <Link href={"/miembros/contraseña"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={"Contraseña"} />
            </ListItem>
          </Link>

        </List>
      </Collapse>

      <ListItem button onClick={() => setOpenUserEvents(!openUserEvents)} >
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Mis Compras" />
        {openUserEvents ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openUserEvents} timeout="auto" unmountOnExit>
        <List disablePadding>
          <Link href={"/miembros/compras/eventos"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={"Eventos"} />
            </ListItem>
          </Link>

          <Link href={"/miembros/compras/asambleas"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary={"Asambleas"} />
            </ListItem>
          </Link>

          <Link href={"/miembros/compras/cursos"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={"Cursos"} />
            </ListItem>
          </Link>
        </List>
      </Collapse>

      <ListItem button onClick={() => setOpenUserAttendance(!openUserAttendance)}>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Asistencias" />
        {openUserAttendance ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openUserAttendance} timeout="auto" unmountOnExit>
        <List disablePadding>
          <Link href={"/miembros/asistencias/eventos"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={"Eventos"} />
            </ListItem>
          </Link>

          <Link href={"/miembros/asistencias/asambleas"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ForumIcon />
              </ListItemIcon>
              <ListItemText primary={"Asambleas"} />
            </ListItem>
          </Link>

          <Link href={"/miembros/asistencias/cursos"} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={"Cursos"} />
            </ListItem>
          </Link>
        </List>
      </Collapse>


      <Link href={DRIVE_DOCUMENTS_LINK} target="_blank" >
        <ListItem button>
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          <ListItemText primary="Documentos" />
        </ListItem>
      </Link>


    </List>
  );
}
