import {
  FETCH_SINGLE_COUNCIL_SUCCESS,
  FETCH_CURRENT_COUNCIL_SUCCESS,
} from "../../constants/actionTypes";

const councilReducer = (council = null, action) => {
  switch (action.type) {
    case FETCH_SINGLE_COUNCIL_SUCCESS:
    case FETCH_CURRENT_COUNCIL_SUCCESS:
      return action.payload;

    default:
      return council;

  }
}

export default councilReducer;
