import {useEffect} from "react";
import Carousel from "react-material-ui-carousel"; // library from https://www.npmjs.com/package/react-material-ui-carousel
import {useDispatch, useSelector} from "react-redux";
import Banner from "./Banner";
import {getFeaturedEventsAndBirthdays} from "../../actions/events";
import {source} from "../../api/index";

import './Example.scss';


function FeaturedEvents() {

  const autoPlay = true;
  const animation = "slide";
  const indicators = true;
  const timeout = 1000;
  const navButtonsAlwaysVisible = true;
  const navButtonsAlwaysInvisible = false;
  const cycleNavigation = true;
  //const cycleNavigation = false;

  const featuredEvents = useSelector((state) => state.featuredEvents);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeaturedEventsAndBirthdays());

    //return () => {
    //source.cancel("Unmounting component...");
    //}
  }, [dispatch]);


  return (
    <div style={{marginTop: "50px", color: "#494949"}}>

      <Carousel
        className="Example"
        autoPlay={autoPlay}
        animation={animation}
        indicators={indicators}
        timeout={timeout}
        cycleNavigation={cycleNavigation}
        navButtonsAlwaysVisible={navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
      // next={(now, previous) => console.log(`Next User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
      // prev={(now, previous) => console.log(`Prev User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
      // onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child${now}. Previously displayed child${previous}`)}
      // fullHeightHover={false}
      // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
      // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
      // indicatorContainerProps={{style: {margin: "20px"}}}
      // NextIcon='next'
      >
        {
          featuredEvents.length && featuredEvents.map((event) => {
            return <Banner event={event} key={event._id} contentPosition={event.contentPosition} />
          })
        }
      </Carousel>

    </div>

  );
}

export default FeaturedEvents;

