import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  Grid,
  Button,
  TextField,
  Link,
  Container,
  Paper,
  Fade,
} from '@material-ui/core';
import {signin} from "../../actions/auth";
import ErrorHandler from "./ErrorHandler";

import useStyles from "./styles";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: ""
}

export default function LoginForm() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState(initialState);
  const [errorHandler, setErrorHandler] = useState({
    hasError: false,
    message: "",
  });
  const animationDuration = 500;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signin(formData, history, setErrorHandler));
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };


  return (
    <Fade direction="right" in={true} timeout={animationDuration} mountOnEnter unmountOnExit >
      <Container component="div" maxWidth="lg" >
        <div className={classes.paperLogin}>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>

            <Grid container spacing={1} className={classes.inputCredentials} justifyContent="center" alignItems="center" >

              <Grid item xs={12} md={5}>
                <Paper >
                  <TextField
                    variant="outlined"
                    required
                    id="email"
                    label="Correo"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                    fullWidth
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={5}>
                <Paper >
                  <TextField
                    variant="outlined"
                    required
                    name="password"
                    label="Contraseña"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleChange}
                    fullWidth
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.authButton}
                >
                  Acceder
                </Button>

              </Grid>

            </Grid>

            <Grid container>
              <Grid item xs>
                <ErrorHandler errorHandler={errorHandler} />
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" >
              <Grid item xs>
                <Link href={"/claveOlvidada"} variant="body2">
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>

            </Grid>
          </form>
        </div>

      </Container>
    </Fade>
  );

}

