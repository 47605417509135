import {
  FETCH_ALL_USERS,
  FETCH_NON_HIDDEN_USERS,
  FETCH_DISABLED_USERS_SUCCESS,
  APPROVE_USER,
  FETCH_PREMIUM_USERS_SUCCESS,
  RESET_USERS,
  DELETE_DISABLED_USER_SUCCESS,
} from "../constants/actionTypes";

const usersReducer = (users = [], action) => {
  switch (action.type) {
    case FETCH_ALL_USERS:
    case FETCH_NON_HIDDEN_USERS:
    case FETCH_DISABLED_USERS_SUCCESS:
      return action.payload;

    case FETCH_PREMIUM_USERS_SUCCESS:
      return action.payload;

    case APPROVE_USER:
      return users.map((user) => {
        return (user._id === action.payload._id ? action.payload : user);
      });

    case RESET_USERS:
      return [];

    case DELETE_DISABLED_USER_SUCCESS:
      return users.filter((user) => String(user._id) !== String(action?.payload?._id));

    default:
      return users;

  }
}

export default usersReducer;
