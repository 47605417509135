import {Button} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: 56,
  },

}));


export default function SubmitButton(props) {
  const {
    isLoading = false,
    textCondition = false,
    text1 = "CREAR",
    text2 = "EDITAR",
  } = props;
  const classes = useStyles();

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={isLoading}
    >
      {isLoading
        ? (<>
          <CircularProgress />
        </>)
        : textCondition
          ? text2
          : text1
      }
    </Button>
  );
}
