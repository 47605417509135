import {useState, useEffect} from "react";
import {PayPalButton} from "react-paypal-button-v2";
import {useDispatch, useSelector} from "react-redux";
import {saveTransaction, enableEventForUser, sendEmailWithEvent} from "../../actions/paypal";
import {PAYPAL_ACTIVE} from "../../constants/misc";

import {
  Typography,
  Button,
  Grid,
} from '@material-ui/core';

import AlternativePayment from "./AlternativePayment";
import SuccessModal from "./SuccessModal";

export default function Paypal(props) {
  const dispatch = useDispatch();
  const isSuccess = useSelector((state) => state.loading?.ENABLE_EVENT_USER?.success || false);
  const productId = props.productId;
  const amount = parseInt(props.amount) >= 0 ? props.amount.toString() : "0";
  const [user, setUser] = useState(null);
  const localStorageUser = JSON.parse(localStorage.getItem("profile"))?.data;

  const purchasedEvent = user?.purchasedEvents?.includes(productId);
  const currency = props.currency ? props.currency : "MXN";
  const [open, setOpen] = useState(false);
  const handleModalOpen = () => {
    setOpen(true);
  };
  const email = props.email;

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
    } else {
      if (localStorageUser) {
        setUser(localStorageUser);
      }
    }
  }, []);


  useEffect(() => {
    if (isSuccess) {
      // updates user because associated events may have changed
      if (localStorageUser) {
        setUser(localStorageUser);
      }
      handleModalOpen();
    }
  }, [isSuccess]);

  ////////////////////////////////////////////////////////////////////////////

  const approvedTransaction = (data, actions) => {
    // Capture the funds from the transaction
    console.log("capturing action");
    const result = actions.order.capture()
      .then(function (details) {
        console.log("start .then");
        // Show a success message to your buyer

        // OPTIONAL: Call your server to save the transaction
        const transaction = {
          orderId: data?.orderID,
          payerId: data?.payerID,
          userId: user?._id,
          //payerEmail: details?.payer?.email_address,
          userEmail: email ? email : user?.email,
          paymentCreateTime: details?.create_time,
          paymentUpdateTime: details?.update_time,
          status: details?.status,
          amount: amount,
        };
        dispatch(saveTransaction(transaction));
        if (user) {
          dispatch(enableEventForUser(user?._id, productId));
        } else {
          dispatch(sendEmailWithEvent(email, productId));
        }
        console.log("end .then");
      })
      .catch(function (error) {
        console.log("Catching some error: ", error);
      });

    console.log("ending action capture");
    return result;
  }

  const successfullTransaction = (details, data) => {
    //alert("Transaction completed by " + details.payer.name.given_name);
    console.log("successful transaction!");
  }

  //5020.82
  const handleError = (error) => {
    console.log("Error happened: ", error);
  };

  ////////////////////////////////////////////////////////////////////////////

  return (
    <>
      {!purchasedEvent && parseInt(amount) > 0
        ? (<Grid container direction="column" justifyContent="center" >
          {PAYPAL_ACTIVE ? <>
            <PayPalButton
              amount={amount}
              shippingPreference="NO_SHIPPING"
              onApprove={approvedTransaction}
              //onSuccess={successfullTransaction}
              onError={handleError}

              options={{
                //clientId: process.env.NODE_ENV === 'development' ? "sb" : process.env.PAYPAL_CLIENT_ID_PROD,
                clientId: "sb",
                currency: currency,
                //debug: process.env.NODE_ENV === 'development' ? true : false,
              }}
            />

            <AlternativePayment />
          </>
            :
            <AlternativePayment title="Compra de curso" />
          }

        </Grid>)

        : (<Typography variant="body1" component="h5" align="justify" paragraph >
          <Button variant="contained" color="primary" href={"/miembros/compras/eventos"}>Ir a mis compras</Button>
        </Typography>)
      }

      <SuccessModal open={open} setOpen={setOpen} loggedInUser={Boolean(user)} />

    </>
  );
}
