import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,

    "& div": {
      flexGrow: 1,
    },
  },

  sectionTitle: {
    padding: theme.spacing(1),
  },

  style: {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
  },

  child: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },

  divider: {
    height: 28,
    margin: 4,
  },

  endMessage: {
    margin: 10,
  },

  button: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },

  iconButton: {
    width: "50%",
  },

  img: {
    borderRadius: "50%",
    height: 160,
    width: 160,
  },
  typography: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  sticky: {
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
}));
