import DOMPurify from "dompurify";
import parse from "html-react-parser";

import './CleanHtml.scss';

export default function CleanHTML({text = ""}) {
  const purifiedHTML = DOMPurify.sanitize(text, {USE_PROFILES: {html: true}});
  const cleanHTML = parse(purifiedHTML);

  return (
    <span >
      {cleanHTML}
    </span>
  );
};
