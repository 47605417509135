import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  sectionTitle: {
    padding: theme.spacing(1),
  },

  groupPhoto: {
    height: 500,
    width: 500,
    marginLeft: "auto",
    marginRight: "auto",
  },

  sectionSeparator: {
    marginTop: 20,
    marginBottom: 20,
  },

  typography: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  noBorder: {
    border: "none",
    boxShadow: "none",
    marginLeft: "auto",
    marginRight: "auto",
    background: "inherit",
    textAlign: "center",
  }
}));

