import {useState} from 'react';
import {
  Grid,
  Container,
  Avatar,
  Typography,
  Button,
  Link,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import LoginForm from "./LoginForm";

import useStyles from "./styles";


export default function Login(props) {
  const classes = useStyles();

  const {user, handleLogout, clickedOnce, setClickedOnce} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  function LoginButton() {
    return (
      <>
        {clickedOnce
          ? <LoginForm />
          : <AccessButton />
        }
      </>
    );
  }

  function AccessButton() {
    return (
      <Container component="div" maxWidth="xs">
        <div className={classes.paperLogin}>
          <Grid container spacing={1} className={classes.inputCredentials} justifyContent={isMobile ? "center" : "flex-end"} >
            <Grid item xs={isMobile} style={{textAlign: "center"}}>
              <Button
                type="submit"
                variant="outlined"
                color="default"
                className={classes.authButton}
                href="registro"
                style={{border: "outset", borderWidth: "2px"}}
              >
                <strong>Registrarse</strong>
              </Button>
            </Grid>

            <Grid item xs={isMobile} style={{textAlign: "center"}}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.authButton}
                onClick={() => setClickedOnce(true)}
              >
                Acceder
              </Button>
            </Grid>
          </Grid>

        </div>

      </Container >
    );

  }

  function LogoutButton({handleLogout}) {
    return (
      <Grid container direction="row" spacing={2} alignItems="center" justifyContent={isMobile ? "center" : "flex-end"} >
        <Grid item>
          <Link href="/miembros/perfil" >
            <Avatar
              className={classes.purple}
              alt={user?.data.firstName}
              src={user.data.avatar}
            >
              {user?.data.firstName.charAt(0)}
            </Avatar>
          </Link>
        </Grid>
        <Grid item>
          <Typography className={classes.userName} variant="h6">
            <strong>{user?.data.firstName}</strong>
            {user?.data.memberId && ` Miembro ${user?.data.memberId}`}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            className={classes.authButton}
            onClick={handleLogout}
          >
            Salir
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <div >
      {user?.data
        ? <LogoutButton handleLogout={handleLogout} />
        : <LoginButton clickedOnce={clickedOnce} setClickedOnce={setClickedOnce} />
      }
    </div>
  )
}

