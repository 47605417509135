import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },

  typography: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  contactGrid: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  sectionTitle: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  gridContainer: {
  },

  gridChild: {
    width: "100%",
    "& TextField": {
      margin: theme.spacing(1),
    },
    "& TextareaAutosize": {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  },

  submitButton: {
    textAlign: "center"
  },
}));
