import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  TextField,
  Button,
  Container,
  Avatar,
  Typography,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';

import {sendForgotPasswordMail} from "../../actions/auth";

import useStyles from "./styles";

////////////////////////////////////////////////////////////////////////////

export default function ForgotPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");

  const isLoading = useSelector((state) => state.loading?.SEND_FORGOT_PASSWORD_MAIL?.request || false);
  const isSuccess = useSelector((state) => state.loading?.SEND_FORGOT_PASSWORD_MAIL?.success || false);
  const isFailure = useSelector((state) => state.loading?.SEND_FORGOT_PASSWORD_MAIL?.failure || false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (email) {
      dispatch(sendForgotPasswordMail(email));
    }
  }

  ////////////////////////////////////////////////////////////////////////////

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5" gutterBottom>
          Restablecer Contraseña
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            required
            variant="outlined"
            fullWidth
            id="email"
            name="email"
            label="Correo"
            autoFocus
            placeholder="Correo Electronico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={(!isLoading && !isSuccess && !isFailure) || isFailure
              ? ""
              : "disabled"}
          >
            {isLoading ? <CircularProgress /> : "Reestablecer contraseña"}
          </Button>

          {isSuccess &&
            <Typography color="textPrimary" variant="body1" gutterBottom style={{textAlign: "justify"}}>
              Se ha enviado un mensaje de restablecimiento de contraseña a su correo. Entre en el enlace que encuentre dentro del correo para seguir.
            </Typography>
          }



        </form>
      </div>

    </Container>
  );
}
