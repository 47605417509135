import moment from "moment";
import "moment/locale/es";
import {ALL_ATTENDED, MINIMUM_ATTENDED, NOT_MINIMUM_ATTENDED} from "../constants/misc";

//////////////////////////////////////////////////////////////////////////////

export const isUrl = (str) => {
  const urlRule = new RegExp('http?.*');
  return urlRule.test(str);
}

//////////////////////////////////////////////////////////////////////////////

export const shuffle = (arr) => {
  const cpy = arr.slice(0);
  for (let i = cpy.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = cpy[i];
    cpy[i] = cpy[j];
    cpy[j] = temp;
  }

  return cpy;
}

//////////////////////////////////////////////////////////////////////////////

// valuations is added as a function parameter to have the same number of parameters as the filterList
export const filterList = (list, query, parameters = "title", valuations = []) => {
  if (!query) {
    return list;
  }

  if (Array.isArray(parameters)) {
    return list.filter((item) => {
      const content = parameters.map((p) => item[p].toLowerCase()).join(" ");
      return content.includes(query);
    });
  }

  return list.filter((item) => {

    const content = item[parameters].toLowerCase();
    return content.includes(query);
  });
};

//////////////////////////////////////////////////////////////////////////////

// _p is added as a function parameter to have the same number of parameters as the filterList
export const filterUsersList = (users, query, _p, valuations = []) => {
  if (!query) {
    return users;
  }
  const lowCaseQuery = query.toLowerCase();

  const separator = " ";
  const parameters = [
    "firstName",
    "lastName",
    "memberId",
    "email",
    "telephone",
    "title",
  ];

  const arrayObjectParameters = [
    "degrees",
    "masters",
    "specialties",
    "phds",
  ];

  return users.filter((user) => {
    const content = parameters.map((p) => user[p].toLowerCase()).join(separator);

    if (content.includes(lowCaseQuery)) {
      return true;
    }

    // if it hasn't been found, try looking in the object parameters of the user
    const arrayObjectContent = arrayObjectParameters.map((p) => {
      return user[p].map((x) => x.name).join(separator).toLowerCase();
    }).filter((x) => x !== "").join(separator);

    if (arrayObjectContent.includes(lowCaseQuery)) {
      return true
    }

    // special case: is visible?
    if (lowCaseQuery === "escondido" && user?.hidden === 1) {
      return true;
    }

    // if it hasn't been found, fetch valuations to see if user is found
    const userValuations = valuations.filter((valuation) => user.valuations.includes(valuation._id.toString()))
      .map((v) => v.name.toLowerCase()).join(separator);
    if (userValuations.includes(lowCaseQuery)) {
      return true;
    }

    return false;


  });
};

//////////////////////////////////////////////////////////////////////////////

// _p and valuations is added as a function parameter to have the same number of parameters as the filterList
export const filterEventsList = (events, query, _p = "", valuations = []) => {
  if (!query) {
    return events;
  }

  const separator = "|";

  return events.filter((event) => {
    const dateFormat1 = moment(event["date"]).locale("es").format("DD/MM/YYYY");
    const dateFormat2 = moment(event["date"]).locale("es").format("DD/MMM/YYYY").replace(".", "");
    const dateFormat3 = moment(event["date"]).locale("es").format("DD/MMMM/YYYY");
    const title = event["title"];

    const parameters = [dateFormat1, dateFormat2, dateFormat3, title.toLowerCase()];
    const content = parameters.join(separator);

    return content.includes(query.toLowerCase());
  });
};

//////////////////////////////////////////////////////////////////////////////

export const filterAssembly = (list, userAttendedEvents = [], parameter = ALL_ATTENDED) => {
  if (parameter === ALL_ATTENDED) {
    return list;
  }

  const gte = (a, b) => {return a >= b};
  const lt = (a, b) => {return a < b};
  const comparator = parameter === MINIMUM_ATTENDED ? gte : lt;

  const filteredList = list.filter((event) => {
    const index = userAttendedEvents.findIndex((attendance) => attendance.eventId.toString() === event._id.toString());
    if (index !== -1) {
      return comparator(userAttendedEvents[index].time, event.minimumTime);
    }

    return false;
  });

  return filteredList;
};

//////////////////////////////////////////////////////////////////////////////

export const redirectScroll = (history) => (route = "/", state = null) => {
  history.push({
    pathname: route,
    state: state,
  });
  window.scrollTo(0, 0);
}

//////////////////////////////////////////////////////////////////////////////

export const formatTimeDuration = (seconds = 0) => {
  seconds = seconds ? seconds : 0;
  const time = moment.utc(seconds * 1000);
  return `${time.format("HH")}h ${time.format("mm")}m ${time.format("ss")}s`
}

//////////////////////////////////////////////////////////////////////////////

export const getAttendedTime = (attendedEvents, eventId) => {
  const index = attendedEvents.findIndex((attendance) => attendance.eventId.toString() === eventId.toString());
  if (index === -1) {
    return null;
  }

  return attendedEvents[index].time;
}

//////////////////////////////////////////////////////////////////////////////

export const secondsToTime = (timeSeconds) => {
  const time = parseInt(timeSeconds) > 0 ? parseInt(timeSeconds) : 0;
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time % 3600 / 60);
  const seconds = Math.floor(time % 3600 % 60);
  const result = hours || minutes || seconds ? `${hours}h${minutes}m${seconds}s` : ""

  return result;
}

//////////////////////////////////////////////////////////////////////////////

export const getHighestStudies = (user) => {
  const phds = user?.phds;
  if (phds?.length) {
    return phds[0].name;
  }

  const specialties = user?.specialties;
  if (user?.specialties?.length) {
    return specialties[0].name;
  }

  const masters = user?.masters;
  if (user?.masters?.length) {
    return masters[0].name;
  }

  const degrees = user?.degrees;
  if (user?.degrees?.length) {
    return degrees[0].name;
  }

  return "Miembro"; //default
}

//////////////////////////////////////////////////////////////////////////////
//
export const toCurrency = (quantity) => {
  return new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(quantity);
}

