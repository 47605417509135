import {
  SAVE_TRANSACTION_REQUEST,
  SAVE_TRANSACTION_SUCCESS,
  SAVE_TRANSACTION_FAILURE,
  ENABLE_EVENT_USER_REQUEST,
  ENABLE_EVENT_USER_SUCCESS,
  ENABLE_EVENT_USER_FAILURE,
  DISABLE_EVENT_USER_REQUEST,
  DISABLE_EVENT_USER_SUCCESS,
  DISABLE_EVENT_USER_FAILURE,
  SEND_EMAIL_WITH_EVENT_REQUEST,
  SEND_EMAIL_WITH_EVENT_SUCCESS,
  SEND_EMAIL_WITH_EVENT_FAILURE,
} from '../constants/actionTypes';
import * as api from '../api/index.jsx';
import {snackbar} from "../actions/notifications";
import {sendEmail} from "./general";

export const saveTransaction = (details, saveData, payedObject) => async (dispatch) => {
  try {
    dispatch({type: SAVE_TRANSACTION_REQUEST});
    const {data} = await api.saveTransaction(details, saveData, payedObject);

    dispatch({type: SAVE_TRANSACTION_SUCCESS, payload: data});
  } catch (error) {
    console.log(error);
    dispatch({type: SAVE_TRANSACTION_FAILURE, payload: error});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const enableEventForUser = (userId, productId, updateLocalStorage = true) => async (dispatch) => {
  try {
    dispatch({type: ENABLE_EVENT_USER_REQUEST});

    const {data} = await api.enableEventForUser(userId, productId);
    if (updateLocalStorage) {
      const token = JSON.parse(localStorage.getItem("profile"))?.token;
      localStorage.setItem("profile", JSON.stringify({data: data?.data, token: token}));
    }
    dispatch({type: ENABLE_EVENT_USER_SUCCESS, payload: data});
    dispatch(snackbar(data?.message, "success"));
  } catch (error) {
    console.log(error);
    dispatch({type: ENABLE_EVENT_USER_FAILURE, payload: error});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const disableEventForUser = (userId, productId, updateLocalStorage = true) => async (dispatch) => {
  try {
    dispatch({type: DISABLE_EVENT_USER_REQUEST});

    const {data} = await api.disableEventForUser(userId, productId);
    if (updateLocalStorage) {
      const token = JSON.parse(localStorage.getItem("profile"))?.token;
      localStorage.setItem("profile", JSON.stringify({data: data?.data, token: token}));
    }
    dispatch({type: DISABLE_EVENT_USER_SUCCESS, payload: data});
    dispatch(snackbar(data?.message, "success"));
  } catch (error) {
    console.log(error);
    dispatch({type: DISABLE_EVENT_USER_FAILURE, payload: error});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const sendEmailWithEvent = (email, eventId) => async (dispatch) => {
  try {
    dispatch({type: SEND_EMAIL_WITH_EVENT_REQUEST});

    const {data} = await api.fetchEvent(eventId);
    const to = email;
    const subject = "Su compra de comaviin.com";
    const message = data.paywallContentDescription || "No hay contenido";
    const contentType = "html";

    dispatch(sendEmail(to, subject, message, contentType));
    dispatch({type: SEND_EMAIL_WITH_EVENT_SUCCESS, payload: data});
    dispatch(snackbar("Correo enviado. Revise su bandeja de entrada, o bandeja de SPAM", "success"));
  } catch (error) {
    console.log(error);
    dispatch({type: SEND_EMAIL_WITH_EVENT_FAILURE, payload: error});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};
