import {
  Typography,
  Grid,
} from '@material-ui/core';

import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';

const DisplayPersonalData = ({user}) => {
  const {email, telephone, address, title, } = user;
  const description = "auto";

  return (
    <Grid container spacing={1} alignItems="center" justifyContent="center" style={{paddingBottom: "1.5rem"}} >

      <Typography variant="h5" component="p" gutterBottom >
        <strong>DATOS PERSONALES</strong>
      </Typography>

      <Grid container spacing={0} alignItems="center" justifyContent="center">
        <Grid item xs={12} >
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            <Typography variant="body1" color="textSecondary" component="p" gutterBottom >
              {description && description !== "auto" ? description : title}
            </Typography>
          </Grid>
        </Grid>

        {email &&
          <Grid item xs={12} >
            <Grid container direction="row" justifyContent="center" alignItems="center" >
              <MailIcon />
              <Typography variant="body1" color="textSecondary" component="p" gutterBottom >
                {email}
              </Typography>
            </Grid>
          </Grid>
        }
        {telephone &&
          <Grid item xs={12} >
            <Grid container direction="row" justifyContent="center" alignItems="center" >
              <PhoneIcon />
              <Typography variant="body1" color="textSecondary" component="p" gutterBottom >
                {telephone}
              </Typography>
            </Grid>
          </Grid>
        }

        {address !== "" &&
          <Grid item xs={12} >
            <Grid container direction="row" justifyContent="center" alignItems="center" >
              <HomeIcon />
              <Typography variant="body1" color="textSecondary" component="p" gutterBottom >
                {address}
              </Typography>
            </Grid>
          </Grid>
        }
      </Grid>
    </Grid>
  );
};

export default DisplayPersonalData;
