import {useState, useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Tooltip,
} from '@material-ui/core';

// wysiwyg editor.
// customize toolbar documentation https://github.com/zenoamaro/react-quill#custom-toolbar
// this is for react-quill (https://github.com/zenoamaro/react-quill) but there are other alternatives:
// React Draft Wysiwyg (https://github.com/jpuri/react-draft-wysiwyg#readme)
// slate, but its still in beta (https://github.com/ianstormtaylor/slate#readme)
// react-jodit, lightweight, but not as popular with not many features (https://www.npmjs.com/package/jodit-react)
// more alterlatives: https://ourcodeworld.com/articles/read/1065/top-15-best-rich-text-editor-components-wysiwyg-for-reactjs
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {DropzoneArea} from 'material-ui-dropzone';


import {getCouncil} from "../../actions/councils";
import {getCouncilMember, createCouncilMember, editCouncilMember} from "../../actions/councilMembers";
import {resetLoading} from "../../actions/general";
import SubmitButton from "../Utilities/SubmitButton";
import {redirectScroll} from "../../helpers/misc";


import useStyles from "./styles";

export default function CreateCouncilMember(props) {
  const classes = useStyles();
  const {edit = false} = props;

  const location = useLocation();
  const history = useHistory();

  const council = useSelector((state) => state.council);
  const councilMember = useSelector((state) => state.councilMember);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading?.CREATE_COUNCIL_MEMBER?.request || state.loading?.EDIT_COUNCIL_MEMBER?.request || false);
  const isSuccess = useSelector((state) => state.loading?.CREATE_COUNCIL_MEMBER?.success || state.loading?.EDIT_COUNCIL_MEMBER?.success || false);
  const urlArray = location.pathname.split("/");

  const bigUrlLength = "/administrador/miembrosConsejo/editar/:idCouncil/:idCouncilMember".split("/").length;
  const councilId = urlArray.length === bigUrlLength
    ? urlArray[urlArray.length - 2]  // "/administrador/miembrosConsejo/editar/:idCouncil/:idCouncilMember"
    : urlArray[urlArray.length - 1]; // "/administrador/miembrosConsejo/crear/:idCouncil"
  const councilMemberId = urlArray.length === bigUrlLength
    ? urlArray[urlArray.length - 1] // "/administrador/miembrosConsejo/editar/:idCouncil/:idCouncilMember"
    : ""; // "/administrador/miembrosConsejo/crear/:idCouncil"


  const initialState = {
    fullName: councilMember?.fullName || "",
    boardTitle: councilMember?.boardTitle || "",
    personalData: councilMember?.personalData || "",
    curriculum: councilMember?.curriculum || "",
    imageName: "",
    uploadedImage: null,
  };
  const [updateForm, setUpdateForm] = useState(true);
  const [formData, setFormData] = useState({});

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    // add council id to formData so it becomes reachable in the controller
    formData["councilId"] = council._id;
    const fd = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      fd.append(key, value);
    }

    if (!councilMember) {
      dispatch(createCouncilMember(fd));
    } else {
      dispatch(editCouncilMember(fd, councilMember._id));
    }
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleChangeRichText = (name) => (val) => {
    setFormData({...formData, [name]: val});
  };

  const handleOnchangeDropzone = (files) => {
    setFormData({
      ...formData,
      ["uploadedImage"]: files[0],
      ["imageName"]: files[0]?.name,
    });
  };
  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getCouncil(councilId));
  }, [dispatch, councilId]);

  useEffect(() => {
    if (councilMemberId) {
      dispatch(getCouncilMember(councilMemberId));
    }
  }, [dispatch, councilMemberId]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      redirectScroll(history)(`/administrador/consejos/${councilId}`);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (edit && councilMember && updateForm) {
      setUpdateForm(false);
      setFormData(initialState);
    }
  }, [edit, councilMember, updateForm, initialState]);
  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Grid container direction="column" justifyContent="center" alignItems="center" >
            <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
              {councilMember
                ? "Editar Miembro de Consejo"
                : "Crear Miembro de Consejo"}
            </Typography>
          </Grid>
        </Grid>


        {(!edit || (edit && councilMember && !updateForm)) &&
          <form className={classes.form} noValidate onSubmit={handleSubmit} >
            <Grid container spacing={3} >

              <Grid item xs={12} >
                <Tooltip title="Nombre completo del miembro de consejo" arrow >
                  <Paper>
                    <TextField
                      autoFocus
                      required
                      fullWidth
                      name="fullName"
                      variant="outlined"
                      id="fullName"
                      label="Nombre Completo"
                      onChange={handleChange}
                      defaultValue={formData?.fullName}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Puesto del miembro de consejo" arrow >
                  <Paper>
                    <TextField
                      required
                      fullWidth
                      variant="outlined"
                      name="boardTitle"
                      id="boardTitle"
                      label="Puesto"
                      onChange={handleChange}
                      defaultValue={formData?.boardTitle}
                    />
                  </Paper>
                </Tooltip>
              </Grid>


              <Grid item xs={12}>

                <div style={{color: "rgb(2 -1 0 / 0.54)"}}>
                  <Typography variant="h5" component="legend" gutterBottom > Descripción </Typography>
                  <Typography variant="body1" component="legend" gutterBottom >
                    Escriba aquí los datos generales del miembro del consejo. Datos tales como teléfono, correo electronico, etc.
                  </Typography>
                </div>

                <Paper>
                  <ReactQuill
                    theme="snow"
                    value={formData.personalData}
                    onChange={handleChangeRichText("personalData")}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>

                <div style={{color: "rgb(2 -1 0 / 0.54)"}}>
                  <Typography variant="h5" component="legend" gutterBottom > Semblanza </Typography>
                  <Typography variant="body1" component="legend" gutterBottom >
                    Escriba aquí la semblanza del miembro del consejo. Datos tales como sus estudios, experiencia laboral, tipos de avaluos que realiza, etc.
                  </Typography>
                </div>

                <Paper>
                  <ReactQuill
                    theme="snow"
                    value={formData.curriculum}
                    onChange={handleChangeRichText("curriculum")}
                  />
                </Paper>
              </Grid>


              <Grid item xs={12}>

                <div style={{color: "rgb(2 -1 0 / 0.54)"}}>
                  <Typography variant="h5" component="legend" gutterBottom >Imagen</Typography>
                  <Typography variant="body1" component="legend" gutterBottom >
                    Si sube una imagen, ésta se mostrara como foto de perfil del miembro de consejo.
                  </Typography>

                  <Typography variant="body2" component="legend" gutterBottom >
                    <strong><em>NOTA:</em></strong> Dependiendo de las dimensiones de la imagen, es posible que no se pueda visualizar completamente
                  </Typography>
                </div>

                <DropzoneArea
                  filesLimit={1}
                  onChange={handleOnchangeDropzone}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/jpg']}
                  //showPreviews={true}
                  maxFileSize={5000000}
                  name="uploadedImage"
                  dropzoneText="Arrastra el archivo o haz click aqui"
                  inputProps={{name: "uploadedImage"}}
                />

              </Grid>

              <SubmitButton
                isLoading={isLoading}
                textCondition={councilMember}
              />

            </Grid>
          </form>
        }
      </Grid>
    </>
  );
}
