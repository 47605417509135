import {FETCH_SINGLE_EMAIL_SUCCESS} from "../../constants/actionTypes";

const emailReducer = (email = null, action) => {
  switch (action.type) {
    case FETCH_SINGLE_EMAIL_SUCCESS:
      return action.payload;

    default:
      return email;

  }
}

export default emailReducer;
