import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {MemberRoute, AdminRoute} from './PrivateRoutes';

import CssBaseline from '@material-ui/core/CssBaseline';
import Notifier from '../Notifier/Notifier';
import Container from '@material-ui/core/Container';

import Header from '../../Header';
import App from '../../App';
import AdminDashboard from '../../components/AdminDashboard/AdminDashboard';
import AdminHome from '../../components/AdminDashboard/Home';
import UpdateBoardMembers from '../../components/AdminDashboard/UpdateBoardMembers/UpdateBoardMembers';
import UpdateValuations from '../../components/AdminDashboard/UpdateValuations/UpdateValuations';
import MemberDashboard from '../../components/MemberDashboard/MemberDashboard';
import MemberHome from '../../components/MemberDashboard/Home';
import UpdateProfile from '../../components/MemberDashboard/UpdateProfile';
import UpdateStudies from '../../components/AdminDashboard/UpdateStudies/UpdateStudies';
import UpdatePassword from '../../components/MemberDashboard/UpdatePassword';
import Directory from '../../components/Directory/Directory';
import IndividualUser from '../../components/Directory/IndividualUser';
import Services from '../../components/Services/Services';
import Avaluo from '../../components/Services/Avaluo';
import CouncilView from '../../components/Council/CouncilView';
import SingleCouncil from '../../components/Council/SingleCouncil';
import IndividualCouncilMember from '../../components/Council/IndividualCouncilMember';
import CreateCouncil from '../../components/Council/CreateCouncil';
import CreateCouncilMember from '../../components/Council/CreateCouncilMember';
import AboutUs from '../../components/AboutUs/AboutUs';
import Signin from '../../components/Auth/Signin';
import Signup from '../../components/Auth/Signup';
import PostSignup from '../../components/Auth/PostSignup';
import ForgotPassword from '../../components/Auth/ForgotPassword';
import ResetPassword from '../../components/Auth/ResetPassword';
import PreRegistration from '../../components/Auth/PreRegistration';
import Footer from '../../Footer';
import Paypal from '../../components/Paypal/Paypal';


import AdminEvents from '../AdminDashboard/events/AdminEvents';
import CreateEvent from '../AdminDashboard/events/CreateEvent';
import MarkAttendance from '../AdminDashboard/events/MarkAttendance';
import CalendarView from '../Events/CalendarView';
import EventView from '../Events/EventView';
import PaywallView from '../AdminDashboard/PaywallView/PaywallView';
import {EVENT, COURSE, ASSEMBLY, ALL_EVENTS, PURCHASED_EVENTS, ATTENDED_EVENTS, PURCHASABLE_EVENTS} from "../../constants/events";
import {ONLY_EXTRAORDINARY, ONLY_NORMAL, DISABLED_USER} from "../../constants/misc";

function Router() {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>

        <Notifier />
        <Container disableGutters={true} maxWidth={false} >
          <Header title="COMAVIIN" />
        </Container>

        <Container maxWidth={false} >
          <Switch>

            <Route path="/home">
              <App />
            </Route>

            {/*MEMBER ROUTES*/}
            <MemberRoute path="/miembros/perfil">
              <MemberDashboard Content={UpdateProfile} />
            </MemberRoute>

            <MemberRoute path="/miembros/contraseña">
              <MemberDashboard Content={UpdatePassword} />
            </MemberRoute>

            <MemberRoute path="/miembros/compras/eventos">
              <MemberDashboard Content={EventView} renderEventTabs={false} eventType={EVENT} listOfEvents={PURCHASED_EVENTS} />
            </MemberRoute>

            <MemberRoute path="/miembros/compras/asambleas">
              <MemberDashboard Content={EventView} renderEventTabs={false} eventType={ASSEMBLY} listOfEvents={PURCHASED_EVENTS} />
            </MemberRoute>

            <MemberRoute path="/miembros/compras/cursos">
              <MemberDashboard Content={EventView} renderEventTabs={false} eventType={COURSE} listOfEvents={PURCHASED_EVENTS} />
            </MemberRoute>

            <MemberRoute path="/miembros/asistencias/eventos">
              <MemberDashboard Content={EventView} renderEventTabs={false} eventType={EVENT} listOfEvents={ATTENDED_EVENTS} />
            </MemberRoute>

            <MemberRoute path="/miembros/asistencias/asambleas/extraordinarias">
              <MemberDashboard Content={EventView} renderEventTabs={false} eventType={ASSEMBLY} listOfEvents={ATTENDED_EVENTS} showAttendances={true} extraordinary={ONLY_EXTRAORDINARY} />
            </MemberRoute>

            <MemberRoute path="/miembros/asistencias/asambleas">
              <MemberDashboard Content={EventView} renderEventTabs={false} eventType={ASSEMBLY} listOfEvents={ATTENDED_EVENTS} showAttendances={true} extraordinary={ONLY_NORMAL} />
            </MemberRoute>

            <MemberRoute path="/miembros/asistencias/cursos">
              <MemberDashboard Content={EventView} renderEventTabs={false} eventType={COURSE} listOfEvents={ATTENDED_EVENTS} />
            </MemberRoute>


            <MemberRoute path="/miembros/eventos">
              <MemberDashboard Content={MemberHome} />
            </MemberRoute>

            <MemberRoute path="/miembros/cursos">
              <MemberDashboard Content={MemberHome} />
            </MemberRoute>

            <MemberRoute path="/miembros">
              <MemberDashboard Content={MemberHome} />
            </MemberRoute>
            {/*MEMBER ROUTES*/}

            {/*ADMIN ROUTES*/}
            <AdminRoute path="/administrador/usuarios/deshabilitados">
              <AdminDashboard Content={Directory} disabledUsers={true} showApprove={true} includeHidden={true} />
            </AdminRoute>

            <AdminRoute path="/administrador/directorio/deshabilitados/:id">
              <AdminDashboard Content={IndividualUser} typeUser={DISABLED_USER} />
            </AdminRoute>


            <AdminRoute path="/administrador/usuarios">
              <AdminDashboard Content={Directory} showApprove={true} includeHidden={true} />
            </AdminRoute>

            <AdminRoute path="/administrador/usuario/editar/estudios/deshabilitado/:id">
              <AdminDashboard Content={UpdateStudies} userType={DISABLED_USER} />
            </AdminRoute>

            <AdminRoute path="/administrador/usuario/editar/deshabilitado/:id">
              <AdminDashboard Content={UpdateProfile} userType={DISABLED_USER} />
            </AdminRoute>

            <AdminRoute path="/administrador/usuario/editar/estudios/:id">
              <AdminDashboard Content={UpdateStudies} />
            </AdminRoute>

            <AdminRoute path="/administrador/usuario/editar/:id">
              <AdminDashboard Content={UpdateProfile} />
            </AdminRoute>

            <AdminRoute path="/administrador/usuario/contenidopaga/:id">
              <AdminDashboard Content={PaywallView} renderEventTabs={false} listOfEvents={PURCHASABLE_EVENTS} unlockPaywall={true} />
            </AdminRoute>

            <AdminRoute path="/administrador/eventos/lista">
              <AdminDashboard Content={AdminEvents} />
            </AdminRoute>

            <AdminRoute path="/administrador/eventos/asistencias/:id?">
              <AdminDashboard Content={MarkAttendance} />
            </AdminRoute>

            <AdminRoute path="/administrador/eventos/crear">
              <AdminDashboard Content={CreateEvent} eventType={EVENT} />
            </AdminRoute>

            <AdminRoute path="/administrador/eventos/editar/:id?">
              <AdminDashboard Content={CreateEvent} eventType={EVENT} />
            </AdminRoute>

            <AdminRoute path="/administrador/eventos">
              <AdminDashboard Content={AdminHome} />
            </AdminRoute>

            <AdminRoute path="/administrador/asambleas/lista">
              <AdminDashboard Content={AdminEvents} eventType={ASSEMBLY} />
            </AdminRoute>

            <AdminRoute path="/administrador/asambleas/editar/:id?">
              <AdminDashboard Content={CreateEvent} eventType={ASSEMBLY} />
            </AdminRoute>

            <AdminRoute path="/administrador/asambleas/crear">
              <AdminDashboard Content={CreateEvent} eventType={ASSEMBLY} />
            </AdminRoute>

            <AdminRoute path="/administrador/asambleas">
              <AdminDashboard Content={AdminHome} />
            </AdminRoute>

            <AdminRoute path="/administrador/cursos/lista">
              <AdminDashboard Content={AdminEvents} eventType={COURSE} />
            </AdminRoute>

            <AdminRoute path="/administrador/cursos/editar/:id?">
              <AdminDashboard Content={CreateEvent} eventType={COURSE} />
            </AdminRoute>

            <AdminRoute path="/administrador/cursos/crear">
              <AdminDashboard Content={CreateEvent} eventType={COURSE} />
            </AdminRoute>

            <AdminRoute path="/administrador/cursos">
              <AdminDashboard Content={AdminHome} />
            </AdminRoute>

            <AdminRoute path="/administrador/miembrosConsejo/editar/:idCouncil/:idCouncilMember">
              <AdminDashboard Content={CreateCouncilMember} edit={true} />
            </AdminRoute>

            <AdminRoute path="/administrador/miembrosConsejo/crear/:idCouncil">
              <AdminDashboard Content={CreateCouncilMember} />
            </AdminRoute>

            <AdminRoute path="/administrador/miembrosConsejo/:councilId/:councilMemberId">
              <AdminDashboard Content={IndividualCouncilMember} />
            </AdminRoute>

            <AdminRoute path="/administrador/consejos/editar/:id">
              <AdminDashboard Content={CreateCouncil} edit={true} />
            </AdminRoute>

            <AdminRoute path="/administrador/consejos/crear">
              <AdminDashboard Content={CreateCouncil} />
            </AdminRoute>

            <AdminRoute path="/administrador/consejos/:id">
              <AdminDashboard Content={SingleCouncil} showControlButtons={true} />
            </AdminRoute>

            <AdminRoute path="/administrador/consejos">
              <AdminDashboard Content={CouncilView} showControlButtons={true} />
            </AdminRoute>

            <AdminRoute path="/administrador/consejo">
              <AdminDashboard Content={UpdateBoardMembers} />
            </AdminRoute>

            <AdminRoute path="/administrador/valuaciones/:id">
              <AdminDashboard Content={UpdateValuations} />
            </AdminRoute>

            <AdminRoute path="/administrador">
              <AdminDashboard Content={AdminHome} />
            </AdminRoute>
            {/*ADMIN ROUTES*/}

            {/*PUBLIC ROUTES*/}
            <Route path="/directorio/:id">
              <IndividualUser />
            </Route>

            <Route path="/directorio">
              <Directory />
            </Route>

            <Route path="/eventos/calendario/:type?/:id?">
              <CalendarView />
            </Route>

            <Route path="/eventos/asambleas/:type?/:id?">
              <EventView eventType={ASSEMBLY} />
            </Route>

            <Route path="/eventos/eventos/:type?/:id?">
              <EventView eventType={EVENT} />
            </Route>

            <Route path="/eventos/cursos/:type?/:id?">
              <EventView eventType={COURSE} />
            </Route>

            <Route path="/servicios/:serviceUrl">
              <Avaluo />
            </Route>

            <Route path="/servicios/avaluo2">
              <Avaluo valuationid={2} title="Avaluo 2" text="very big text goes in here" image="" />
            </Route>

            <Route path="/contacto">
              <Services />
            </Route>

            <Route path="/miembrosConsejo/:councilId/:councilMemberId">
              <IndividualCouncilMember />
            </Route>

            <Route path="/consejos/:id">
              <SingleCouncil />
            </Route>


            <Route path="/consejos">
              <CouncilView />
            </Route>

            <Route path="/aboutus">
              <AboutUs />
            </Route>

            <Route path="/preregistro">
              <PreRegistration />
            </Route>

            <Route path="/registro">
              <Signup />
            </Route>

            <Route path="/postregistro">
              <PostSignup />
            </Route>

            <Route path="/acceder">
              <Signin />
            </Route>

            <Route path="/claveOlvidada">
              <ForgotPassword />
            </Route>

            <Route path="/reiniciarClave/:passwordToken">
              <ResetPassword />
            </Route>

            <Route path="/paypal">
              <Paypal />
            </Route>


            <Route path="/">
              <App />
            </Route>
            {/*PUBLIC ROUTES*/}

          </Switch>


        </Container>
      </BrowserRouter>
      <Footer title="" description="" />
    </>
  );
}

export default Router;
