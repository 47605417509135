import {FETCH_ALL_TRANSACTIONS_SUCCESS, } from "../../constants/actionTypes";

const transactionsReducer = (transactions = [], action) => {
  switch (action.type) {
    case FETCH_ALL_TRANSACTIONS_SUCCESS:
      return action.payload;

    default:
      return transactions;

  }
}

export default transactionsReducer;
