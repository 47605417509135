import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  appbar: {
    marginBottom: theme.spacing(3),
  },

  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
    paddingTop: theme.spacing(2),
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    textTransform: "uppercase",
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },

  img: {
    maxWidth: "100%",
    maxHeight: "80px",
  },
}));
