import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import {Typography} from "@material-ui/core";

import EventView from "../../Events/EventView";

import {getUser} from "../../../actions/users";

export default function PaywallView(props) {

  const location = useLocation();
  const userId = location.pathname.split("/").pop() || null;
  const user = useSelector((state) => state.user || null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (userId);
    dispatch(getUser(userId));
  }, [userId]);

  //////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Typography variant="h4" gutterBottom >
        <strong>Editando a {user?.firstName} {user?.lastName}</strong>
      </Typography>

      <EventView {...props} useStateUser={true} />
    </>
  );
}


