import {useState, useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Tooltip,
} from '@material-ui/core';

import {DropzoneArea} from 'material-ui-dropzone';

// datepicker
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';

import CircularProgress from '@material-ui/core/CircularProgress';

import {updateProfile, getUser, getDisabledUser} from "../../actions/users";
import {resetLoading} from "../../actions/general";
import {redirectScroll} from "../../helpers/misc";
import {NORMAL_USER} from "../../constants/misc";
import {isAdmin} from "../../api/index";

import useStyles from "./styles";

export default function UpdateProfile({userType = NORMAL_USER}) {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading?.UPDATE_PROFILE?.request || state.loading?.UPDATE_DISABLED_USER_PROFILE?.request || false);
  const isSuccess = useSelector((state) => state.loading?.UPDATE_PROFILE?.success || state.loading?.UPDATE_DISABLED_USER_PROFILE?.success || false);
  const normalUser = useSelector((state) => state.user);
  const disabledUser = useSelector((state) => state.disabledUser);
  const loggedUser = JSON.parse(localStorage.getItem("profile"))?.data;

  const [fillForm, setFillForm] = useState(true);
  const [user, setUser] = useState(null);
  const editRoute = userType === NORMAL_USER
    ? `/administrador/usuario/editar/estudios/${user?._id}`
    : `/administrador/usuario/editar/estudios/deshabilitado/${user?._id}`;

  const {id} = useParams();
  const userId = id ? id : JSON.parse(localStorage.getItem("profile"))?.data?._id;

  const initialState = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    title: user?.title || "",
    telephone: user?.telephone || "",
    birthday: user?.birthday,
    address: user?.address || "",
    uploadedImage: "",
    imageName: "",
    //degrees: user?.degrees?.length ? user.degrees : [],
    //masters: user?.masters?.length ? user.masters : [],
    //specialties: user?.specialties?.length ? user.specialties : [],
    //phds: user?.phds?.length ? user.phds : [],
  };

  const [formData, setFormData] = useState({});

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    const fd = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      fd.append(key, value);
    }

    dispatch(updateProfile(user?._id, fd, userType)); // if userType is NORMAL_USER, update the current logged in user
  };

  const handleChange = (e, name = null) => {
    if (!name) {
      name = e.target.name;
    }
    setFormData({...formData, [name]: e.target.value});
  };

  const handleChangeDate = (date) => {
    setFormData({...formData, birthday: moment(date).format("YYYY/MM/DD")});
  }

  const handleOnchangeDropzone = (files) => {
    setFormData({
      ...formData,
      ["uploadedImage"]: files[0],
      ["imageName"]: files[0]?.name,
    });
  };

  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (fillForm && user) {
      setFormData(initialState);
      setFillForm(false);
    }
  }, [initialState, fillForm, user]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      if (isAdmin(loggedUser)) {
        const newRoute = userType === NORMAL_USER
          ? "/administrador/usuarios"
          : "/administrador/usuarios/deshabilitados";
        redirectScroll(history)(newRoute);
      } else {
        redirectScroll(history)("/miembros");
      }
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    if (userType === NORMAL_USER) {
      dispatch(getUser(userId));
    } else {
      dispatch(getDisabledUser(userId));
    }
  }, [dispatch, userId, userType]);

  useEffect(() => {
    if (userType === NORMAL_USER) {
      setUser(normalUser);
    } else {
      setUser(disabledUser);
    }
  }, [userType, normalUser, disabledUser]);
  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Grid container direction="column" justifyContent="center" alignItems="center" >
            <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
              Editar perfil
            </Typography>

            {isAdmin(loggedUser) &&
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    redirectScroll(history)(editRoute)}
                >
                  Editar Datos como Administrador
                </Button>
              </Grid>
            }


          </Grid>
        </Grid>


        {Object.keys(formData).length
          ? <form className={classes.form} noValidate onSubmit={handleSubmit} >
            <Grid container spacing={3} >

              <Grid item xs={12} >
                <Tooltip title="Nombre" arrow >
                  <Paper>
                    <TextField
                      autoFocus
                      required
                      fullWidth
                      name="firstName"
                      variant="outlined"
                      id="firstName"
                      label="Nombres"
                      onChange={handleChange}
                      defaultValue={formData.firstName}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Apellidos" arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="lastName"
                      id="lastName"
                      label="Apellidos"
                      onChange={handleChange}
                      defaultValue={formData.lastName}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Correo electronico" arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      name="email"
                      id="email"
                      label="Email"
                      onChange={handleChange}
                      defaultValue={formData.email}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Título que desea que se muestre en su perfil" arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="title"
                      id="title"
                      label="Título"
                      onChange={handleChange}
                      defaultValue={formData.title}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Número telefónico" arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="telephone"
                      id="telephone"
                      label="Teléfono"
                      onChange={handleChange}
                      defaultValue={formData.telephone}
                    />
                  </Paper>
                </Tooltip>
              </Grid>


              <Grid item xs={12}>
                <Tooltip title="Fecha de cumpleaños" arrow >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      required
                      name="birthday"
                      id="birthday"
                      variant="outlined" //inline ?
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Cumpleaños"
                      KeyboardButtonProps={{
                        'aria-label': 'Cambiar fecha',
                      }}
                      value={formData.birthday ? formData.birthday : moment().format("YYYY/MM/DD")}
                      onChange={handleChangeDate}
                    />

                  </MuiPickersUtilsProvider>
                </Tooltip>
              </Grid>


              <Grid item xs={12}>
                <Tooltip title="Dirección" arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="address"
                      id="address"
                      label="Dirección"
                      onChange={handleChange}
                      defaultValue={formData.address}
                    />
                  </Paper>
                </Tooltip>
              </Grid>


              <Grid item xs={12}>
                <div style={{color: "rgb(2 -1 0 / 0.54)"}}>
                  <Typography variant="h5" component="legend" gutterBottom >Foto de perfil</Typography>

                  <Typography variant="body2" component="legend" gutterBottom >
                    <strong><em>NOTA:</em></strong> Solo puede subir fotos en formato jpeg, png, jpg o bmp
                  </Typography>
                </div>


                <DropzoneArea
                  filesLimit={1}
                  onChange={handleOnchangeDropzone}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/jpg']}
                  //showPreviews={true}
                  maxFileSize={5000000}
                  name="uploadedImage"
                  dropzoneText="Arrastra el archivo o haz click aqui"
                  inputProps={{name: "uploadedImage"}}
                />

              </Grid>


              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
              >
                {isLoading
                  ? (<>
                    <CircularProgress />
                  </>)
                  : "Editar"
                }
              </Button>

            </Grid>
          </form>
          : <CircularProgress />
        }
      </Grid>
    </>
  );
}
