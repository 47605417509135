import {useState} from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Button,
  Link,
} from '@material-ui/core';
import services from "./ServicesList";
import ContactUs from "../ContactUs/ContactUs";


import useStyles from "./styles";


export default function Services() {
  const classes = useStyles();

  const [selectedService, setSelectedService] = useState(null);
  const handleServiceClick = (service) => {setSelectedService(service)};

  return (
    <>
      {/* <Typography */}
      {/*   component="h2" */}
      {/*   variant="h2" */}
      {/*   color="inherit" */}
      {/*   align="center" */}
      {/*   gutterBottom */}
      {/*   noWrap */}
      {/*   className={classes.sectionTitle} */}
      {/* > */}
      {/*   <strong>SERVICIOS</strong> */}
      {/* </Typography> */}


      {/* <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center"> */}
      {/*   <Grid item xs={12} > */}
      {/*     <Typography */}
      {/*       component="p" */}
      {/*       variant="body1" */}
      {/*       color="inherit" */}
      {/*       align="center" */}
      {/*       gutterBottom */}
      {/*     > */}
      {/*       COMAVIIN es Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer in ligula nec enim dapibus fermentum. Curabitur egestas vehicula leo, quis venenatis quam suscipit vitae. Duis felis dolor, laoreet vitae gravida nec, laoreet at eros. Sed egestas nunc magna, eget rutrum justo fringilla sit amet. Donec semper laoreet odio quis scelerisque. Nulla vehicula varius ante, ut pretium quam lacinia sed. Integer blandit metus non nibh tincidunt mollis. Donec tincidunt blandit lacus vitae elementum. Nulla congue ligula commodo lacus pulvinar lobortis. Sed accumsan lectus non libero lacinia, et elementum erat posuere. Nullam nec lorem ex. */}

      {/*     </Typography> */}
      {/*   </Grid> */}

      {/* </Grid> */}

      {/* <Grid container spacing={4}> */}
      {/*   {services.map((service, i) => ( */}
      {/*     <Grid item key={i} xs={12} sm={6} md={4}> */}
      {/*       <Card className={classes.card}> */}
      {/*         <CardMedia */}
      {/*           component="img" */}
      {/*           className={classes.cardMedia} */}
      {/*           image={service.image} */}
      {/*           title="Image title" */}
      {/*         /> */}
      {/*         <CardContent className={classes.cardContent}> */}
      {/*           <Typography gutterBottom variant="h5" component="h2"> */}
      {/*             {service.title} */}
      {/*           </Typography> */}
      {/*           <Typography> */}
      {/*             Breve descripcion del avaluo */}
      {/*           </Typography> */}
      {/*         </CardContent> */}
      {/*         <CardActions> */}
      {/*           <Grid container justifyContent="space-between"> */}
      {/*             <Button size="small" color="primary"> */}
      {/*               <Link href={`servicios/${service.url}`} > */}
      {/*                 Ver Más */}
      {/*               </Link> */}
      {/*             </Button> */}

      {/*             <Button size="small" color="primary" onClick={() => handleServiceClick(service)}> */}
      {/*               Contactanos */}
      {/*             </Button> */}
      {/*           </Grid> */}
      {/*         </CardActions> */}
      {/*       </Card> */}
      {/*     </Grid> */}
      {/*   ))} */}
      {/* </Grid> */}


      <ContactUs selectedService={selectedService} scrollToForm={Boolean(selectedService)} />
    </>
  );
}

