import {
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';

import useStyles from "../Dashboard/styles";

export default function Home() {
  const classes = useStyles();

  return (
    <>
      <Typography align="center" variant="h4" gutterBottom className={classes.typography} >
        <strong>MIEMBRO COMAVIIN</strong>
      </Typography>


      <Grid container direction="column" className={classes.root} justifyContent="center" alignItems="center" spacing={2} >

        <Typography variant="body1" component="p" >
          En esta sección puede controlar y visualizar distintos elementos de su información

          <ul>
            <li>
              Puede Modificar su información de perfil (hay información que solo los administradores pueden editar)
            </li>
            <li>
              Puede modificar su contraseña de acceso
            </li>

            <li>
              Puede ver los eventos/asambleas/cursos que ha comprado
            </li>
            <li>
              Puede ver las asistencias que ha tenido en los eventos de COMAVIIN
            </li>

            <li>
              Puede ver una sección de documentos especiales
            </li>

          </ul>
        </Typography>

      </Grid>

    </>
  );
}
