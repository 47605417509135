import {FETCH_VALUATIONS_SUCCESS} from '../constants/actionTypes';

const Valuations = (valuations = [], action) => {
  switch (action.type) {
    case FETCH_VALUATIONS_SUCCESS:
      return action.payload;

    default:
      return valuations;

  }
};

export default Valuations;

