import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from '@material-ui/core';
import moment from "moment";

import {getPresidents} from "../../actions/councilMembers";
import {redirectScroll} from "../../helpers/misc";

import NO_AVATAR from "../../assets/images/no_avatar.png";

import useStyles from "./styles";

export default function PresidentsGallery(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const presidents = useSelector((state) => state.councilMembers);

  // effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getPresidents());
  }, [dispatch]);
  // effects /////////////////////////////////////////////////////////////////

  // on click ////////////////////////////////////////////////////////////////
  // on click ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Grid container spacing={3} className={classes.root} direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid item xs={12} >
          <Typography
            component="h2"
            variant="h2"
            color="inherit"
            align="center"
            gutterBottom
            className={classes.sectionTitle}
          >
            <strong>Presidentes de Consejos de COMAVIIN</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} >
          <Grid container spacing={1} className={classes.root} alignItems="center" justifyContent="center" >
            {presidents.map((member, i) => (
              < Grid item xs={12} md={6} lg={4} key={i} >

                <Card className={classes.root}>
                  <CardActionArea onClick={() => redirectScroll(history)(`/miembrosConsejo/${member.councilId}/${member._id}`)} style={{height: "400px"}} >
                    <CardMedia
                      component="img"
                      alt="Foto usuario"
                      image={member.avatar ? member.avatar : NO_AVATAR}
                      title="Miembro de consejo"
                      className={classes.img}
                    />
                    <CardContent>
                      <Grid container spacing={1} >


                        {member.startDate && member.endDate &&
                          <Grid item xs={12} >
                            <Grid container direction="row" justifyContent="center" alignItems="center" >
                              <Typography variant="body2" color="textSecondary" component="p">
                                {`${moment(member.startDate).format("YYYY")}-${moment(member.endDate).format("YYYY")}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        }

                        <Grid item xs={12} >
                          <Typography gutterBottom variant="h4" component="h4" style={{textAlign: "center"}}>
                            {member.fullName}
                          </Typography>
                        </Grid>

                      </Grid>
                    </CardContent>
                  </CardActionArea>

                </Card>

              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

    </>
  );
}
