import {makeStyles} from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  errorAlert: {
    //position: "absolute",
    justifyContent: "space-between",
    //animation: `$SlideDown 1s ${theme.transitions.easing.easeInOut}`
  },

  "@keyframes SlideDown": {
    "0%": {
      transform: "translate3d(0, -150px, 0)",
    },
    "100%": {
      transform: "translate3d(0, 0, 0)",
    },
  }
}));

