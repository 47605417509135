import {
  FETCH_VALUATIONS_REQUEST,
  FETCH_VALUATIONS_SUCCESS,
  FETCH_VALUATIONS_FAILURE,
} from '../constants/actionTypes';
import * as api from '../api/index.jsx';

export const getValuations = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_VALUATIONS_REQUEST});
    const {data} = await api.fetchValuations();

    dispatch({type: FETCH_VALUATIONS_SUCCESS, payload: data.data});
  } catch (error) {
    console.log(error);
    dispatch({type: FETCH_VALUATIONS_FAILURE, payload: error});
  }
};
