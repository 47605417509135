import {combineReducers} from 'redux';
import users from "./users";
import user from "./user";
import disabledUser from "./disabledUser";
import auth from "./auth";
import events from "./events/events";
import event from "./events/event";
import publishedEvents from "./publishedEvents";
import featuredEvents from "./featuredEvents";
import notifications from "./notifications";
import loading from "./loadingReducer";
import attendances from "./events/attendances";
import valuations from "./valuations";
import council from "./councils/council";
import councils from "./councils/councils";
import councilMember from "./councils/councilMember";
import councilMembers from "./councils/councilMembers";
import email from "./emails/email";
import emails from "./emails/emails";
import transaction from "./transactions/transaction";
import transactions from "./transactions/transactions";

export const reducers = combineReducers({
  auth,
  user,
  disabledUser,
  users,
  event,
  events,
  publishedEvents,
  featuredEvents,
  notifications,
  loading,
  attendances,
  valuations,
  council,
  councils,
  councilMember,
  councilMembers,
  email,
  emails,
  transaction,
  transactions,
});
