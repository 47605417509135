import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useHistory, useParams} from "react-router-dom";
import moment from "moment";
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Button,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import NO_AVATAR from "../../assets/images/no_avatar.png";

import {getCouncil, getMembersOfCouncil} from "../../actions/councils";
import {deleteCouncilMember} from "../../actions/councilMembers";
import ConfirmDialog from "../Utilities/ConfirmDialog";
import {redirectScroll} from "../../helpers/misc";

import useStyles from "./styles";

export default function SingleCouncil(props) {
  const {showControlButtons = false} = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const councilId = location.pathname.split("/").pop();

  const council = useSelector((state) => state.council);
  const councilMembers = useSelector((state) => state.councilMembers);

  const [clickId, setClickId] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const councilMemberRoute = (councilMemberId) => {
    const route = showControlButtons
      ? `/administrador/miembrosConsejo/${councilId}/${councilMemberId}`
      : `/miembrosConsejo/${councilId}/${councilMemberId}`;
    redirectScroll(history)(route)
  }

  //effects //////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getCouncil(councilId));
  }, [dispatch, councilId]);

  useEffect(() => {
    dispatch(getMembersOfCouncil(councilId));
  }, [dispatch, councilId]);
  //effects //////////////////////////////////////////////////////////////////

  const handleDelete = () => {
    dispatch(deleteCouncilMember(clickId));
  };


  //on click /////////////////////////////////////////////////////////////////
  const confirmDelete = (member) => {
    setClickId(member._id);
    setShowDeleteDialog(true);
  }

  //on click /////////////////////////////////////////////////////////////////

  return (
    <>
      <Grid container spacing={3} className={classes.root} direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid item xs={12} >
          <Typography
            component="h2"
            variant="h2"
            color="inherit"
            align="center"
            gutterBottom
            className={classes.sectionTitle}
          >
            {council &&
              <strong>{`Consejo Directivo ${moment(council.startDate).format("YYYY")}-${moment(council.endDate).format("YYYY")}`}</strong>
            }
          </Typography>
        </Grid>

        {showControlButtons &&
          <Grid item xs={12} >
            <Button
              color="primary"
              variant="contained"
              onClick={() => redirectScroll(history)(`/administrador/miembrosConsejo/crear/${council?._id}`)}
            >
              Nuevo Miembro de Consejo
            </Button>
          </Grid>
        }


        <Grid item xs={12} >
          <Grid container spacing={1} className={classes.root} alignItems="center" justifyContent="center" >
            {councilMembers.map((member, i) => (
              < Grid item xs={12} md={6} lg={4} key={i} >

                <Card className={classes.root} >
                  <CardActionArea onClick={() => councilMemberRoute(member._id)} style={{height: "400px"}} >
                    <CardMedia
                      component="img"
                      alt="Foto usuario"
                      image={member.avatar ? member.avatar : NO_AVATAR}
                      title="Miembro de consejo"
                      className={classes.img}
                    />
                    <CardContent>
                      <Grid container spacing={1} >

                        <Grid item xs={12} >
                          <Typography gutterBottom variant="h4" component="h4" style={{textAlign: "center"}}>
                            {member.fullName}
                          </Typography>
                        </Grid>

                        {member.boardTitle &&
                          <Grid item xs={12} >
                            <Grid container direction="row" justifyContent="center" alignItems="center" >
                              <Typography variant="body2" color="textSecondary" component="p">
                                {member.boardTitle}
                              </Typography>
                            </Grid>
                          </Grid>
                        }

                      </Grid>
                    </CardContent>
                  </CardActionArea>

                  {showControlButtons &&
                    <Grid container justifyContent="space-evenly" >
                      <Grid item >
                        <Tooltip title="Editar" arrow>
                          <IconButton edge="end" aria-label="edit" onClick={() => redirectScroll(history)(`/administrador/miembrosConsejo/editar/${council?._id}/${member?._id}`)} >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Grid item >
                        <Tooltip title="Borrar" arrow>
                          <IconButton edge="end" aria-label="delete" onClick={() => confirmDelete(member)} >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  }

                </Card>

              </Grid>
            ))}
          </Grid>
        </Grid>


        {showDeleteDialog &&
          (<ConfirmDialog
            title="¿Esta seguro que desea eliminar el miembro de consejo?"
            onConfirm={handleDelete}
            open={showDeleteDialog}
            setOpen={setShowDeleteDialog}
            onClose={() => setClickId(null)}
          >
            <Grid container spacing={3} direction="column" justifyContent="center" >
              <Grid item >
                Esta intentando eliminar a un miembro del consejo.
              </Grid>

              <Grid item >
                Esta accion no podra deshacerse, <strong>¿esta seguro?</strong>
              </Grid>
            </Grid>
          </ConfirmDialog>)
        }

      </Grid>

    </>
  );
}
