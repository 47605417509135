import {useState, useEffect} from "react";
import {
  Typography,
  Grid,
  Tooltip,
  Paper,
  TextField,
  Button,
} from '@material-ui/core';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {v4 as uuidv4} from 'uuid';

const DegreeInput = (props) => {
  const {
    fullWidth,
    variant,
    name,
    title,
    handleChange,
    defaultValue,
  } = props;


  const [degrees, setDegrees] = useState(
    defaultValue?.length
      ? defaultValue.map(dv => {
        return {...dv, id: uuidv4()};
      })
      : []
  );

  ///////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    handleChange(name, degrees); // update formData
  }, [degrees, name]);

  ///////////////////////////////////////////////////////////////////////////

  const handleChangeDegree = (e, index) => {
    const inputName = e.target.name.split("-")[0];

    degrees[index][inputName] = e.target.value;
    setDegrees(degrees.slice()); // its enough to trigger a redraw using slice
  };

  // handle click event of the Remove button
  const handleRemoveDegree = (index) => {
    degrees.splice(index, 1);
    // in order to copy correctly all values, this mapping is done
    const cpy = degrees.map(a => {return {...a}});
    setDegrees(cpy);
  };

  // handle click event of the Add button
  const handleAddDegree = () => {
    const newDegrees = [...degrees, {name: "", university: "", licenseNumber: "", id: uuidv4()}];
    setDegrees(newDegrees);
  };

  ///////////////////////////////////////////////////////////////////////////


  return (
    <>
      <Typography variant="h3" gutterBottom>
        {title} ({degrees.length})
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          {degrees.map((degree, i) => (
            <Grid container spacing={3} alignItems="center" justifyContent="center" key={degree.id} >
              <Grid item xs={12}>
                <Tooltip title="Nombre de licenciatura" arrow >
                  <Paper>
                    <TextField
                      required
                      fullWidth={fullWidth}
                      variant={variant}
                      name={`name-${name}-${i}`}
                      label="Nombre"
                      onChange={(e) => handleChangeDegree(e, i)}
                      defaultValue={degree?.name || ""}
                    />
                  </Paper>
                </Tooltip>

                <Tooltip title="Universidad de licenciatura" arrow >
                  <Paper>
                    <TextField
                      required
                      fullWidth={fullWidth}
                      variant={variant}
                      name={`university-${name}-${i}`}
                      label="Universidad"
                      onChange={(e) => handleChangeDegree(e, i)}
                      defaultValue={degree?.university || ""}
                    />
                  </Paper>
                </Tooltip>

                <Tooltip title="Dirección" arrow >
                  <Paper>
                    <TextField
                      fullWidth={fullWidth}
                      variant={variant}
                      name={`licenseNumber-${name}-${i}`}
                      label="Número de Cédula"
                      onChange={(e) => handleChangeDegree(e, i)}
                      defaultValue={degree?.licenseNumber || ""}
                    />
                  </Paper>
                </Tooltip>

              </Grid>


              <Grid item xs={12}>
                <Grid container spacing={1} >
                  {degrees.length > 0 &&
                    <Grid item >
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleRemoveDegree(i)}
                      >
                        <DeleteOutlineIcon />
                      </Button>
                    </Grid>
                  }
                </Grid>
              </Grid>

            </Grid>
          ))}
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddDegree}
          >
            <PlusOneIcon />
          </Button>
        </Grid>
      </Grid>
    </>
  );
}; export default DegreeInput;
