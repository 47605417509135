import {useState, useEffect} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blueGrey from '@material-ui/core/colors/blueGrey';
import deepOrange from '@material-ui/core/colors/deepOrange';

import DisplayEducation from "./Utilities/DisplayEducation";

import {
  getUsers,
  getDisabledUsers,
  enableUser,
  disableUser,
  deleteDisabledUser,
} from '../actions/users';
import {resetLoading, sendEmail} from "../actions/general";
import {BANK, BANK_ACCOUNT, CONTACT_PHONE, ADMIN_EMAIL} from "../constants/misc";
import {ADMIN} from "../constants/roles";

import ConfirmDialog from "./Utilities/ConfirmDialog";
import {redirectScroll, getHighestStudies} from "../helpers/misc";

import clsx from "clsx";

export default function UserCard(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },

    img: {
      borderRadius: '50%',
      height: 200,
      width: 200,
      //left: '25%',
      marginLeft: "auto",
      marginRight: "auto",
      position: 'relative',
      padding: theme.spacing(1),
    },

    approveButton: {
      width: "100%",
    },

    enable: {
      color: green[800],
    },

    disable: {
      color: deepOrange["A700"],
    },

    unlockPaywall: {
      color: blueGrey[700],
    },

    delete: {
      color: red[800],
    },

    approved: {
      width: "100%",
      color: green[500],
      alignItems: "center",
    },

    noAnchorStyle: {
      color: "inherit",
      textDecoration: "none",
    },

    admin: {
      color: blueGrey[500],
    },

    visibilityOff: {
      color: blueGrey[500],
    },

  }));
  const classes = useStyles();

  const {
    userId,
    name,
    description = "auto",
    email,
    telephone,
    showApprove,
    disabledUsers,
    user,
    address = "",
    degrees = [],
    masters = [],
    specialties = [],
    phds = [],
    displayEducation = false,
    redirectRoute = "",
  } = props;


  const successfullyEnabledUser = useSelector((state) => state.loading?.ENABLE_USER?.success || false);
  const successfullyDisabledUser = useSelector((state) => state.loading?.DISABLE_USER?.success || false);

  const img = props.img;

  const dispatch = useDispatch();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEnableDialog, setShowEnableDialog] = useState(false);
  const [showDisableDialog, setShowDisableDialog] = useState(false);
  const history = useHistory();

  const infoRoute = redirectRoute
    ? redirectRoute
    : !disabledUsers ? `/directorio/${userId}` : `/administrador/directorio/deshabilitados/${user._id}`;

  // handlers ////////////////////////////////////////////////////////////////
  //const handleApproval = () => {
  //dispatch(approveUser(userId));
  //}

  const handleEnable = () => {
    dispatch(enableUser(userId));
  }

  const handleDisable = () => {
    dispatch(disableUser(userId));
  }

  const handleShowDeleteDialog = () => {
    setShowDeleteDialog(true);
  }

  const handleDelete = () => {
    const email = user?.email;
    const emailTitle = "Cuenta Eliminada";
    const emailBody = `Lamentamos informarle que su cuenta con nombre ${user.firstName} ${user.lastName} fue eliminada`;

    dispatch(sendEmail(email, emailTitle, emailBody, "text"));
    dispatch(deleteDisabledUser(userId));
  };

  const handleSendPaymentInfo = () => {
    const email = user?.email;
    const title = "Información de pago COMAVIIN";
    const content = "Le informamos que su solicitud de cuenta de COMAVIIN fue aceptada\n"
      + "Para poder utilizar su cuenta, debe realizar un deposito a la siguiente cuenta de banco:\n"
      + `${BANK}\n`
      + `${BANK_ACCOUNT}\n\n`
      + `Una vez haya realizado el deposito, puede enviar un correo a: ${ADMIN_EMAIL}\n`
      + `o mandar un mensaje de Whatsapp al telefono: ${CONTACT_PHONE}\n`
      + "Indicando que realizo el pago y cual fue el contenido por el que pago, para que se le habilite en su cuenta o se le envie a su correo ese contenido."
      ;

    dispatch(sendEmail(email, title, content, "text"));
  }

  // handlers ////////////////////////////////////////////////////////////////

  // effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (successfullyEnabledUser) {
      dispatch(getDisabledUsers());
    }
  }, [successfullyEnabledUser, dispatch])

  useEffect(() => {
    if (successfullyDisabledUser) {
      dispatch(getUsers());
    }
  }, [successfullyDisabledUser, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetLoading);
    }
  }, [dispatch])
  // effects /////////////////////////////////////////////////////////////////


  function ShowApproval() {

    return (<CardActions>
      <Grid container >
        {disabledUsers
          ? <>
            <Grid item xs={12} md={6} lg={4} >
              <Button color="primary" onClick={() => redirectScroll(history)(`/administrador/usuario/editar/deshabilitado/${user._id}`)} className={clsx(classes.approveButton, classes.unlockPaywall)}>
                <EditIcon /> Editar
              </Button>
            </Grid>

            <Grid item xs={12} md={6} lg={4} >
              <Button color="primary" onClick={handleEnable} className={clsx(classes.approveButton, classes.enable)}>
                <DoneIcon /> Habilitar
              </Button>
            </Grid>

            <Grid item xs={12} md={6} lg={4} >
              <Button color="primary" onClick={handleShowDeleteDialog} className={clsx(classes.approveButton, classes.delete)}>
                <DeleteForeverIcon /> Eliminar
              </Button>
            </Grid>

            <Grid item xs={12} md={6} lg={4} >
              <Button color="primary" onClick={handleSendPaymentInfo} className={clsx(classes.approveButton, classes.unlockPaywall)}>
                <MailIcon /> Enviar Datos Pago
              </Button>
            </Grid>

            {showEnableDialog &&
              (<ConfirmDialog
                title="¿Esta seguro que desea habilitar al usuario?"
                onConfirm={handleEnable}
                open={showEnableDialog}
                setOpen={setShowEnableDialog}
              >
                <Grid container spacing={3} direction="column" justifyContent="center" >
                  <Grid item >
                    <Typography gutterBottom>
                      Esta intentando habilitar al usuario <strong>{`${user?.firstName} ${user?.lastName}`}</strong>.
                    </Typography>
                  </Grid>

                  <Grid item >
                    <strong>¿está seguro?</strong>
                  </Grid>
                </Grid>
              </ConfirmDialog>)
            }

            {showDeleteDialog &&
              (<ConfirmDialog
                title="¿Esta seguro que desea eliminar al usuario?"
                onConfirm={handleDelete}
                open={showDeleteDialog}
                setOpen={setShowDeleteDialog}
                disableConfirmButtonTime={5000}
              >
                <Grid container spacing={3} direction="column" justifyContent="center" >
                  <Grid item >
                    <Typography gutterBottom>
                      Esta intentando eliminar al usuario <strong>{`${user?.firstName} ${user?.lastName}`}</strong>.
                    </Typography >
                  </Grid>

                  <Grid item >
                    <Typography gutterBottom>
                      Si confirma, el usuario ya no podrá acceder al sitio con su cuenta,
                      y se enviará un correo al usuario informandole que fue eliminado.
                    </Typography>

                  </Grid>


                  <Grid item >
                    Esta acción no podra deshacerse, <strong>¿está seguro?</strong>
                  </Grid>
                </Grid>
              </ConfirmDialog>)
            }

          </>
          : <>
            <Grid item xs={12} >
              <Button color="primary" className={classes.approveButton} onClick={() => redirectScroll(history)(`/administrador/usuario/editar/${user._id}`)} >
                <EditIcon /> Editar
              </Button>
            </Grid>

            <Grid item xs={12} >
              <Button color="primary" className={clsx(classes.approveButton, classes.unlockPaywall)} onClick={() => redirectScroll(history)(`/administrador/usuario/contenidopaga/${user?._id}`)} >
                <ShoppingCartIcon /> Habilitar Contenido Paga
              </Button>
            </Grid>

            <Grid item xs={12} >
              <Button color="primary" className={clsx(classes.approveButton, classes.unlockPaywall)} onClick={() => redirectScroll(history)(`/administrador/valuaciones/${user?._id}`)} >
                <ListIcon /> Editar Avaluos
              </Button>
            </Grid>

            <Grid item xs={12} >
              <Button color="primary" onClick={handleDisable} className={clsx(classes.approveButton, classes.disable)}>
                <BlockIcon /> Deshabilitar
              </Button>
            </Grid>

            {user?.hidden === 1 &&
              <Grid item xs={12} >
                <Button color="primary" className={clsx(classes.visibilityOff)}>
                  <VisibilityOffIcon /> Escondido
                </Button>
              </Grid>
            }

            {user?.roles.includes(ADMIN) &&
              <Grid item xs={12} >
                <Button color="primary" className={clsx(classes.admin)}>
                  <SupervisorAccountIcon /> Administrador
                </Button>
              </Grid>
            }

            {showDisableDialog &&
              (<ConfirmDialog
                title="¿Esta seguro que desea deshabilitar al usuario?"
                onConfirm={handleDisable}
                open={showDisableDialog}
                setOpen={setShowDisableDialog}
              >
                <Grid container spacing={3} direction="column" justifyContent="center" >
                  <Grid item >
                    Esta intentando deshabilitar al usuario <strong>{`${user?.firstName} ${user?.lastName}`}</strong>.
                  </Grid>

                  <Grid item >
                    El usuario ya no podrá iniciar sesión con su cuenta hasta ser habilitado otra vez
                    (pero si ya está ingresado, podrá seguir dentro hasta que su sesión expire en 1h).
                  </Grid>

                  <Grid item >
                    <strong>¿está seguro?</strong>
                  </Grid>
                </Grid>
              </ConfirmDialog>)
            }

          </>}

      </Grid>
    </CardActions>);
  }

  return (
    <Card className={classes.root} style={{height: "100%"}}>
      <CardActionArea onClick={() => redirectScroll(history)(infoRoute)}>
        <CardMedia
          component="img"
          alt="Foto usuario"
          image={img}
          title={
            user?.firstName || user?.lastName
              ? `${user.firstName} ${user.lastName}`
              : name
          }
          className={classes.img}
        />
        <CardContent>
          <Grid container spacing={1} >

            <Grid item xs={12} >
              <Typography gutterBottom variant="h4" component="h4">
                {name}
              </Typography>
            </Grid>

            <Grid item xs={12} >
              <Typography variant="body1" color="textSecondary" component="h5">
                {description && description !== "auto" ? description : getHighestStudies(user)}
              </Typography>
            </Grid>

            {email &&
              <Grid item xs={12} >
                <Grid container direction="row" justifyContent="center" alignItems="center" >
                  <MailIcon />
                  <Typography variant="body2" color="textSecondary" component="p">
                    {email}
                  </Typography>
                </Grid>
              </Grid>
            }

            {telephone &&
              <Grid item xs={12} >
                <Grid container direction="row" justifyContent="center" alignItems="center" >
                  <PhoneIcon />
                  <Typography variant="body2" color="textSecondary" component="p">
                    {telephone}
                  </Typography>
                </Grid>
              </Grid>
            }

            {address !== "" &&

              <Grid item xs={12} >
                <Grid container direction="row" justifyContent="center" alignItems="center" >
                  <HomeIcon />
                  <Typography variant="body2" color="textSecondary" component="p">
                    {address}
                  </Typography>
                </Grid>
              </Grid>
            }

            {displayEducation &&
              <DisplayEducation education={[degrees, masters, specialties, phds]} />
            }


          </Grid>
        </CardContent>
      </CardActionArea>

      {showApprove &&
        <ShowApproval />
      }

    </Card>
  );
}

