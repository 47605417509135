import {FETCH_USER_SUCCESS, RESET_USER} from "../constants/actionTypes";

const userReducer = (user = null, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return action.payload;

    case RESET_USER:
      return null;

    default:
      return user;

  }
}

export default userReducer;
