import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Avatar,
  Grid,
  Tooltip,
  Paper,
  Typography,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import {redirectScroll} from "../../helpers/misc";

import {resetPassword} from "../../actions/auth";

import useStyles from "./styles";


////////////////////////////////////////////////////////////////////////////

export default function ForgotPassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {passwordToken} = useParams();
  const [formData, setFormData] = useState({
    passwordToken: passwordToken,
    password: "",
    confirmPassword: "",
  });

  const isLoading = useSelector((state) => state.loading?.RESET_PASSWORD?.request || false);
  const isSuccess = useSelector((state) => state.loading?.RESET_PASSWORD?.success || false);
  const isFailure = useSelector((state) => state.loading?.RESET_PASSWORD?.failure || false);

  ////////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password && formData.confirmPassword) {
      dispatch(resetPassword(formData));
    }
  }

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (isSuccess) {

      redirectScroll(history)("/acceder");
    }
  }, [isSuccess]);

  ////////////////////////////////////////////////////////////////////////////

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5" gutterBottom>
          Restablecer Contraseña
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container direction="row" spacing={3} >

            <Grid item xs={12}>
              <Tooltip title="Nueva Contraseña" arrow >
                <Paper>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="password"
                    id="password"
                    type="password"
                    label="Nueva Contraseña"
                    onChange={handleChange}
                  />
                </Paper>
              </Tooltip>

              <Typography variant="body2" gutterBottom> </Typography>
              <Typography variant="body2" >
                <em>Al menos 8 caracteres</em>
              </Typography>

            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Escriba la misma contraseña que arriba" arrow >
                <Paper>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    label="Confirmar Contraseña"
                    onChange={handleChange}
                    inputProps={{
                      minLength: 8,
                      maxLength: 256,
                    }}
                  />
                </Paper>
              </Tooltip>

              {formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword &&
                <>
                  <Typography variant="body2" gutterBottom> </Typography>
                  <Typography variant="body2" color="error" >
                    <em>Contraseñas no concuerdan</em>
                  </Typography>
                </>
              }

            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={(!isLoading && !isSuccess && !isFailure) || isFailure
                  ? ""
                  : "disabled"}
              >
                {isLoading ? <CircularProgress /> : "Reestablecer contraseña"}
              </Button>
            </Grid>
          </Grid>

        </form>
      </div>

    </Container>
  );
}
