import {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {Grid, Paper, Divider, Typography, Chip, Tooltip, Button} from '@material-ui/core';
import clsx from 'clsx';
import moment from "moment";
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import Paypal from "../Paypal/Paypal";
import SmartText from "../Utilities/SmartText";
import EmailModal from "../Paypal/ModalEmail";
import {EVENT, COURSE, ASSEMBLY, BIRTHDAY} from "../../constants/events";
import {ADMIN_EMAIL, PAYPAL_ACTIVE} from "../../constants/misc";
import birthdayImage from "../../assets/images/birthday/birthday.jpg";
import {formatTimeDuration, toCurrency} from "../../helpers/misc";
import {enableEventForUser, disableEventForUser} from "../../actions/paypal";

import NO_AVATAR from "../../assets/images/no_avatar.png";

import useStyles from "./styles";

export default function IndividualEvent({event, autoScroll = true, abridged = false, showEnableButton = false, specificUser = null}) {
  const classes = useStyles();
  const [referrer, setReferrer] = useState("");
  const dispatch = useDispatch();
  const toggledEventPurchase = useSelector((state) => state.loading?.ENABLE_EVENT_USER?.success || state.loading?.DISABLE_EVENT_USER?.success || false);
  const [user, setUser] = useState(null);
  const purchasedEvent = user?.purchasedEvents?.includes(event?._id);

  const scrollToRef = useRef(null);

  const attendedSeconds = user?.attendedEvents.filter((ev) => ev.eventId.toString() === event?._id?.toString())[0]?.time;
  const attendedMinimumTime = attendedSeconds >= event?.minimumTime;
  const dateNow = moment();

  const [openEmailModal, setOpenEmailModal] = useState(null);
  const [email, setEmail] = useState(null);

  // effects //////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (specificUser) {
      setUser(specificUser);
    } else {
      setUser(JSON.parse(localStorage.getItem("profile"))?.data);
    }
  }, [specificUser]);

  useEffect(() => {
    if (toggledEventPurchase) {
      if (specificUser) {
        if (user?._id) {
          //update all users to update the purchased events of this particular user
          //dispatch(getUser(user._id)); // causes infinite render
        }
      } else {
        setUser(JSON.parse(localStorage.getItem("profile"))?.data);
      }
    }
  }, [toggledEventPurchase, specificUser]);

  useEffect(() => {
    if (autoScroll && scrollToRef.current && event) {
      scrollToRef.current.scrollIntoView({behavior: "smooth"});
    }
  }, [autoScroll, scrollToRef, event]);
  // handlers ////////////////////////////////////////////////////////////////
  const handleEnableEvent = () => {
    if (user?._id && event?._id) {
      dispatch(enableEventForUser(user._id, event._id, false));
    }
  };

  const handleDisableEvent = () => {
    if (user?._id && event?._id) {
      dispatch(disableEventForUser(user._id, event._id, false));
    }
  };

  const onClickViewMore = (event) => {
    setReferrer(`/eventos/calendario/${event.type}/${event._id}`);
  };
  // handlers ////////////////////////////////////////////////////////////////

  return (
    <>
      {referrer && <Redirect to={referrer} />}

      {event
        ? <Paper className={classes.height100} ref={scrollToRef} >
          <Grid container justifyContent="center" alignItems="flex-start" >
            <Grid item xs={12} >
              <Grid container direction="column" justifyContent="center" alignItems="center" >
                <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
                  {event.title}
                </Typography>

                <Divider />

                {(event.type === BIRTHDAY) &&
                  <Grid container alignItems="center" justifyContent="space-around" >
                    <Grid sm={12} md={6} >
                      <img className={classes.eventImage} src={birthdayImage} alt="felicitacion cumpleaños" />
                    </Grid>
                    <Grid sm={12} md={6} >
                      <img className={classes.eventImage} src={event.avatar ? event.avatar : NO_AVATAR} alt="avatar" />
                    </Grid>
                  </Grid>
                }
                {(event.image) &&
                  <img className={classes.eventImage} src={event.image} alt="evento" />
                }
              </Grid>

              <Divider />
            </Grid>

            <Grid item xs={12} >
              <Grid container justifyContent="center" alignItems="center" >
                <Grid item xs={3} >
                  <Typography gutterBottom variant="subtitle1" component="h2" align="center" className={clsx(classes.typography, classes.metadataName)} >
                    <strong>Fecha</strong>
                  </Typography>
                </Grid>

                <Grid item xs={9} >
                  <Typography gutterBottom variant="h4" component="h2" align="center" className={clsx(classes.typography, classes.metadataContent)} >
                    {moment(event.date).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-around" alignItems="flex-start" >
                <Grid item xs={3} >
                  <Typography gutterBottom variant="subtitle1" component="h2" align="center" className={clsx(classes.typography, classes.metadataName)} >
                    <strong>Tipo de evento</strong>
                  </Typography>
                </Grid>

                <Grid item xs={9} >
                  <Chip label={event.extraordinary ? `${event.type} EXTRAORDINARIA` : event.type} className={clsx(classes.typography, classes.metadataContent, classes[event.type])} />
                </Grid>
              </Grid>

              {
                !abridged &&
                user &&
                event.attendable &&
                event.minimumTime &&
                [EVENT, COURSE, ASSEMBLY].includes(event.type) &&
                dateNow >= moment(event.date) &&
                < Grid container justifyContent="space-around" alignItems="flex-start" >
                  <Grid item xs={3} >
                    <Typography gutterBottom variant="subtitle1" component="h2" align="center" className={clsx(classes.typography, classes.metadataName)} >
                      <strong>Tiempo asistido: </strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={9} >
                    <Tooltip title={`Si considera que ha asistido un tiempo diferente al marcado, puede contactar al administrador al correo ${ADMIN_EMAIL}`} placement="left-start" arrow>
                      <Typography gutterBottom variant="h5" component="h5" align="center" className={clsx(classes.typography, classes.metadataContent)} >
                        <span style={{borderBottom: "1px dotted"}}>
                          <Grid container alignItems="center" spacing={1} >
                            <Grid item >
                              {attendedMinimumTime
                                ? (<CheckCircleOutlineIcon style={{marginTop: "6px", color: "#4caf50"}} />)
                                : (<ErrorOutlineOutlinedIcon style={{marginTop: "6px", color: "#f44336"}} />)
                              }

                            </Grid>
                            <Grid item >
                              {formatTimeDuration(attendedSeconds)} de {formatTimeDuration(event.minimumTime)}
                            </Grid>
                          </Grid>
                        </span>
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              }

              {!abridged &&
                <Grid container justifyContent="space-around" alignItems="flex-start" >
                  <Grid item xs={3} >
                    <Typography gutterBottom variant="subtitle1" component="h2" align="center" className={clsx(classes.typography, classes.metadataName)} >
                      <strong>Descripción</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={9} >
                    <SmartText text={event.body} showLess={false} onClick={() => onClickViewMore(event)} />
                  </Grid>
                </Grid>
              }


              {parseInt(event.price) > 0 && !abridged && !purchasedEvent &&
                <Grid container justifyContent="space-around" alignItems="flex-start" >
                  <Grid item xs={3} >
                    <Typography gutterBottom variant="subtitle1" component="h2" align="center" className={clsx(classes.typography, classes.metadataName)} >
                      <strong>Costo</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={9} >
                    <Typography gutterBottom variant="h4" component="h2" align="center" className={clsx(classes.typography, classes.metadataContent)} >
                      {event.price ? `MXN ${toCurrency(event.price)}` : "Gratuito"}
                    </Typography>

                  </Grid>
                </Grid>
              }

              {!abridged && purchasedEvent &&
                <Grid container justifyContent="space-around" alignItems="flex-start" >
                  <Grid item xs={3} >
                    <Typography gutterBottom variant="subtitle1" component="h2" align="center" className={clsx(classes.typography, classes.metadataName)} >
                      <strong>Contenido de pago</strong>
                    </Typography>
                  </Grid>


                  <Grid item xs={9} >
                    <SmartText text={event.paywallContentDescription} showLess={abridged} onClick={() => onClickViewMore(event)} />
                  </Grid>

                </Grid>
              }

              {showEnableButton
                ? (
                  <Grid container justifyContent="space-around" alignItems="flex-start" >
                    <Grid item xs={3} >
                    </Grid>

                    <Grid item xs={9} >
                      <Button
                        variant="contained"
                        color={purchasedEvent ? "secondary" : "primary"}
                        onClick={purchasedEvent ? handleDisableEvent : handleEnableEvent}
                        style={{marginBottom: "14px"}}
                      >
                        {purchasedEvent ? "Deshabilitar Contenido" : "Habilitar Contenido"}
                      </Button>
                    </Grid>
                  </Grid>
                )
                : (parseInt(event.price) > 0 && !abridged &&
                  <Grid container justifyContent="space-around" alignItems="flex-start" >
                    <Grid item xs={3} >
                    </Grid>

                    <Grid item xs={9} >
                      <Grid container >
                        <Grid item xs={6} >
                          {PAYPAL_ACTIVE
                            ? (user || email)
                              ? <Paypal
                                productId={event._id}
                                amount={event.price}
                                type={event.type}
                                itemName={event.title}
                                user={user}
                                email={email}
                              />
                              : <>
                                <Button variant="contained" color="primary" onClick={() => setOpenEmailModal(true)} style={{marginBottom: "20px"}}>
                                  Comprar
                                </Button>
                                <EmailModal open={openEmailModal} setOpen={setOpenEmailModal} email={email} setEmail={setEmail} />
                              </>
                            :
                            <Paypal
                              productId={event._id}
                              amount={event.price}
                              type={event.type}
                              itemName={event.title}
                              user={null}
                              email={null}
                            />
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }

              <Divider />
            </Grid>

            {abridged &&
              <Grid item xs={12} >
                <SmartText text={event.body} showLess={abridged} onClick={() => onClickViewMore(event)} />
              </Grid>
            }

          </Grid>
        </Paper>
        : <Paper className={classes.fullHeight} ref={scrollToRef} >
          <Grid container justifyContent="center" alignItems="center" >
            <Grid item xs={12} >
              <Typography gutterBottom variant="h5" component="h5" align="center" className={classes.typography} >
                SELECCIONE UN EVENTO
              </Typography>

            </Grid>
          </Grid>
        </Paper>

      }
    </>
  );
}
