import {
  Grid,
  Typography,
} from '@material-ui/core';
import SchoolIcon from '@material-ui/icons/School';

const SingleEducation = ({education, name}) => {

  return (
    <>
      {education?.length > 0 &&
        <Grid item xs={12} >
          <Grid container direction="row" justifyContent="center" alignItems="center" >
            <Grid container direction="row" justifyContent="center" alignItems="center" >
              <SchoolIcon />
              <Typography variant="body1" color="textSecondary" component="p">
                <strong>{name}</strong>
              </Typography>
            </Grid>

            <ul>
              {education.map((e, i) => (
                <li key={i} style={{marginBottom: "1em"}}>
                  <Typography variant="body1" color="textSecondary" component="p">
                    <strong>Título:</strong> {e.name}
                  </Typography>

                  <Typography variant="body1" color="textSecondary" component="p">
                    <strong>Universidad:</strong> {e.university}
                  </Typography>
                  <Typography variant="body1" color="textSecondary" component="p">
                    <strong>Núm. Cédula:</strong> {e.licenseNumber}
                  </Typography>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      }
    </>
  );
}

const DisplayStudies = ({education}) => {
  const [degrees, masters, specialties, phds] = education;

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center" >
      <Typography variant="h5" component="p" gutterBottom >
        <strong>EDUCACIÓN</strong>
      </Typography>


      <SingleEducation education={degrees} name="Licenciaturas" />
      <SingleEducation education={masters} name="Maestrias" />
      <SingleEducation education={specialties} name="Especialidades" />
      <SingleEducation education={phds} name="Doctorados" />
    </Grid>
  );
}; export default DisplayStudies;
