import {makeStyles} from '@material-ui/core/styles';
import FeaturedEvents from './components/FeaturedEvents/FeaturedEvents';
import {
  Grid,
  Divider
} from '@material-ui/core';

import LandingCalendar from './components/Calendar/LandingCalendar';
import Council from './components/Council/Council';
import LandingDirectory from './components/Directory/LandingDirectory';
import MapView from './components/Map/MapView';
import ContactInformation from './components/ContactInformation/ContactInformation';

export default function App() {
  const useStyles = makeStyles((theme) => ({
    sectionSeparator: {
      marginTop: 20,
      marginBottom: 20,
    }
  }));

  const classes = useStyles();

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center" >

      <Grid item xs={0} sm={1} > </Grid>

      <Grid item xs={12} sm={10}>
        <main>
          <FeaturedEvents />

        </main>

        <Divider className={classes.sectionSeparator} />
      </Grid>

      <Grid item xs={0} sm={1}> </Grid>

      <Grid item xs={12} >
        <LandingCalendar />

        <Divider className={classes.sectionSeparator} />
      </Grid>

      <Grid item xs={12} >
        <Council />

        <Divider className={classes.sectionSeparator} />
      </Grid>

      <Grid item xs={12} >
        <LandingDirectory maxMembers={9} random={true} />

        <Divider className={classes.sectionSeparator} />
      </Grid>

      <Grid item xs={12} >
        <Grid container >
          <Grid item xs={12} md={6} >
            <ContactInformation />
          </Grid>

          <Divider className={classes.sectionSeparator} />

          <Grid item xs={12} md={6} >
            <MapView />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
}
