import {useState} from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

const ConfirmDialog = (props) => {
  const {
    title,
    children,
    open,
    setOpen,
    onConfirm,
    onClose = () => {},
    disableConfirmButtonTime = 0
  } = props;

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(Boolean(disableConfirmButtonTime));
  if (disableConfirmButtonTime) {
    setTimeout(() => setIsConfirmButtonDisabled(false), disableConfirmButtonTime);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        onClose();
      }}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent style={{overflowY: 'visible'}} dividers >
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color="default"
        >
          No
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="secondary"
          disabled={isConfirmButtonDisabled}
        >
          {isConfirmButtonDisabled
            ? <CircularProgress />
            : "Sí"
          }


        </Button>
      </DialogActions>
    </Dialog>
  );
}; export default ConfirmDialog;
