import {
  FETCH_SINGLE_EVENT,
  FETCH_SINGLE_ASSEMBLY,
  FETCH_SINGLE_COURSE,
  FETCH_BIRTHDAY,
  CLEAR_EVENT,
} from '../../constants/actionTypes';
import {BIRTHDAY} from "../../constants/events";

export const singleEventReducer = (state = {event: null, assembly: null, course: null}, action) => {
  switch (action.type) {
    case FETCH_SINGLE_EVENT:
      return {...state, event: action.payload};

    case FETCH_SINGLE_ASSEMBLY:
      return {...state, event: action.payload, assembly: action.payload};

    case FETCH_SINGLE_COURSE:
      return {...state, event: action.payload, course: action.payload};

    case FETCH_BIRTHDAY:
      const user = action.payload;
      const birthday = {
        title: `${user.firstName} ${user.lastName} cumpleaños hoy`,
        date: user.birthday,
        body: `Le deseamos muchas felicidades a ${user.firstName} ${user.lastName} por ser su cumpleaños`,
        type: BIRTHDAY,
        avatar: user.avatar,
      };
      return {...state, event: birthday, birthday};

    case CLEAR_EVENT:
      return {...state, event: null};

    default:
      return state;
  }
};


export default singleEventReducer;
