import Dashboard from "../Dashboard/Dashboard";
import {AdminListItems} from '../Dashboard/ListItems';

export default function AdminDashboard({Content, ...rest}) {

  return (
    <>
      <Dashboard Content={Content} ListItems={AdminListItems} {...rest} />
    </>
  );
}
