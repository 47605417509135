import {Typography} from '@material-ui/core';
import EventView from "../../Events/EventView";

import {EVENT} from "../../../constants/events";


import useStyles from "../../Dashboard/styles";

export default function AdminEvents({eventType = EVENT}) {
  const classes = useStyles();

  const autoScroll = false;

  return (
    <>
      <Typography align="center" variant="h4" gutterBottom className={classes.typography} >
        <strong style={{textTransform: "uppercase"}}>{eventType}S</strong>
      </Typography>

      <EventView renderEventTabs={false} eventType={eventType} autoScroll={autoScroll} showControlButtons={true} />
    </>
  );
}


