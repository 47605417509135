import {
  Typography,
  Grid,
  Button,
} from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';

import {CONTACT_EMAIL, CONTACT_PHONE, LOCATION} from "../../constants/misc";

import useStyles from "./styles";

export default function ContactUs() {
  const classes = useStyles();

  return (
    <>
      <Typography
        align="center"
        variant="h4"
        color="inherit"
        gutterBottom
        className={classes.typography}>
        <strong>INFORMACIÓN DE CONTACTO</strong>
      </Typography>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.contactGrid}>
        <Grid item xs={12} >
          <Typography variant="body1" component="p" gutterBottom >
            <EmailIcon /> {CONTACT_EMAIL}
          </Typography>
        </Grid>

        <Grid item xs={12} >
          <Typography variant="body1" component="p" gutterBottom>
            <PhoneIcon /> {CONTACT_PHONE}
          </Typography>
        </Grid>

        <Grid item xs={12} >
          <Typography variant="body1" component="p" gutterBottom>
            <MapIcon />
              {LOCATION}
          </Typography>
        </Grid>


        <Grid item xs={12} >
          <Button variant="contained" color="primary" href={"/servicios#servicios"} >
            Contactanos
          </Button>
        </Grid>

      </Grid>
    </>
  );
}

