import {useState} from "react";
import useStyles from './styles';

import {
  Typography,
  Button,
  Grid,
  Collapse,
} from '@material-ui/core';

import {
  ADMIN_EMAIL,
  CONTACT_PHONE,
  BANK,
  BANK_ACCOUNT,
  CLABE,
} from "../../constants/misc";

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MailIcon from '@material-ui/icons/Mail';

export default function AlternativePayment(props) {
  const classes = useStyles();
  const {title = "Alternativa de pago"} = props;

  const [collapse, setCollapse] = useState(true);
  const handleCollapse = () => {
    setCollapse(!collapse);
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleCollapse} style={{marginBottom: "14px"}}>
        {title}
      </Button>

      <Collapse in={!collapse}>
        <Grid container spacing={3} direction="column" alignItems="center" justifyContent="center" >

          <Grid item>
            <Typography variant="body1" color="textSecondary" component="h5" >
              Banco
            </Typography>
            <Typography variant="body1" component="h5" gutterBottom>
              <strong>{BANK}</strong>
            </Typography>

            <Typography variant="body1" color="textSecondary" component="h5" >
              Cuenta
            </Typography>
            <Typography variant="body1" component="h5" gutterBottom>
              <strong>{BANK_ACCOUNT}</strong>
            </Typography>

            <Typography variant="body1" color="textSecondary" component="h5" >
              Clabe
            </Typography>
            <Typography variant="body1" component="h5" gutterBottom>
              <strong>{CLABE}</strong>
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" component="h5" className={classes.justifyText} >
              Una vez haya realizado el deposito, puede enviar un correo a:
              <a href={`mailto:${ADMIN_EMAIL}`}>
                <Grid container direction="row" alignItems="center" >
                  <MailIcon /> {ADMIN_EMAIL}
                </Grid>
              </a>
              o mandar un mensaje de Whatsapp al telefono:
              <a href={`https://wa.me/+52${CONTACT_PHONE}`} >
                <Grid container direction="row" alignItems="center" >
                  <WhatsAppIcon /> {CONTACT_PHONE}
                </Grid>
              </a>

            </Typography>

            <Typography variant="body1" component="h5" gutterBottom className={classes.justifyText} >
              Indicando que realizo el pago y cual fue el contenido por el que pago,
              para que se le habilite en su cuenta o se le envie a su correo ese contenido.
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
}
