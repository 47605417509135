import {useState, useEffect} from 'react';
import {useSelector} from "react-redux";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import {
  filterList,
  filterUsersList,
  filterEventsList,
} from "../../helpers/misc";

import {EVENT} from "../../constants/events";

import clsx from "clsx";
import useStyles from "./styles";


export default function SearchFilter(props) {
  const classes = useStyles();
  const {
    list,
    setFilteredList,
    halfWidth,
    parameters = "title",
    placeholder = "Buscar",
    filter = "general",
    baseList = list,
    isSticky = false,
  } = props;

  const [query, setQuery] = useState('');
  const valuations = useSelector((state) => state.valuations); // Directory component dispatched this event

  const filterFunc = filter === "general"
    ? filterList
    : filter === EVENT
      ? filterEventsList
      : filterUsersList
    ;

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredList(
        query
          ? filterFunc(list, query, parameters, valuations)
          : baseList
      );
    }, 300);

    return () => clearTimeout(timer);
  }, [list, query, parameters, baseList]);

  return (
    <Grid container spacing={3} className={classes.root} direction="row" justifyContent="flex-end" alignItems="flex-end">
      <Grid item xs={12} md={halfWidth ? 6 : 12} >
        <Paper component="div" className={clsx(classes.child, isSticky ? classes.sticky : "")}>
          <InputBase
            className={classes.input}
            placeholder={placeholder}
            inputProps={{'aria-label': 'Buscar'}}
            value={query}
            onChange={event => setQuery(event.target.value)}
          />
          {query &&
            <IconButton
              type="button"
              className={classes.iconButton}
              aria-label="Cancelar"
              onClick={() => setQuery("")}
            >
              <ClearRoundedIcon
              />
            </IconButton>
          }

          <Divider className={classes.divider} orientation="vertical" />
          <IconButton type="submit" className={classes.iconButton} aria-label="Buscar">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Grid>
    </Grid>
  );
}

