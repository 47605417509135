import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Grid,
  Tooltip,
} from '@material-ui/core/';
import moment from "moment";
import clsx from "clsx";
import ReactPaginate from 'react-paginate';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmDialog from "../Utilities/ConfirmDialog";

import NO_AVATAR from "../../assets/images/no_avatar.png";

import {getCouncilsAndMembers, deleteCouncil} from "../../actions/councils";
import {redirectScroll} from "../../helpers/misc";

import useStyles from "./styles";


export default function CouncilsList(props) {
  const classes = useStyles();
  const {showControlButtons = false} = props;

  const councils = useSelector((state) => state.councils);
  const [councilsList, setCouncilsList] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 8;
  const pagesVisited = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(councilsList.length / itemsPerPage);

  const [clickId, setClickId] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  //functions ////////////////////////////////////////////////////////////////
  const confirmDelete = (council) => {
    setClickId(council._id);
    setShowDeleteDialog(true);
  }

  const handleDelete = () => {
    dispatch(deleteCouncil(clickId));
  };

  function changePage({selected}) {
    setPageNumber(selected);
  }

  //functions ////////////////////////////////////////////////////////////////

  //effects //////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getCouncilsAndMembers());
  }, []);

  useEffect(() => {
    setCouncilsList(councils);
  }, [councils]);

  //effects //////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////

  return (
    <>

      {showControlButtons &&
        <Button
          color="primary"
          variant="contained"
          onClick={() => redirectScroll(history)("/administrador/consejos/crear")}
        >
          Nuevo Consejo
        </Button>

      }


      <List className={classes.root} >

        {councilsList
          .slice(pagesVisited, pagesVisited + itemsPerPage)
          .map((council, i) => (
            <div key={council._id} >

              <ListItem
                alignItems="flex-start"
                component={Button}
                onClick={() => {
                  showControlButtons
                    ? redirectScroll(history)(`/administrador/consejos/${council._id}`)
                    : redirectScroll(history)(`/consejos/${council._id}`)

                }}>
                <Grid container >
                  <Grid container >
                    <ListItemAvatar>
                      <Avatar
                        alt="Consejo"
                        className={classes.council}
                      >
                        {councilsList.length - i - pagesVisited}
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={"Consejo Directivo"}
                      secondary={`${moment(council.startDate).format("YYYY")}-${moment(council.endDate).format("YYYY")}`}
                    >
                    </ListItemText>
                  </Grid>

                  <Grid container spacing={3} justifyContent="flex-start">
                    {council?.membersContent?.map((cm, j) => (
                      <Grid item xs={12} sm={6} md={3} lg={2} key={j} >
                        <Grid item >
                          <Grid container justifyContent="center">
                            <img
                              src={cm.avatar ? cm.avatar : NO_AVATAR}
                              className={classes.img}
                              alt={cm.fullName}
                              style={{alignItems: "center"}}
                            />
                          </Grid>
                        </Grid>

                        <Grid item >
                          <Typography variant="body1" style={{textAlign: "center", textTransform: "capitalize"}} >
                            {cm.fullName}
                          </Typography >
                        </Grid>

                        <Grid item >
                          <Typography variant="body1" color="textSecondary" style={{textAlign: "center", textTransform: "capitalize"}} >
                            {cm.boardTitle}
                          </Typography >
                        </Grid>
                      </Grid>
                    ))}

                  </Grid>

                  {showControlButtons &&
                    <ListItemSecondaryAction>

                      <Tooltip title="Editar" arrow>
                        <IconButton edge="end" aria-label="edit" onClick={() => redirectScroll(history)(`/administrador/consejos/editar/${council._id}`)} >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Borrar" arrow>
                        <IconButton edge="end" aria-label="delete" onClick={() => confirmDelete(council)} >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  }
                </Grid>
              </ListItem>

              <Divider variant="inset" component="li" />
            </div>
          ))}

      </List>


      {
        councilsList.length !== 0
          ? (<ReactPaginate
            previousLabel={"Anterior"}
            nextLabel={"Siguiente"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={classes.paginationBttns}
            previousLinkClassName={clsx(classes.previousBttn, "MuiPaper-root", "MuiPaper-elevation1")}
            nextLinkClassName={clsx(classes.nextBttn, "MuiPaper-root", "MuiPaper-elevation1")}
            disabledClassName={classes.paginationDisabled}
            activeClassName={classes.paginationActive}
          />)
          : (<Box textAlign="center"><Typography>
            {`NO SE ENCONTRARON RESULTADOS`}
          </Typography></Box>)
      }

      {showDeleteDialog &&
        (<ConfirmDialog
          title="¿Esta seguro que desea eliminar el consejo?"
          onConfirm={handleDelete}
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
          onClose={() => setClickId(null)}
        >
          <Grid container spacing={3} direction="column" justifyContent="center" >
            <Grid item >
              Esta intentando eliminar un consejo.
            </Grid>

            <Grid item >
              Si elimina el consejo, ya no podra ver sus miembros ni accesar su información.
            </Grid>


            <Grid item >
              Esta accion no podra deshacerse, <strong>¿esta seguro?</strong>
            </Grid>
          </Grid>
        </ConfirmDialog>)
      }
    </>
  );
}

