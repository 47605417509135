import img1 from "../../assets/images/random/random1.jpeg";
import img2 from "../../assets/images/random/random2.jpeg";
import img3 from "../../assets/images/random/random3.jpeg";
import img4 from "../../assets/images/random/random4.jpeg";
import img5 from "../../assets/images/random/random5.jpeg";
import img6 from "../../assets/images/random/random6.jpeg";
import img7 from "../../assets/images/random/random7.jpeg";
import img8 from "../../assets/images/random/random8.jpeg";
import img9 from "../../assets/images/random/random9.jpeg";
import img10 from "../../assets/images/random/random10.jpeg";
import img11 from "../../assets/images/random/random11.jpeg";

import text2 from "../../assets/texts/avaluo1";

const valuations = [
  {
    title: "Avaluo 1",
    image: img1,
    url: "avaluo1",
    text: "Este es un avaluo muy interesante\n"
      + "Segunda linea"
  },
  {
    title: "Avaluo 2",
    image: img2,
    url: "avaluo2",
    text: text2,
  },
  {
    title: "Avaluo 3",
    image: img3,
    url: "",
  },
  {
    title: "Avaluo 4",
    image: img4,
    url: "",
  },
  {
    title: "Avaluo 5",
    image: img5,
    url: "",
  },
  {
    title: "Avaluo 6",
    image: img6,
    url: "",
  },
  {
    title: "Avaluo 7",
    image: img7,
    url: "",
  },
  {
    title: "Avaluo 8",
    image: img8,
    url: "",
  },
  {
    title: "Avaluo 9",
    image: img9,
    url: "",
  },
];

export default valuations;
