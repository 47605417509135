import {
  Grid,
} from '@material-ui/core';

import CouncilsList from "./CouncilsList";
import PresidentsGallery from "./PresidentsGallery";

import clsx from "clsx";
import useStyles from "./styles";

export default function EventView(props) {
  const classes = useStyles();
  const {showControlButtons} = props;

  // effects /////////////////////////////////////////////////////////////////

  // effects /////////////////////////////////////////////////////////////////

  // on click ////////////////////////////////////////////////////////////////
  // on click ////////////////////////////////////////////////////////////////

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
        className={classes.root}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} className={clsx(classes.section)} >
          <PresidentsGallery />
        </Grid>

        <Grid item xs={12} className={classes.section} >
          <CouncilsList showControlButtons={showControlButtons} />
        </Grid>

      </Grid>

    </>
  );
}
