import {
  FETCH_ALL_COUNCILS_SUCCESS,
  FETCH_ALL_COUNCILS_AND_MEMBERS_SUCCESS,
} from "../../constants/actionTypes";

const councilsReducer = (councils = [], action) => {
  switch (action.type) {
    case FETCH_ALL_COUNCILS_SUCCESS:
    case FETCH_ALL_COUNCILS_AND_MEMBERS_SUCCESS:
      return action.payload;

    default:
      return councils;

  }
}

export default councilsReducer;
