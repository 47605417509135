import {FETCH_SINGLE_TRANSACTION_SUCCESS} from "../../constants/actionTypes";

const transactionReducer = (transaction = null, action) => {
  switch (action.type) {
    case FETCH_SINGLE_TRANSACTION_SUCCESS:
      return action.payload;

    default:
      return transaction;

  }
}

export default transactionReducer;
