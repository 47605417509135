import {ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR} from "../constants/actionTypes";
import {v4 as uuidv4} from 'uuid';
import {Button} from '@material-ui/core';


export const enqueueSnackbarAction = (notification) => {
  const key = notification?.options?.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || uuidv4(),
    },
  };
};


export const closeSnackbarAction = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});


export const snackbar = (message = "", variant = "warning") => (dispatch) => {
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));

  return enqueueSnackbar({
    message: message,
    options: {
      key: uuidv4(),
      variant: variant,
      action: key => (
        <Button onClick={() => closeSnackbar(key)}>Ok</Button>
      ),
      style: {whiteSpace: 'pre-line'}
    },
  });
}
