import useStyles from './styles';

import {
  Grid,
  Button,
  Link,
} from '@material-ui/core';

import sections from "./sections";

export default function GridNavbar(props) {
  const classes = useStyles();
  const {user} = props;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" >
      {sections.map((section) => (
        !section.condition || section.condition(user?.data) ?
          <Grid item key={section.title} >
            <Button >
              <Link
                color="inherit"
                variant="h5"
                href={section.url}
                className={classes.toolbarLink}
              >
                <strong>{section.title}</strong>
              </Link>
            </Button>
          </Grid>
          : null
      ))}
    </Grid>
  );
}
