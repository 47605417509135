import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  Typography,
  Grid,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfiniteScroll from "react-infinite-scroll-component";
// basic tutorial for react paginate: (https://www.youtube.com/watch?v=HANSMtDy508)

import UserCard from '../UserCard';
import SearchFilter from '../SearchFilter/SearchFilter';

import NO_AVATAR from "../../assets/images/no_avatar.png";

import {getUsers, getDisabledUsers} from "../../actions/users";
import {getValuations} from "../../actions/valuations";

import useStyles from "./styles";

export default function Directory({showApprove = false, disabledUsers = false, includeHidden = false}) {
  console.log("hidden status: ", includeHidden);
  const classes = useStyles();
  const dispatch = useDispatch();

  const allMembers = useSelector((state) => state.users);
  const [fetchedMembers, setFetchedMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const maxFetch = 9;

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (disabledUsers) {
      dispatch(getDisabledUsers(includeHidden));
    } else {
      dispatch(getUsers(includeHidden));
    }

    dispatch(getValuations());
  }, [dispatch]);

  useEffect(() => {
    setFetchedMembers(allMembers.slice(0, maxFetch));
    setFilteredMembers(allMembers.slice(0, maxFetch));
  }, [allMembers]);

  useEffect(() => {
    setFilteredMembers(fetchedMembers);
  }, [fetchedMembers]);

  ////////////////////////////////////////////////////////////////////////////

  const fetchMoreData = () => {
    const currentNumber = fetchedMembers.length;
    const list = fetchedMembers.slice();

    if (currentNumber >= allMembers.length) {
      setHasMore(false);
      return;
    }

    setTimeout(() => {
      setFetchedMembers(list.concat(allMembers.slice(currentNumber, currentNumber + maxFetch)));
    }, 500);
  };

  ////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Grid container spacing={3} className={classes.root} direction="row" justifyContent="flex-end" alignItems="flex-end">
        <Grid item xs={12} >
          <Typography
            component="h4"
            variant="h4"
            color="inherit"
            align="center"
            gutterBottom
            className={classes.sectionTitle}
          >
            <strong>DIRECTORIO DE MIEMBROS</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} className={classes.sticky} >
          <SearchFilter
            list={allMembers}
            baseList={fetchedMembers}
            filter="user"
            setFilteredList={setFilteredMembers}
          />
        </Grid>
        <Grid item xs={12} >
          <Grid container spacing={1} className={classes.root} justifyContent="center" >
            <InfiniteScroll
              dataLength={filteredMembers.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<h4>Cargando...</h4>}
              endMessage={
                <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.endMessage} >
                  <CheckCircleOutlineIcon />
                  <Typography style={{textAlign: "center"}}>
                    <strong>Ya no hay mas miembros</strong>
                  </Typography>
                </Grid>
              }
              style={{textAlign: "center", overflow: "hidden"}}
            >
              <Grid container spacing={1} className={classes.root} justifyContent="center" >
                {filteredMembers.map((member, i) => (
                  < Grid item xs={12} md={6} lg={4} key={i} >
                    <UserCard
                      name={`${member.firstName} ${member.lastName}`}
                      userId={member._id}
                      description={member.title}
                      memberType={member.memberType}
                      email={member.email}
                      telephone={member.telephone}
                      img={member.avatar ? member.avatar : NO_AVATAR}
                      waiting_approval={member.waiting_approval}
                      showApprove={showApprove}
                      disabledUsers={disabledUsers}
                      user={member}
                    />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </Grid>
        </Grid>

      </Grid>

    </>
  );
}
