import {ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR} from '../constants/actionTypes';

const notificationReducer = (notifications = [], action) => {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return [
        ...notifications,
        {
          key: action.notification?.key,
          ...action.notification,
        },
      ];

    case CLOSE_SNACKBAR:
      return notifications.map(notification => (
        (action.dismissAll || notification.key === action.key)
          ? {...notification, dismissed: true}
          : {...notification}
      ));

    case REMOVE_SNACKBAR:
      return notifications.filter(
        notification => notification.key !== action.key,
      );

    default:
      return notifications;
  }
};

export default notificationReducer;
