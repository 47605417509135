import {
  FETCH_ALL_EVENTS,
  FETCH_SINGLE_EVENT,
  FETCH_SINGLE_ASSEMBLY,
  FETCH_SINGLE_COURSE,
  FETCH_PUBLISHED_EVENTS,
  FETCH_FEATURED_EVENTS,
  FETCH_FEATURED_EVENTS_AND_BIRTHDAYS,
  CLEAR_EVENT,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  EDIT_EVENT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  DELETE_EVENT,
  UPLOAD_IMAGE,
  FETCH_PURCHASED_EVENTS_REQUEST,
  FETCH_PURCHASED_EVENTS_SUCCESS,
  FETCH_PURCHASED_EVENTS_FAILURE,
  FETCH_ATTENDED_EVENTS_REQUEST,
  FETCH_ATTENDED_EVENTS_SUCCESS,
  FETCH_ATTENDED_EVENTS_FAILURE,
  FETCH_PURCHASABLE_EVENTS_REQUEST,
  FETCH_PURCHASABLE_EVENTS_SUCCESS,
  FETCH_PURCHASABLE_EVENTS_FAILURE,
} from '../constants/actionTypes';
import {ASSEMBLY, COURSE} from "../constants/events";
import * as api from '../api/index.jsx';
import {snackbar} from "../actions/notifications";

export const getEvents = (startDate, endDate, title) => async (dispatch) => {
  try {
    const {data} = await api.fetchEvents(startDate, endDate, title);

    dispatch({type: FETCH_ALL_EVENTS, payload: data});
  } catch (error) {
    console.log(error);
  }
};

export const getAllEvents = getEvents;

export const getEvent = (id) => async (dispatch) => {
  try {
    const {data} = await api.fetchEvent(id);

    let TypeAction = "";
    switch (data.type) {
      case ASSEMBLY:
        TypeAction = FETCH_SINGLE_ASSEMBLY;
        break;

      case COURSE:
        TypeAction = FETCH_SINGLE_COURSE;
        break;

      default:
        TypeAction = FETCH_SINGLE_EVENT;
        break;
    }

    dispatch({type: TypeAction, payload: data});
  } catch (error) {
    console.log(error);
  }
};

export const createEvent = (event) => async (dispatch) => {
  try {
    dispatch({type: CREATE_EVENT_REQUEST});

    const {data} = await api.createEvent(event);
    dispatch({type: CREATE_EVENT_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: CREATE_EVENT_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const editEvent = (event, id) => async (dispatch) => {
  try {
    dispatch({type: EDIT_EVENT_REQUEST});

    const {data} = await api.editEvent(event, id);
    dispatch({type: EDIT_EVENT_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: EDIT_EVENT_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }



};

export const deleteEvent = (id) => async (dispatch) => {
  try {
    const res = await api.deleteEvent(id);
    console.log("Response of deletion: ", res);

    dispatch({type: DELETE_EVENT, payload: id});
  } catch (error) {
    console.log(error);
  }
};

export const getPublishedEvents = () => async (dispatch) => {
  try {
    const {data} = await api.fetchPublishedEvents();

    dispatch({type: FETCH_PUBLISHED_EVENTS, payload: data});
  } catch (error) {
    console.log(error);
  }
};

export const getFeaturedEvents = () => async (dispatch) => {
  try {
    const {data} = await api.fetchFeaturedEvents();

    dispatch({type: FETCH_FEATURED_EVENTS, payload: data});
  } catch (error) {
    console.log(error);
  }
};

export const getFeaturedEventsAndBirthdays = () => async (dispatch) => {
  try {
    const {data} = await api.fetchFeaturedEventsAndBirthdays();

    dispatch({type: FETCH_FEATURED_EVENTS_AND_BIRTHDAYS, payload: data.data});
  } catch (error) {
    console.log(error);
  }
};

export const uploadImage = (imageForm) => async (dispatch) => {
  try {
    const {data} = await api.uploadImage(imageForm);

    dispatch({type: UPLOAD_IMAGE, payload: data});
  } catch (error) {
    console.log(error);
  }
};

export const clearEvent = () => async (dispatch) => {
  try {
    dispatch({type: CLEAR_EVENT, payload: null});
  } catch (error) {
    console.log(error);
  }
};

export const getPurchasedEvents = (id) => async (dispatch) => {
  try {
    dispatch({type: FETCH_PURCHASED_EVENTS_REQUEST});

    const {data} = await api.fetchPurchasedEvents(id);
    dispatch({type: FETCH_PURCHASED_EVENTS_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_PURCHASED_EVENTS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getPurchasableEvents = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_PURCHASABLE_EVENTS_REQUEST});

    const {data} = await api.fetchEvents(null, null, null, {purchasable: true});

    dispatch({type: FETCH_PURCHASABLE_EVENTS_SUCCESS, payload: data});

  } catch (error) {
    console.log("error: ", error);
    dispatch({type: FETCH_PURCHASABLE_EVENTS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getAttendedEvents = (id) => async (dispatch) => {
  try {
    dispatch({type: FETCH_ATTENDED_EVENTS_REQUEST});

    const {data} = await api.fetchAttendedEvents(id);
    dispatch({type: FETCH_ATTENDED_EVENTS_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_ATTENDED_EVENTS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

