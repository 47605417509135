import {useState, useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Tooltip,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import MyAccordion from '../../Utilities/MyAccordion';
import SearchFilter from '../../SearchFilter/SearchFilter';

import {getUsers, upsertAttendance, getPremiumUsers} from "../../../actions/users";
import {resetLoading, resetUsers} from "../../../actions/general";

import CircularProgress from '@material-ui/core/CircularProgress';

import {redirectScroll, getAttendedTime, secondsToTime} from "../../../helpers/misc";

import {ALL_USERS, PREMIUM_USERS} from "../../../constants/misc";

import useStyles from "../styles";

export default function MarkAttendance() {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const event = location.state?.event;
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [usersList, setUsersList] = useState(users);
  const isLoading = useSelector((state) => state.loading?.MARK_ATTENDANCE?.request || state.loading?.UPDATE_EVENT?.request || false);
  const isSuccess = useSelector((state) => state.loading?.MARK_ATTENDANCE?.success || state.loading?.UPDATE_EVENT?.success || false);
  const [formData, setFormData] = useState({});
  const [minimumTime, setMinimumTime] = useState(event?.minimumTime);
  const [usersFilter, setUsersFilter] = useState(ALL_USERS); // can be ALL_USERS or PREMIUM_USERS

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    const attendance = {attendance: _.values(formData), minimumTime: minimumTime};
    dispatch(upsertAttendance(attendance, event._id));
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: {userId: e.target.name, time: e.target.value}});
  };

  const handleChangeUsersFilter = (e) => {
    setUsersFilter(e.target.value)
  };

  const handleChangeMinimumTime = (e) => {
    setMinimumTime(e.target.value)
  };
  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (usersFilter === ALL_USERS) {
      dispatch(getUsers());
    } else if (usersFilter === PREMIUM_USERS) {
      dispatch(getPremiumUsers(event._id));
    }
  }, [dispatch, usersFilter]);

  useEffect(() => {
    setFormData(users.reduce((total, user) => {
      return {
        ...total,
        [user._id]: {
          userId: user._id,
          time: getAttendedTime(user.attendedEvents, event._id)
        }
      }
    }, {}));
  }, [users]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      dispatch(resetUsers());
      redirectScroll(history)(`/administrador/${event?.type}s/lista`);
    }
  }, [isSuccess]);

  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container direction="row" spacing={3} justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Typography gutterBottom variant="h3" component="h3" align="center" className={classes.typography} gutterBottom>
            Asistencia a <span style={{textTransform: "lowercase"}}>{event?.type}</span> "{event?.title}"
          </Typography>


          <Typography gutterBottom variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
            Ingrese el numero de horas, minutos y/o segundos que cada miembro asistio a este evento.
          </Typography>

          <Typography variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
            Porfavor, escriba la duracion en el formato <em>XXhYYmZZs</em>
          </Typography>

          <MyAccordion heading="Ejemplos">
            <Typography variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
              Si escribe <em>10h08m20s</em>, signfica que el miembro asistio al evento durante 10 horas, 8 minutos y 20 segundos.
            </Typography>

            <Typography variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
              Si escribe <em>2h08m</em>, signfica que el miembro asistio al evento durante 2 horas, 8 minutos y 0 segundos.
            </Typography>

            <Typography variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
              Si escribe <em>10m</em>, signfica que el miembro asistio al evento durante 0 horas, 10 minutos y 0 segundos.
            </Typography>

            <Typography variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
              Si escribe <em>0</em>, signfica que el miembro asistio al evento durante 0 horas, 0 minutos y 0 segundos.
            </Typography>

            <Typography variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
              Si el campo esta vacio, se entendera que el miembro no participo en el evento.
            </Typography>
          </MyAccordion>
        </Grid>

        <Grid item xs={12} >
          <Typography variant="h4" component="h4" align="justify" className={classes.typography} gutterBottom >
            Tiempo mínimo para considerar asistencia
          </Typography>

          <Typography variant="body1" component="h5" align="justify" className={classes.typography} gutterBottom >
            Si el miembro asistió menos tiempo al marcado a continuación, no se considerará como asistencia.
          </Typography>

          <Tooltip title="Si el miembro asistio menos tiempo, no se considerará como asistencia" arrow >
            <Paper>
              <TextField
                fullWidth
                name="minimumTime"
                variant="outlined"
                label="Tiempo Mínimo Asistencia"
                onChange={handleChangeMinimumTime}
                defaultValue={secondsToTime(event?.minimumTime)}
              />
            </Paper>
          </Tooltip>

        </Grid>

        <Grid item xs={12} >
          <form className={classes.form} noValidate onSubmit={handleSubmit} >
            <Grid direction="row" container spacing={3} justifyContent="center" alignItems="center" >

              <Grid item xs={12} >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Filtrar Usuarios</FormLabel>
                  <RadioGroup row aria-label="usersFilter" name="usersFilter" value={usersFilter} onChange={handleChangeUsersFilter}>
                    <FormControlLabel value={ALL_USERS} control={<Radio />} label="Todos" />
                    <FormControlLabel value={PREMIUM_USERS} control={<Radio />} label="Solo Evento Comprado" disabled={!event?.purchasable} />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} style={{padding: "0"}}>
                <SearchFilter
                  list={users}
                  setFilteredList={setUsersList}
                  filter="users"
                  placeholder="Buscar Miembro"
                />
              </Grid>

              <List className={classes.root} >
                {usersList.map((user) => (
                  <div key={user._id} >
                    <Grid item xs={12} >
                      <ListItem alignItems="flex-start" >
                        <ListItemAvatar>
                          <Avatar alt={`${user.firstName} ${user.lastName}`} src={user.avatar} />
                        </ListItemAvatar>

                        <ListItemText primary={`${user?.firstName} ${user?.lastName}`} secondary={user?.title} />
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} >
                      <Tooltip title="Tiempo de asistencia al evento (puede ser vacio)" arrow >
                        <Paper>
                          <TextField
                            fullWidth
                            name={user._id}
                            variant="outlined"
                            label="Tiempo Asistencia"
                            onChange={handleChange}
                            defaultValue={secondsToTime(getAttendedTime(user.attendedEvents, event._id))}
                          />
                        </Paper>
                      </Tooltip>
                    </Grid>

                  </div>)
                )}
              </List>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              {isLoading
                ? (<>
                  <CircularProgress />
                </>)
                : "Guardar Asistencias"
              }
            </Button>
          </form >
        </Grid>
      </Grid >
    </>
  );
}
