import {FETCH_SINGLE_DISABLED_USER_SUCCESS, RESET_USER} from "../constants/actionTypes";

const disabledUserReducer = (disabledUser = null, action) => {
  switch (action.type) {
    case FETCH_SINGLE_DISABLED_USER_SUCCESS:
      return action.payload;

    case RESET_USER:
      return null;

    default:
      return disabledUser;

  }
}

export default disabledUserReducer;
