import {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  // FormControl,
  // Select,
  // MenuItem,
  // FormHelperText,
} from '@material-ui/core';
import services from "../Services/ServicesList";

import {requestService} from "../../actions/general";

import useStyles from "./styles";

export default function ContactUs({selectedService, scrollToForm}) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const initialSelection = "Contactanos";

  const isLoading = useSelector((state) => state.loading?.SEND_EMAIL?.request || false);
  const isSuccess = useSelector((state) => state.loading?.SEND_EMAIL?.success || false);
  const isFailure = useSelector((state) => state.loading?.SEND_EMAIL?.failure || false);


  const selectOptions = [{title: initialSelection}].concat(services);

  const scrollToRef = useRef(null);
  const scrollToEvent = () => {
    scrollToRef.current.scrollIntoView({
      behavior: "smooth"
    });
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    service: "Contactanos",
    contentType: "text",
  });

  ////////////////////////////////////////////////////////////////////////////

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name && formData.email && formData.message && formData.service) {
      dispatch(requestService(formData));
    }
  }

  const handleChangeGeneral = (name, value) => {
    setFormData({...formData, [name]: value});
  };

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToEvent();
    }
  }, [selectedService]);

  ////////////////////////////////////////////////////////////////////////////

  return (
    <div ref={scrollToForm ? scrollToRef : null} >
      <Typography
        align="center"
        variant="h4"
        color="inherit"
        gutterBottom
        noWrap
        className={classes.typography}>
        <strong> CONTACTO </strong>
      </Typography>


      <form className={classes.root} id="servicios" noValidate onSubmit={handleSubmit} >
        <Grid container className={classes.gridContainer} spacing={3} alignItems="center" justifyContent="center" >

          {/* <Grid item xs={12}> */}
          {/*   <FormControl */}
          {/*     variant="outlined" */}
          {/*     fullWidth */}
          {/*   > */}
          {/*     <Paper> */}
          {/*       <Select */}
          {/*         value={formData.service} */}
          {/*         onChange={(e) => handleChangeGeneral("service", e.target.value)} */}
          {/*         labelId="selectService" */}
          {/*         id="serviceSelector" */}
          {/*         fullWidth */}
          {/*       > */}
          {/*         <MenuItem aria-label="None" value="" disabled /> */}
          {/*         {selectOptions.map((item) => ( */}
          {/*           <MenuItem key={item.title} value={item.title}>{item.title}</MenuItem> */}
          {/*         ))} */}
          {/*       </Select> */}
          {/*     </Paper> */}
          {/*     <FormHelperText>Opción</FormHelperText> */}
          {/*   </FormControl> */}
          {/* </Grid> */}


          <Grid item xs={12} md={6}>

            <Paper >
              <TextField
                className={classes.gridChild}
                id="contactName"
                label="Nombre"
                variant="outlined"
                value={formData.name}
                onChange={e => handleChangeGeneral("name", e.target.value)}
                required
                fullWidth
              />
            </Paper>

          </Grid>

          <Grid item xs={12} md={6}>

            <Paper >
              <TextField
                className={classes.gridChild}
                id="contactEmail"
                label="Correo"
                variant="outlined"
                value={formData.email}
                onChange={e => handleChangeGeneral("email", e.target.value)}
                autoComplete="email"
                type="email"
                required
                fullWidth
              />
            </Paper>


          </Grid>

          <Grid item xs={12} >

            <Paper >
              <TextField
                className={classes.gridChild}
                id="contactMessage"
                label="Mensaje"
                variant="outlined"
                minRows={12}
                maxRows={18}
                value={formData.message}
                onChange={e => handleChangeGeneral("message", e.target.value)}
                required
                multiline
                fullWidth
              />
            </Paper>

          </Grid>

          <Grid item xs={12} className={classes.submitButton} >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={(formData.name && formData.email && formData.message && formData.service) && ((!isLoading && !isSuccess && !isFailure) || (isFailure))
                ? ""
                : "disabled"}
            >
              Enviar
            </Button>
          </Grid>

        </Grid>
      </form>
    </div>
  );
}

