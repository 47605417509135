import {useState, useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Tooltip,
} from '@material-ui/core';
import moment from "moment";

// datepicker
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';

import {getCouncil, createCouncil, editCouncil} from "../../actions/councils";
import {resetLoading} from "../../actions/general";
import SubmitButton from "../Utilities/SubmitButton";
import {redirectScroll} from "../../helpers/misc";


import useStyles from "./styles";

export default function CreateCouncil(props) {
  const classes = useStyles();
  const {edit = false} = props;

  const location = useLocation();
  const history = useHistory();

  const council = useSelector((state) => state.council);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading?.CREATE_COUNCIL?.request || state.loading?.EDIT_COUNCIL?.request || false);
  const isSuccess = useSelector((state) => state.loading?.CREATE_COUNCIL?.success || state.loading?.EDIT_COUNCIL?.success || false);
  const councilId = location.pathname.split("/").pop();

  const initialState = {
    startDate: council?.startDate || "",
    endDate: council?.endDate || "",
  };
  const [updateForm, setUpdateForm] = useState(true);
  const [formData, setFormData] = useState({});

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!council) {
      dispatch(createCouncil(formData));
    } else {
      dispatch(editCouncil(councilId, formData));
    }
  };

  const handleChangeDate = (name) => (date) => {
    setFormData({...formData, [name]: moment(date).format("YYYY/MM/DD")});
  }
  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (edit) { // when editing, get the council
      dispatch(getCouncil(councilId));
    }
  }, [dispatch, councilId]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      redirectScroll(history)("/administrador/consejos");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (edit && council && updateForm) {
      setUpdateForm(false);
      setFormData(initialState);
    }
  }, [edit, council, updateForm, initialState]);
  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Grid container direction="column" justifyContent="center" alignItems="center" >
            <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
              {council
                ? "Editar Consejo"
                : "Crear Consejo"}
            </Typography>
          </Grid>
        </Grid>

        {(!edit || (edit && council)) &&
          <form className={classes.form} noValidate onSubmit={handleSubmit} >
            <Grid container spacing={3} >

              <Grid item xs={12} >
                <Tooltip title="Fecha de inicio del consejo. El dato más importa es el año" arrow >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      required
                      name="startDate"
                      id="startDate"
                      variant="outlined" //inline ?
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Fecha"
                      KeyboardButtonProps={{
                        'aria-label': 'Cambiar fecha',
                      }}
                      value={formData.startDate || null}
                      onChange={handleChangeDate("startDate")}
                    />

                  </MuiPickersUtilsProvider>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Fecha de fin del consejo. El dato más importa es el año" arrow >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      required
                      name="endDate"
                      id="endDate"
                      variant="outlined" //inline ?
                      format="dd/MM/yyyy"
                      margin="normal"
                      label="Fecha"
                      KeyboardButtonProps={{
                        'aria-label': 'Cambiar fecha',
                      }}
                      value={formData.endDate || null}
                      onChange={handleChangeDate("endDate")}
                    />

                  </MuiPickersUtilsProvider>
                </Tooltip>
              </Grid>


              <SubmitButton
                isLoading={isLoading}
                textCondition={council}
              />

            </Grid>
          </form>
        }
      </Grid>
    </>
  );
}
