import {FETCH_NUMBER_ATTENDED_EVENTS_SUCCESS} from '../../constants/actionTypes';

const attendancesReducer = (state = {attended: [], total: []}, action) => {

  switch (action.type) {
    case FETCH_NUMBER_ATTENDED_EVENTS_SUCCESS:
      return {
        ...state,
        attended: action.payload.attended,
        total: action.payload.total
      };

    default:
      return state;
  }
};

export default attendancesReducer;
