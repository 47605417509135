import {makeStyles} from "@material-ui/core/styles";
import {deepOrange, deepPurple, brown, teal, } from '@material-ui/core/colors';

import {EVENT, BIRTHDAY, ASSEMBLY, COURSE, } from "../../constants/events";

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  typography: {
    margin: 15,
    whiteSpace: "pre-line",
  },

  metadataName: {
    textAlign: "justify",
  },

  metadataContent: {
    textAlign: "justify",
  },

  section: {
    height: "100%",
  },

  // event main component
  sectionTitle: {
    padding: theme.spacing(1),
  },

  sectionSeparator: {
    marginTop: 20,
    marginBottom: 20,
  },

  height100: {
    height: "100%",
  },

  fullHeight: {
    height: "100%",

    "& > div": {
      height: "100%",
    },
  },

  // pagination style
  [EVENT]: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  [BIRTHDAY]: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  [ASSEMBLY]: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
  },
  [COURSE]: {
    color: theme.palette.getContrastText(teal[500]),
    backgroundColor: teal[500],
  },

  paginationBttns: {
    //width: "80%",
    //height: 40,
    listStyle: "none",
    display: "flex",
    justifyContent: "center",

    "& a": {
      padding: 10,
      margin: 8,
      borderRadius: 5,
      border: "#2b2eff solid 1px",
      color: "#2b2eff",
      cursor: "pointer",

      "&:hover": {
        color: "white",
        backgroundColor: "#2b2eff",
      }
    }
  },

  previousBttn: {

  },

  nextBttn: {

  },

  paginationDisabled: {

  },

  paginationActive: {
    "& a": {
      color: "white",
      backgroundColor: "#2b2eff",
    }
  },

  eventImage: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '60vh',
    marginBottom: "20px",
  },

  tabMenu: {
    margin: theme.spacing(3),
  },

  noAnchorStyle: {
    color: "inherit",
    textDecoration: "none",
  },

  sticky: {
    [theme.breakpoints.up('md')]: {
      position: "-webkit-sticky",
      position: "sticky",
      top: 0,
      //backgroundColor: "yellow",
      //padding: "50px",
    },
  },
}));

