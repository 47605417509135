import {
  Button,
  Avatar,
  Typography,
  Container,
  Paper,
} from "@material-ui/core";

import BallotIcon from '@material-ui/icons/Ballot';

import useStyles from "./styles";


export default function PreRegistration() {
  const classes = useStyles();


  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <BallotIcon />
        </Avatar>


        <Typography component="h1" variant="h5" gutterBottom >
          <strong>Registro</strong>
        </Typography>


        <Typography component="p" variant="h5" align="justify" gutterBottom >
          Para crear una cuenta, primero debe llenar el siguiente formulario.
        </Typography>

        <Typography component="p" variant="h5" align="justify" gutterBottom >
          Una vez llene el formulario, espere a que sea revisado y se le enviará
          un correo con instrucciones para proseguir con el registro de cuenta.
        </Typography>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          href={"https://forms.gle/zz4h5RzpJi1rDPEdA"}
        >
          <Typography component="p" variant="button" align="justify" gutterBottom >
            Formulario Inicial
          </Typography>
        </Button>


      </div>

    </Container >
  )
}
