export const ADMIN_EMAIL = 'contacto@comaviin.com';
export const CONTACT_EMAIL = ADMIN_EMAIL;

export const ADMIN_PHONE = "6623103519";
export const CONTACT_PHONE = ADMIN_PHONE;

export const BANK = "SANTANDER";
export const BANK_ACCOUNT = "655007672074";
export const CLABE = "0147 6065 5076 7207 49";

export const LOCATION = "Ave. Luis Robles Linares No. 21, Colonia la Verbena. Codigo Postal 83288. Hermosillo, Sonora";

export const ALL_USERS = "ALL_USERS";
export const PREMIUM_USERS = "PREMIUM_USERS";

export const ALL_ATTENDED = "ALL_ATTENDED";
export const MINIMUM_ATTENDED = "MINIMUM_ATTENDED";
export const NOT_MINIMUM_ATTENDED = "NOT_MINIMUM_ATTENDED";

export const BOTH = "BOTH";
export const ONLY_EXTRAORDINARY = "ONLY_EXTRAORDINARY";
export const ONLY_NORMAL = "ONLY_NORMAL";

export const NORMAL_USER = "NORMAL_USER";
export const DISABLED_USER = "DISABLED_USER";

export const SIGNUP_FORM_LINK = "https://forms.gle/17ZS8VFV4V4fCs4u6";

export const PAYPAL_ACTIVE = false;

export const DRIVE_DOCUMENTS_LINK = "https://drive.google.com/drive/folders/1sGz3sfOEaMsvGiud9SIikbcuBN61Cuqv?usp=sharing";
