import {createSelector} from "reselect";
import _ from 'lodash';
import moment from "moment";
import {
  ALL_ATTENDED,
  BOTH,
  ONLY_EXTRAORDINARY,
  ONLY_NORMAL
} from "../constants/misc";
import {filterAssembly} from "../helpers/misc";

//////////////////////////////////////////////////////////////////////////////

export const createLoadingSelector = (actions) => (state) => {
  // returns true only when all actions is not loading
  return _(actions)
    .some((action) => _.get(state, `api.loading.${action}`));
};

//////////////////////////////////////////////////////////////////////////////

export const eventsSelector = (eventType) => createSelector(
  (state) => state.events,
  events => events
    .filter((event) => eventType ? event.type === eventType : true)
    .sort((x, y) => moment(y.date) - moment(x.date)) // most recent to least recent
);

//////////////////////////////////////////////////////////////////////////////

export const eventsSelectorAttendedEvents = (eventType, userAttendedEvents, parameter = ALL_ATTENDED, extraordinary = BOTH) => {
  let filter = null;
  switch (extraordinary) {
    case ONLY_EXTRAORDINARY:
      filter = (event) => {
        return (eventType ? event.type === eventType : true) && event.extraordinary
      };
      break;

    case ONLY_NORMAL:
      filter = (event) => {
        return (eventType ? event.type === eventType : true) && !event.extraordinary
      };
      break;

    case BOTH:
    default:
      filter = (event) => {
        return eventType ? event.type === eventType : true
      };
  }

  return createSelector(
    (state) => state.events,
    events => {
      events = events
        .filter(filter)
        .sort((x, y) => moment(y.date) - moment(x.date)) // most recent to least recent

      const clist = filterAssembly(events, userAttendedEvents, parameter);
      return clist;
    }
  );
}

//////////////////////////////////////////////////////////////////////////////

export const boardUserSelector = createSelector(
  (state) => state.users,
  users => users
    .filter((user) => user.boardTitle)
);

//////////////////////////////////////////////////////////////////////////////

export const normalUserSelector = createSelector(
  (state) => state.users,
  users => users
    .filter((user) => !user.boardTitle)
);

//////////////////////////////////////////////////////////////////////////////

