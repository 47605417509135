import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import {getValuations} from "../../actions/valuations";

const DisplayValuations = ({user}) => {

  //////////////////////////////////////////////////////////////////////////////

  const dispatch = useDispatch();
  const valuations = useSelector((state) => state.valuations);

  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getValuations());
  }, [dispatch]);
  //////////////////////////////////////////////////////////////////////////////

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center" >

      <Typography variant="h5" component="p" gutterBottom >
        <strong>AVALÚOS</strong>
      </Typography>

      {valuations
        .filter((valuation) => user.valuations.includes(valuation._id.toString()))
        .map((valuation, i) => (
          <Grid container key={i} justifyContent="center" alignItems="center" >
            <Grid item xs={2} > </Grid>
            <Grid item xs={10} style={{paddingLeft: `${valuation.indentation * 25}px`}} >
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{'aria-label': valuation.name}}
                      checked={true}
                    />
                  }
                  label={valuation.name}
                />
              </FormGroup>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}; export default DisplayValuations;
