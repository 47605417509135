import DOMPurify from "dompurify";
import parse from "html-react-parser";

import {
  Typography,
  Link,
} from "@material-ui/core";

export default function SmartText({text, showLess = false, onClick = () => {}, length = 250}) {
  const purifiedHTML = DOMPurify.sanitize(text, {USE_PROFILES: {html: true}});
  const cleanHTML = parse(purifiedHTML);
  const plainText = purifiedHTML.toString().replace(/<[^>]*(>|$)| |‌|»|«|>/g, ' ')
    .replace(/&nbsp;/g, ' '); // hack to remove &nbsp; from smart view of event

  return (
    <Typography gutterBottom variant="body1" component="div" align="justify" paragraph m={2} style={{margin: 15, whiteSpace: "pre-line"}} >
      {showLess && plainText.length > length
        ? (<>
          {`${plainText.slice(0, length)}...`}

          < Link onClick={onClick} style={{cursor: "pointer"}} >
            Ver Más
          </Link>
        </>)
        : <>
          {cleanHTML}

          {showLess &&
            <Link onClick={onClick} style={{cursor: "pointer"}} >
              Ver Más
            </Link>
          }
        </>
      }
    </Typography>
  );
};
