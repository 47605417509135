import {useDispatch} from 'react-redux';
import {
  Grid,
  Avatar,
  Link,
  Typography,
  Container,
} from "@material-ui/core";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {SIGNUP_FORM_LINK} from "../../constants/misc";

// import SignupPayment from "../Paypal/SignupPayment";

import useStyles from "./styles";


export default function PostSignup() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Registrarse
        </Typography>

        <Typography component="p" variant="body1" gutterBottom >
          El siguiente paso después de registrarse, es llenar el formulario:
        </Typography>

        <Typography component="p" variant="body1" gutterBottom >
          <Link href={SIGNUP_FORM_LINK}>
            Formulario
          </Link>
        </Typography>

        <Typography component="p" variant="body1" gutterBottom >
          Una vez sea llenado un administrador revisara su solicitud.
        </Typography>

        <Typography component="p" variant="body1" gutterBottom >
          Si su solicitud es aceptada, solo necesita pagar una suscripción y podrá iniciar sesión.
        </Typography>

      </div>

      {/* <Grid container item justifyContent="center" alignItems="center" style={{marginTop: "20px"}} > */}
      {/*   <SignupPayment /> */}
      {/* </Grid> */}

    </Container>
  )
}
