import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  Grid,
  Button,
  Avatar,
  TextField,
  Link,
  Typography,
  Container,
  Paper,
} from "@material-ui/core";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {signin} from "../../actions/auth";

import useStyles from "./styles";


export default function Signin() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] = useState(initialState);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(signin(formData, history));
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };


  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          Acceder
        </Typography>


        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>


            <Grid item xs={12}>
              <Paper>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Correo"
                  name="email"
                  autoComplete="email"
                  type="email"
                  onChange={handleChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                />
              </Paper>
            </Grid>


          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Acceder
          </Button>
          <Grid container justifyContent="space-evenly">
            <Grid item>
              <Link href={"/contraseña"} variant="body2">
                Olvidaste tu contraseña?
              </Link>
            </Grid>

            <Grid item>
              <Link href={"/registro"} variant="body2">
                ¿No tienes cuenta? Registrate
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>

    </Container>
  )
}
