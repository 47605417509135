import {
  FETCH_ALL_COUNCIL_MEMBERS_SUCCESS,
  FETCH_ALL_PRESIDENTS_SUCCESS,
  FETCH_MEMBERS_OF_COUNCIL_SUCCESS,
} from "../../constants/actionTypes";

const councilMembersReducer = (councilMembers = [], action) => {
  switch (action.type) {
    case FETCH_ALL_COUNCIL_MEMBERS_SUCCESS:
    case FETCH_ALL_PRESIDENTS_SUCCESS:
    case FETCH_MEMBERS_OF_COUNCIL_SUCCESS:
      return action.payload;

    default:
      return councilMembers;

  }
}

export default councilMembersReducer;
