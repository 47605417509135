import {useState, useEffect, useCallback} from "react";
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Link,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import Logo from '../../assets/images/logos/logo_comaviin.png';
import Login from '../Auth/Login';
import decode from 'jwt-decode';

import {snackbar} from "../../actions/notifications";

import DrawerNavbar from "./DrawerNavbar";
import GridNavbar from "./GridNavbar";

import {isLoggedIn} from "../../api/index";

import useStyles from './styles';

export default function Navbar(props) {

  const classes = useStyles();
  const {title} = props;

  const [clickedOnce, setClickedOnce] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const logout = useCallback(() => {
    dispatch({type: 'LOGOUT'});
    setUser(null);
    history.push("/");
  }, [dispatch, history]);

  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) {
        logout();
        dispatch(snackbar("Vuelva a iniciar sesión", "error"));
      }
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location, user?.token, logout]);

  return (
    <AppBar position="relative" color="inherit" className={classes.appbar} >
      <Toolbar className={classes.toolbar}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={clickedOnce && !isLoggedIn(user) ? 0 : 4} ></Grid>

          <Grid item xs={12} md={4} lg={4} >
            <Typography
              component="div"
              color="inherit"
              align="center"
              className={classes.toolbarTitle}
            >
              <Link href={"/"} >
                <img className={classes.img} src={Logo} alt={title} />
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} md={clickedOnce && !isLoggedIn(user) ? 8 : 4} >
            <Login user={user} handleLogout={logout} clickedOnce={clickedOnce} setClickedOnce={setClickedOnce} />
          </Grid>

        </Grid>
      </Toolbar>

      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        {isMobile
          ? <DrawerNavbar user={user} />
          : <GridNavbar user={user} />
        }

      </Toolbar>
    </AppBar >
  );
}
