import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {boardUserSelector, normalUserSelector} from "../../../api/selectors";
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core';

import {getUsers} from "../../../actions/users";
import {resetLoading} from "../../../actions/general";
import {updateBoardMembers} from "../../../actions/users";

import CircularProgress from '@material-ui/core/CircularProgress';

import {redirectScroll} from "../../../helpers/misc";
import BoardList from "./BoardList";
import NormalList from "./NormalList";

import useStyles from "../styles";

export default function MarkAttendance() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const initialBoardUsers = useSelector(boardUserSelector);
  const initialNormalUsers = useSelector(normalUserSelector);

  const [boardList, setBoardList] = useState([]);
  const [normalList, setNormalList] = useState([]);
  const [filteredBoardList, setFilteredBoardList] = useState([]);
  const [filteredNormalList, setFilteredNormalList] = useState([]);

  const isLoading = useSelector((state) => state.loading?.UPDATE_BOARD_MEMBERS?.request || state.loading?.UPDATE_EVENT?.request || false);
  const isSuccess = useSelector((state) => state.loading?.UPDATE_BOARD_MEMBERS?.success || state.loading?.UPDATE_EVENT?.success || false);

  const initialState = {
    boardMembers: {}, // has id:boardTitle
    normalMembers: []
  };
  const [formData, setFormData] = useState(initialState);

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateBoardMembers(formData));
  };

  const handleChange = (e) => {
    const cpyFormData = {...formData};
    cpyFormData.boardMembers[e.target.name] = e.target.value;
    setFormData(cpyFormData);
  };

  const handleSendToMembers = (id) => (e) => {
    // display side
    const filteredBoard = boardList.filter((user) => user._id.toString() !== id.toString());
    const user = boardList.find((user) => user._id.toString() === id.toString());
    setBoardList(filteredBoard);
    setNormalList([...normalList, user]);

    // form side
    const cpyFormData = {...formData};
    delete cpyFormData.boardMembers[id];
    cpyFormData.normalMembers.push(id);
    setFormData(cpyFormData);
  };

  const handleSendToBoard = (id) => (e) => {
    // display side
    const filteredNormal = normalList.filter((user) => user._id.toString() !== id.toString());
    const user = normalList.find((user) => user._id.toString() === id.toString());
    setBoardList([...boardList, user]);
    setNormalList(filteredNormal);

    // form side
    const cpyFormData = {...formData};
    cpyFormData.boardMembers[id] = "";
    cpyFormData.normalMembers.filter(_id => _id.toString() !== id.toString());
    setFormData(cpyFormData);
  };


  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    setBoardList(initialBoardUsers);
    setNormalList(initialNormalUsers);
  }, [initialBoardUsers, initialNormalUsers]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      redirectScroll(history)("/administrador/consejo");
    }
  }, [isSuccess]);

  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container direction="row" spacing={3} justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Typography gutterBottom variant="h3" component="h3" align="center" className={classes.typography} gutterBottom>
            Actualizar Miembros de Consejo
          </Typography>
        </Grid>

        <Grid item xs={12} >
          <form className={classes.form} noValidate onSubmit={handleSubmit} >

            <Grid container direction="row" spacing={3} justifyContent="center" alignItems="flex-start" >

              <Grid item xs={6} >
                <BoardList
                  boardList={boardList}
                  filteredBoardList={filteredBoardList}
                  setFilteredBoardList={setFilteredBoardList}
                  handleChange={handleChange}
                  handleSendToMembers={handleSendToMembers}
                />
              </Grid>

              <Grid item xs={6}>
                <NormalList
                  normalList={normalList}
                  filteredNormalList={filteredNormalList}
                  setFilteredNormalList={setFilteredNormalList}
                  handleSendToBoard={handleSendToBoard}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isLoading}
            >
              {isLoading
                ? (<>
                  <CircularProgress />
                </>)
                : "Editar Miembros del Consejo"
              }
            </Button>
          </form >
        </Grid>
      </Grid >
    </>
  );
}
