import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  Grid,
  Button,
  Avatar,
  TextField,
  Link,
  Typography,
  Container,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {signup} from "../../actions/auth";
import {redirectScroll} from "../../helpers/misc";

import useStyles from "./styles";


export default function Signup() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const isSuccess = useSelector((state) => state.loading?.AUTH_SIGNUP?.success || false);

  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    acceptTerms: false,
  };
  const [formData, setFormData] = useState(initialState);

  ////////////////////////////////////////////////////////////////////////////

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(signup(formData, history));
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };


  const handleChangeCheckbox = (e) => {
    setFormData({...formData, [e.target.name]: e.target.checked});
  };

  ////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (isSuccess) {
      redirectScroll(history)("/postregistro");
    }
  }, [isSuccess]);

  ////////////////////////////////////////////////////////////////////////////

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5" gutterBottom>
          Registrarse
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
              <Paper>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="Nombres"
                  autoFocus
                  onChange={handleChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Paper>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Apellidos"
                  name="lastName"
                  autoComplete="lname"
                  onChange={handleChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Correo"
                  name="email"
                  autoComplete="email"
                  type="email"
                  onChange={handleChange}
                />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Contraseña"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  inputProps={{
                    minLength: 8,
                    maxLength: 256,
                  }}
                />
              </Paper>

              <Typography variant="body2" gutterBottom> </Typography>
              <Typography variant="body2" >
                <em>Al menos 8 caracteres</em>
              </Typography>

            </Grid>

            <Grid item xs={12}>
              <Paper>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirmar contraseña"
                  type="password"
                  id="confirmPassword"
                  onChange={handleChange}
                />
              </Paper>

              {formData.password && formData.confirmPassword && formData.password !== formData.confirmPassword &&
                <>
                  <Typography variant="body2" gutterBottom> </Typography>
                  <Typography variant="body2" color="error" >
                    <em>Contraseñas no concuerdan</em>
                  </Typography>
                </>
              }

            </Grid>

            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptTerms"
                      id="acceptTerms"
                      inputProps={{'aria-label': 'Acepto terminos y condiciones de uso'}}
                      checked={formData.published}
                      onChange={handleChangeCheckbox}
                    />
                  }
                  label="Acepto terminos y condiciones de uso"
                />
              </FormGroup>
            </Grid>

          </Grid>


          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              !(formData.firstName &&
                formData.lastName &&
                formData.email &&
                formData.password &&
                formData.confirmPassword &&
                formData.acceptTerms)
            }
          >
            Registrarse
          </Button>

          <Typography variant="body2" gutterBottom >
            ¿Ya se registro anteriormente? Vaya al <Link href="/postregistro" >pos-registro</Link> para ver cual es el siguiente paso para completar su registro.
          </Typography>

          <Grid container justifyContent="flex-end">
            <Grid item >
              <Link href={"/acceder"} variant="body1">
                ¿Ya tienes cuenta? Accede
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>

    </Container>
  )
}
