import {FETCH_FEATURED_EVENTS, FETCH_FEATURED_EVENTS_AND_BIRTHDAYS} from '../constants/actionTypes';

const featuredEventsReducer = (featuredEvents = [], action) => {
  switch (action.type) {
    case FETCH_FEATURED_EVENTS:
      return action.payload;

    case FETCH_FEATURED_EVENTS_AND_BIRTHDAYS:
      return action.payload;

    default:
      return featuredEvents;
  }
};

export default featuredEventsReducer;
