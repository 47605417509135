import {useState, useEffect} from 'react';
import {useSelector, useDispatch, shallowEqual} from "react-redux";
import {Link} from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  Grid,
  Tooltip,
} from '@material-ui/core/';
import clsx from "clsx";
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import SearchFilter from '../SearchFilter/SearchFilter';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import ConfirmDialog from "../Utilities/ConfirmDialog";

import {isAdmin} from "../../api/index";
import {eventsSelectorAttendedEvents} from "../../api/selectors";
import {
  getAllEvents,
  deleteEvent,
  clearEvent,
  getPurchasedEvents,
  getAttendedEvents,
  getPurchasableEvents,
} from "../../actions/events";
import {
  ALL_EVENTS,
  PURCHASED_EVENTS,
  ATTENDED_EVENTS,
  PURCHASABLE_EVENTS,
} from "../../constants/events";

import {ALL_ATTENDED, BOTH} from "../../constants/misc";
import {EVENT} from "../../constants/events";
import useStyles from "./styles";


export default function ListEvents(props) {
  const classes = useStyles();

  const {
    eventType,
    listOfEvents = ALL_EVENTS,
    onEventClick = () => {},
    showControlButtons = false,
    parameter = ALL_ATTENDED,
    extraordinary = BOTH,
  } = props;
  const [user, setUser] = useState({});
  const userId = user?._id;
  const events = useSelector(eventsSelectorAttendedEvents(eventType, user?.attendedEvents, parameter, extraordinary), shallowEqual);
  const event = useSelector((state) => state.event.event);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile"))?.data);
  }, []);

  const [eventsList, setEventsList] = useState(events);
  useEffect(() => {
    setEventsList(events);
  }, [events]);
  const admin = isAdmin(JSON.parse(localStorage.getItem("profile"))?.data);

  const handleEditEvent = (event) => {
    onEventClick(event); // set selected event
  }

  const confirmDelete = (event) => {
    onEventClick(event); // set selected event
    setShowDeleteDialog(true);
  }

  const handleDeleteEvent = () => {
    dispatch(deleteEvent(event._id));
    dispatch(clearEvent(event._id));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    switch (listOfEvents) {
      case ALL_EVENTS:
        dispatch(getAllEvents());
        break;

      case PURCHASED_EVENTS:
        if (userId)
          dispatch(getPurchasedEvents(userId));
        break;

      case ATTENDED_EVENTS:
        if (userId)
          dispatch(getAttendedEvents(userId));
        break;

      case PURCHASABLE_EVENTS:
        if (userId)
          dispatch(getPurchasableEvents());
        break;


      default:
        if (userId)
          dispatch(getAllEvents());
    }
  }, [dispatch, listOfEvents, userId]);


  const [pageNumber, setPageNumber] = useState(0);
  const eventsPerPage = 8;
  const pagesVisited = pageNumber * eventsPerPage;
  const pageCount = Math.ceil(eventsList.length / eventsPerPage);


  function changePage({selected}) {
    setPageNumber(selected);
  }

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <>
      <SearchFilter
        list={events}
        setFilteredList={setEventsList}
        filter={EVENT}
      />

      <List className={classes.root} >

        {eventsList
          .slice(pagesVisited, pagesVisited + eventsPerPage)
          .map((event) => (
            <div key={event._id} >
              <ListItem alignItems="flex-start" component={Button} onClick={() => onEventClick(event)}>
                <ListItemAvatar>
                  <Avatar alt="Fecha" className={classes[event.type]} >
                    {moment(event.date).format("DD")}
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={event.title}
                  secondary={" - " + moment(event.date).format("YYYY/MM/DD")}
                >
                </ListItemText>


                {showControlButtons && admin &&
                  <ListItemSecondaryAction>
                    {event.attendable &&
                      <Tooltip title="Asistencias" arrow>
                        <IconButton edge="end" aria-label="attendance"  >
                          <Link className={classes.noAnchorStyle} to={{
                            pathname: `/administrador/eventos/asistencias/${event._id}`,
                            state: {event: event}
                          }}>
                            <EventAvailableIcon />
                          </Link>
                        </IconButton>
                      </Tooltip>

                    }
                    <Tooltip title="Editar" arrow>
                      <IconButton edge="end" aria-label="edit" onClick={() => handleEditEvent(event)} >
                        <Link className={classes.noAnchorStyle} to={{
                          pathname: `/administrador/${event.type.toLowerCase()}s/editar/${event._id}`,
                          state: {event: event}
                        }}>
                          <EditIcon />
                        </Link>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Borrar" arrow>
                      <IconButton edge="end" aria-label="delete" onClick={() => confirmDelete(event)} >
                        <Link to="#" className={classes.noAnchorStyle} >
                          <DeleteIcon />
                        </Link>
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                }
              </ListItem>

              <Divider variant="inset" component="li" />
            </div>
          ))}

      </List>


      {eventsList.length !== 0
        ? (<ReactPaginate
          previousLabel={"Anterior"}
          nextLabel={"Siguiente"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={classes.paginationBttns}
          previousLinkClassName={clsx(classes.previousBttn, "MuiPaper-root", "MuiPaper-elevation1")}
          nextLinkClassName={clsx(classes.nextBttn, "MuiPaper-root", "MuiPaper-elevation1")}
          disabledClassName={classes.paginationDisabled}
          activeClassName={classes.paginationActive}
        />)
        : (<Box textAlign="center"><Typography>
          {`NO SE ENCONTRARON RESULTADOS`}
        </Typography></Box>)
      }

      {showDeleteDialog &&
        (<ConfirmDialog
          title="¿Esta seguro que desea eliminar el evento?"
          onConfirm={handleDeleteEvent}
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
        >
          <Grid container spacing={3} direction="column" justifyContent="center" >
            <Grid item >
              Esta intentando eliminar el evento con titulo <em><strong>{event?.title}</strong></em>.
            </Grid>

            <Grid item >
              Si elimina el evento, ya no podra verse, y los usuarios perderan acceso al evento dentro de la pagina, aunque hayan pagado por el.
            </Grid>


            <Grid item >
              Esta accion no podra deshacerse, <strong>¿esta seguro?</strong>
            </Grid>
          </Grid>
        </ConfirmDialog>)
      }
    </>
  );
}

