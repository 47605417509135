import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import {getUsers} from "../../actions/users";

import NO_AVATAR from "../../assets/images/no_avatar.png";

import {shuffle} from "../../helpers/misc";

import useStyles from "./styles";

export default function LandingDirectory(props) {
  const {maxMembers, random} = props;

  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const Members = useSelector((state) => state.users);
  const [members, setMembers] = useState([]);


  useEffect(() => {
    if (maxMembers) {
      if (random) {
        setMembers(shuffle(Members).slice(0, maxMembers));
      } else {
        setMembers(Members.slice(0, maxMembers));
      }
    } else {
      if (random) {
        setMembers(shuffle(Members));
      } else {
        setMembers(Members);
      }
    }
  }, [Members]);

  return (
    <div >
      <Typography align="center" variant="h4" gutterBottom className={classes.typography} >
        <strong>DIRECTORIO</strong>
      </Typography>

      <Grid container item xs={12} spacing={1} alignItems="center" justifyContent="center" >

        {members.map((item, i) => (
          <Grid item xs={12} md={6} lg={4} key={i} style={{heigth: "100%"}} >
            <Paper >
              <IconButton className={classes.button} >
                <Link href={"/directorio"} >
                  <img
                    className={classes.img}
                    src={item.avatar ? item.avatar : NO_AVATAR}
                    alt={item.title}
                  />
                </Link>
              </IconButton>

              <Grid item xs={12} >
                <Typography variant="body1" color="textPrimary" component="h5" style={{textAlign: "center"}} >
                  {`${item.firstName} ${item.lastName}`}
                </Typography>
              </Grid>

              <Grid item xs={12} >
                <Typography variant="body1" color="textSecondary" component="h5" style={{textAlign: "center"}} >
                  {item.title}
                </Typography>
              </Grid>

            </Paper>
          </Grid>
        ))}

      </Grid>
    </div>
  );
}

