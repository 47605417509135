import {
  AUTH,
  AUTH_SIGNUP_REQUEST,
  AUTH_SIGNUP_SUCCESS,
  AUTH_SIGNUP_FAILURE,
  UNAPPROVED,
  SEND_FORGOT_PASSWORD_MAIL_REQUEST,
  SEND_FORGOT_PASSWORD_MAIL_SUCCESS,
  SEND_FORGOT_PASSWORD_MAIL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../constants/actionTypes";
import * as API from "../api/index.jsx";
import {snackbar} from "../actions/notifications";

export const signin = (formData, history, setErrorHandler) => async (dispatch) => {
  try {
    // login the user
    const {data} = await API.signin(formData);
    dispatch({type: AUTH, data});

    history.push("/");
  } catch (error) {
    if (error.response.status === 401) {
      dispatch({type: UNAPPROVED, payload: error.response.data.message});
    }
    setErrorHandler({hasError: true, message: error.response.data.message});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

export const signup = (formData, history) => async (dispatch) => {
  try {
    dispatch({type: AUTH_SIGNUP_REQUEST});
    const {data} = await API.signup(formData);

    dispatch({type: AUTH_SIGNUP_SUCCESS, payload: data});
    dispatch(snackbar(data.message, "success"));
  } catch (error) {
    console.log(error);
    dispatch({type: AUTH_SIGNUP_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

export const sendForgotPasswordMail = (email) => async (dispatch) => {
  try {
    dispatch({type: SEND_FORGOT_PASSWORD_MAIL_REQUEST});
    const {data} = await API.sendForgotPasswordMail(email);

    dispatch({type: SEND_FORGOT_PASSWORD_MAIL_SUCCESS, payload: data});
    dispatch(snackbar(data.message, "success"));
  } catch (error) {
    console.log(error);
    dispatch({type: SEND_FORGOT_PASSWORD_MAIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

export const resetPassword = (formData) => async (dispatch) => {
  try {
    dispatch({type: RESET_PASSWORD_REQUEST});
    const {data} = await API.resetPassword(formData);

    dispatch({type: RESET_PASSWORD_SUCCESS, payload: data});
    dispatch(snackbar(data.message, "success"));
  } catch (error) {
    console.log(error);
    dispatch({type: RESET_PASSWORD_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

