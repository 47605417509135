import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import moment from "moment";

import {
  Grid,
  Typography,
} from '@material-ui/core';

import Calendar from '../Calendar/Calendar';
import IndividualEvent from "../Events/IndividualEvent";

import {getEvent} from "../../actions/events";
import {getBirthday} from "../../actions/users";
import {EVENT, ASSEMBLY, COURSE, BIRTHDAY} from "../../constants/events";

import useStyles from "./styles";

export default function LandingCalendar() {
  const classes = useStyles();

  const event = useSelector((state) => state.event.event);
  // get latest course of the featured events
  const featuredEvents = useSelector((state) => state.featuredEvents).filter((event) => event.type === COURSE)
    .sort((e1, e2) => moment(e2.date) - moment(e1.date)); // sorts from latest to oldest

  const [autoScroll, setAutoScroll] = useState(false);
  const [loadFeaturedEvent, setLoadFeaturedEvent] = useState(true);
  useEffect(() => {
    const type = featuredEvents.length ? featuredEvents[0]?.type : "";
    const id = featuredEvents.length ? featuredEvents[0]?._id : "";

    if (loadFeaturedEvent && [EVENT, ASSEMBLY, COURSE, BIRTHDAY].includes(type) && id) {
      switch (type) {
        case EVENT:
        case ASSEMBLY:
        case COURSE:
          dispatch(getEvent(id));
          break;

        case BIRTHDAY:
          dispatch(getBirthday(id));
          break;

        default:
          console.log("ERROR, no correct type!");
          break;
      }

      setLoadFeaturedEvent(false);
    }
  }, [featuredEvents, loadFeaturedEvent]);

  const [eventInfo, setEventInfo] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    if ([EVENT, ASSEMBLY, COURSE, BIRTHDAY].includes(eventInfo.type) && eventInfo.id) {
      switch (eventInfo.type) {
        case EVENT:
        case ASSEMBLY:
        case COURSE:
          dispatch(getEvent(eventInfo.id));
          break;

        case BIRTHDAY:
          dispatch(getBirthday(eventInfo.id));
          break;

        default:
          console.log("ERROR, no correct type!");
          break;
      }
    }

    //return () => {
    //source.cancel("Unmounting component...");
    //}
  }, [dispatch, eventInfo]);

  const onEventClick = (event) => {
    setEventInfo({type: event.type, id: event._id});
    setAutoScroll(true);
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        className={classes.root}
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid item xs={12} className={classes.section} >
          <Typography align="center" variant="h4" gutterBottom className={classes.typography} >
            <strong>CALENDARIO</strong>
          </Typography>
        </Grid>


        <Grid item xs={12} md={6} className={classes.section} >
          <Calendar onEventClick={onEventClick} />
        </Grid>


        <Grid item xs={12} md={6} className={classes.section} >
          <IndividualEvent event={event} abridged={true} autoScroll={autoScroll} />
        </Grid>

      </Grid>

    </>
  );
}
