import {
  FETCH_ALL_COUNCILS_REQUEST,
  FETCH_ALL_COUNCILS_SUCCESS,
  FETCH_ALL_COUNCILS_FAILURE,
  FETCH_ALL_COUNCILS_AND_MEMBERS_REQUEST,
  FETCH_ALL_COUNCILS_AND_MEMBERS_SUCCESS,
  FETCH_ALL_COUNCILS_AND_MEMBERS_FAILURE,
  FETCH_SINGLE_COUNCIL_REQUEST,
  FETCH_SINGLE_COUNCIL_SUCCESS,
  FETCH_SINGLE_COUNCIL_FAILURE,
  FETCH_CURRENT_COUNCIL_REQUEST,
  FETCH_CURRENT_COUNCIL_SUCCESS,
  FETCH_CURRENT_COUNCIL_FAILURE,
  FETCH_MEMBERS_OF_COUNCIL_REQUEST,
  FETCH_MEMBERS_OF_COUNCIL_SUCCESS,
  FETCH_MEMBERS_OF_COUNCIL_FAILURE,
  CREATE_COUNCIL_REQUEST,
  CREATE_COUNCIL_SUCCESS,
  CREATE_COUNCIL_FAILURE,
  EDIT_COUNCIL_REQUEST,
  EDIT_COUNCIL_SUCCESS,
  EDIT_COUNCIL_FAILURE,
  DELETE_COUNCIL_REQUEST,
  DELETE_COUNCIL_SUCCESS,
  DELETE_COUNCIL_FAILURE,
} from '../constants/actionTypes';
import * as api from '../api/index.jsx';
import {snackbar} from "../actions/notifications";

export const getCouncils = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_ALL_COUNCILS_REQUEST});

    const {data} = await api.fetchCouncils();
    dispatch({type: FETCH_ALL_COUNCILS_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_ALL_COUNCILS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getCouncilsAndMembers = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_ALL_COUNCILS_AND_MEMBERS_REQUEST});

    const {data} = await api.fetchCouncilsAndMembers();
    dispatch({type: FETCH_ALL_COUNCILS_AND_MEMBERS_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_ALL_COUNCILS_AND_MEMBERS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getCurrentCouncil = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_CURRENT_COUNCIL_REQUEST});

    const {data} = await api.fetchCurrentCouncil();
    dispatch({type: FETCH_CURRENT_COUNCIL_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_CURRENT_COUNCIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getCouncil = (id) => async (dispatch) => {
  try {
    dispatch({type: FETCH_SINGLE_COUNCIL_REQUEST});

    const {data} = await api.fetchCouncil(id);
    dispatch({type: FETCH_SINGLE_COUNCIL_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_SINGLE_COUNCIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getMembersOfCouncil = (id) => async (dispatch) => {
  try {
    dispatch({type: FETCH_MEMBERS_OF_COUNCIL_REQUEST});

    const {data} = await api.fetchMembersOfCouncil(id);
    dispatch({type: FETCH_MEMBERS_OF_COUNCIL_SUCCESS, payload: data.data});

  } catch (error) {
    dispatch({type: FETCH_MEMBERS_OF_COUNCIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const createCouncil = (formData) => async (dispatch) => {
  try {
    dispatch({type: CREATE_COUNCIL_REQUEST});

    const {data} = await api.createCouncil(formData);
    dispatch({type: CREATE_COUNCIL_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: CREATE_COUNCIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const editCouncil = (id, formData) => async (dispatch) => {
  try {
    dispatch({type: EDIT_COUNCIL_REQUEST});

    const {data} = await api.editCouncil(id, formData);
    dispatch({type: EDIT_COUNCIL_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: EDIT_COUNCIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const deleteCouncil = (id) => async (dispatch) => {
  try {
    dispatch({type: DELETE_COUNCIL_REQUEST});

    const {data} = await api.deleteCouncil(id);
    dispatch({type: DELETE_COUNCIL_SUCCESS, payload: data.data});
    dispatch(getCouncils());
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: DELETE_COUNCIL_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};
