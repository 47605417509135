import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Tooltip,
  Avatar,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import CircularProgress from '@material-ui/core/CircularProgress';

import {resetLoading} from "../../actions/general";
import {updatePassword} from "../../actions/users";
import {redirectScroll} from "../../helpers/misc";

import useStyles from "./styles";

export default function UpdateProfile() {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"))?.data;
  const isLoading = useSelector((state) => state.loading?.UPDATE_PASSWORD?.request || state.loading?.UPDATE_EVENT?.request || false);
  const isSuccess = useSelector((state) => state.loading?.UPDATE_PASSWORD?.success || state.loading?.UPDATE_EVENT?.success || false);


  const initialState = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const [formData, setFormData] = useState(initialState);


  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updatePassword(user?._id, formData));
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      dispatch({type: 'LOGOUT'});
      redirectScroll(history)("/");
    }
  }, [dispatch, isSuccess]);
  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Grid container direction="column" justifyContent="center" alignItems="center" >
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
              Cambiar Contraseña
            </Typography>
          </Grid>
        </Grid>

        <form className={classes.form} noValidate onSubmit={handleSubmit} >
          <Grid container direction="row" spacing={3} >
            <Grid item xs={12}>
              <Tooltip title="Contraseña Actual" arrow >
                <Paper>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="currentPassword"
                    id="currentPassword"
                    type="password"
                    label="Contraseña Actual"
                    autoComplete="current-password"
                    onChange={handleChange}
                  />
                </Paper>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Nueva Contraseña" arrow >
                <Paper>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="password"
                    id="password"
                    type="password"
                    label="Nueva Contraseña"
                    onChange={handleChange}
                  />
                </Paper>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Tooltip title="Escriba la misma contraseña que arriba" arrow >
                <Paper>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    label="Confirmar Contraseña"
                    onChange={handleChange}
                  />
                </Paper>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
              >
                {isLoading
                  ? (<>
                    <CircularProgress />
                  </>)
                  : "Cambiar Contraseña"
                }
              </Button>
            </Grid>

          </Grid>
        </form>
      </Grid>
    </>
  );
}
