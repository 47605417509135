import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {getUser, getDisabledUser} from "../../actions/users";

import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core';

import DisplayPersonalData from "../Utilities/DisplayPersonalData";
import DisplayEducation from "../Utilities/DisplayEducation";
import DisplayValuations from "../Utilities/DisplayValuations";
import {NORMAL_USER} from "../../constants/misc";

import NO_AVATAR from "../../assets/images/no_avatar.png";

import useStyles from "./styles";

export default function IndividualUser({typeUser = NORMAL_USER}) {
  const classes = useStyles();
  const normalUser = useSelector((state) => state.user || null);
  const disabledUser = useSelector((state) => state.disabledUser || null);
  const location = useLocation();
  let userId = location.pathname.split("/").pop();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  // effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (typeUser === NORMAL_USER) {
      dispatch(getUser(userId));
    } else {
      dispatch(getDisabledUser(userId));
    }
  }, [dispatch, typeUser]);

  useEffect(() => {
    if (typeUser === NORMAL_USER) {
      setUser(normalUser);
    } else {
      setUser(disabledUser);
    }
  }, [normalUser, disabledUser, typeUser]);

  // effects /////////////////////////////////////////////////////////////////

  return (
    <>
      {user &&
        <Grid container justifyContent="center" alignItems="center" >
          <Grid item xs={12} md={8} xl={6} >
            <Card className={classes.root}>
              <Grid container alignItems="center" justifyContent="center" style={{marginTop: "1em", marginBottom: "1em"}}>
                <CardMedia
                  component="img"
                  alt="Foto usuario"
                  image={user.avatar ? user.avatar : NO_AVATAR}
                  title={`${user?.firstName} ${user?.lastName}`}
                  className={classes.img}
                />

              </Grid>

              <Grid container alignItems="center" justifyContent="center" style={{textAlign: "center"}} >
                <Grid item xs={12} >
                  <Typography gutterBottom variant="h4" component="h4">
                    {`${user?.firstName} ${user?.lastName}`}
                  </Typography>
                </Grid>

                <Grid item xs={12} >
                  <Typography gutterBottom variant="h5" component="h5" color="textSecondary" >
                    {user?.memberTitle}
                  </Typography>
                </Grid>

                {user.memberId &&
                  <Grid item xs={12} >
                    <Typography gutterBottom variant="h5" component="h5" color="textSecondary" >
                      Número de miembro: {user?.memberId}
                    </Typography>
                  </Grid>
                }
              </Grid>

              <CardContent>
                <Grid container >
                  <Grid item xs={12} md={6} lg={4} >
                    <DisplayPersonalData user={user} />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} >
                    <DisplayEducation education={[user?.degrees, user?.masters, user?.specialties, user?.phds]} />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4} >
                    <DisplayValuations user={user} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </>
  );
}
