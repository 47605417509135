import {isLoggedIn, isAdmin} from "../../api/index";

const sections = [
  {title: 'Inicio', url: '/'},
  {title: 'Administrador', url: '/administrador', condition: isAdmin},
  {title: 'Miembros', url: '/miembros', condition: isLoggedIn},
  {title: 'Consejo', url: '/consejos'},
  {title: 'Directorio', url: '/directorio'},
  {title: 'Eventos', url: '/eventos/calendario'},
  {title: 'Contáctanos', url: '/contacto'},
  //{title: 'Quienes Somos', url: '/aboutus'},
];

export default sections;
