import {useState} from "react";
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import ListIcon from '@material-ui/icons/List';

import useStyles from "./styles";


export default function AdminEventSublist(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const {type, Icon} = props;
  const typeLower = type.toLowerCase();
  const typeCapital = typeLower.charAt(0).toUpperCase() + typeLower.slice(1);

  return (
    <>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={typeCapital} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>
          <Link href={`/administrador/${typeLower}/crear`} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary={`Crear ${typeCapital}`} />
            </ListItem>
          </Link>

          <Link href={`/administrador/${typeLower}/lista`} >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={`Lista ${typeCapital}`} />
            </ListItem>
          </Link>
        </List>
      </Collapse>
    </>
  );
}
