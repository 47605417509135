import {useParams} from "react-router-dom";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
} from '@material-ui/core';
import services from "./ServicesList";


import useStyles from "./styles";


export default function Avaluo() {
  const classes = useStyles();
  const {serviceUrl} = useParams();

  const valuation = services.find((service) => service.url === serviceUrl);

  return (
    <>
      {valuation
        ? (<>
          <Typography
            component="h2"
            variant="h2"
            color="inherit"
            align="center"
            gutterBottom
            className={classes.sectionTitle}
          >
            <strong>Avalúo: {valuation.title}</strong>
          </Typography>

          <Grid container spacing={4} justifyContent="center" alignItems="center" >
            <Grid item xs={12} md={10} >
              <Card className={classes.card} >
                {valuation.image &&
                  <CardMedia
                    component="img"
                    className={classes.cardMedia}
                    image={valuation.image}
                    title="Image title"
                  />
                }

                <CardContent className={classes.cardContent}>
                  <Typography
                    component="p"
                    variant="body1"
                    align="center"
                    style={{
                      whiteSpace: "pre-line",
                      textAlign: "justify"
                    }}
                    gutterBottom>
                    {valuation.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>)
        :

        <Typography
          component="h3"
          variant="h3"
          color="inherit"
          align="center"
          gutterBottom
          className={classes.sectionTitle}
        >
          <strong>No se encontró el servicio seleccionado</strong>
        </Typography>

      }
    </>
  );
}

