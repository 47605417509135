import {
  Button,
  ButtonGroup,
  Paper,
} from '@material-ui/core';
import {EVENT, BIRTHDAY, ASSEMBLY, COURSE} from "../../constants/events";

import useStyles from "./styles";

export default function EventTabs() {
  const classes = useStyles();

  return (
    <>
      <Paper>
        <ButtonGroup
          fullWidth
          variant="outlined"
          aria-label="outlined button group"
          color="default"
        >
          <Button href={"/eventos/calendario"} style={{textTransform: "capitalize"}}>
            <strong>Calendario</strong>
          </Button>
            <Button href={"/eventos/asambleas"} variant="contained" className={classes[ASSEMBLY]} style={{textTransform: "capitalize"}}>
            <strong>Lista Asambleas</strong>
          </Button>
          <Button href={"/eventos/eventos"} variant="contained" className={classes[EVENT]} style={{textTransform: "capitalize"}}>
            <strong>Lista Eventos</strong>
          </Button>
          <Button href={"/eventos/cursos"} variant="contained" className={classes[COURSE]} style={{textTransform: "capitalize"}}>
            <strong>Lista Cursos</strong>
          </Button>
        </ButtonGroup>
      </Paper>
    </>
  );
}

