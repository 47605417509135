import {
  FETCH_ALL_USERS,
  FETCH_NON_HIDDEN_USERS,
  APPROVE_USER,
  FETCH_BIRTHDAY,
  MARK_ATTENDANCE_REQUEST,
  MARK_ATTENDANCE_SUCCESS,
  MARK_ATTENDANCE_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_DISABLED_USER_PROFILE_REQUEST,
  UPDATE_DISABLED_USER_PROFILE_SUCCESS,
  UPDATE_DISABLED_USER_PROFILE_FAILURE,
  UPDATE_STUDIES_REQUEST,
  UPDATE_STUDIES_SUCCESS,
  UPDATE_STUDIES_FAILURE,
  UPDATE_DISABLED_USER_STUDIES_REQUEST,
  UPDATE_DISABLED_USER_STUDIES_SUCCESS,
  UPDATE_DISABLED_USER_STUDIES_FAILURE,
  FETCH_PREMIUM_USERS_REQUEST,
  FETCH_PREMIUM_USERS_SUCCESS,
  FETCH_PREMIUM_USERS_FAILURE,
  UPDATE_BOARD_MEMBERS_REQUEST,
  UPDATE_BOARD_MEMBERS_SUCCESS,
  UPDATE_BOARD_MEMBERS_FAILURE,
  FETCH_NUMBER_ATTENDED_EVENTS_REQUEST,
  FETCH_NUMBER_ATTENDED_EVENTS_SUCCESS,
  FETCH_NUMBER_ATTENDED_EVENTS_FAILURE,
  ENABLE_USER_REQUEST,
  ENABLE_USER_SUCCESS,
  ENABLE_USER_FAILURE,
  DISABLE_USER_REQUEST,
  DISABLE_USER_SUCCESS,
  DISABLE_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_SINGLE_DISABLED_USER_REQUEST,
  FETCH_SINGLE_DISABLED_USER_SUCCESS,
  FETCH_SINGLE_DISABLED_USER_FAILURE,
  FETCH_DISABLED_USERS_REQUEST,
  FETCH_DISABLED_USERS_SUCCESS,
  FETCH_DISABLED_USERS_FAILURE,
  UPDATE_USER_VALUATIONS_REQUEST,
  UPDATE_USER_VALUATIONS_SUCCESS,
  UPDATE_USER_VALUATIONS_FAILURE,
  DELETE_DISABLED_USER_REQUEST,
  DELETE_DISABLED_USER_SUCCESS,
  DELETE_DISABLED_USER_FAILURE,
} from "../constants/actionTypes";
import {NORMAL_USER} from "../constants/misc";
import * as API from "../api/index.jsx";
import {snackbar} from "../actions/notifications";

// gets all non hidden users
export const getUsers = (includeHidden = false) => async (dispatch) => {
  try {
    if (includeHidden) {
      const {data} = await API.fetchUsers();
      dispatch({type: FETCH_ALL_USERS, payload: data});
    } else {
      const {data} = await API.fetchNonHiddenUsers();
      dispatch({type: FETCH_NON_HIDDEN_USERS, payload: data});
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({type: FETCH_USER_REQUEST});
    const {data} = await API.fetchUser(id);

    dispatch({type: FETCH_USER_SUCCESS, payload: data});
  } catch (error) {
    console.log(error);

    dispatch({type: FETCH_USER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getDisabledUser = (userId) => async (dispatch) => {
  try {
    dispatch({type: FETCH_SINGLE_DISABLED_USER_REQUEST});
    const {data} = await API.fetchDisabledUser(userId);

    dispatch({type: FETCH_SINGLE_DISABLED_USER_SUCCESS, payload: data});
  } catch (error) {
    console.log(error);
    dispatch({type: FETCH_SINGLE_DISABLED_USER_FAILURE});
  }
};

export const getDisabledUsers = () => async (dispatch) => {
  try {
    dispatch({type: FETCH_DISABLED_USERS_REQUEST});
    const {data} = await API.fetchDisabledUsers();

    dispatch({type: FETCH_DISABLED_USERS_SUCCESS, payload: data});
  } catch (error) {
    console.log(error);
    dispatch({type: FETCH_DISABLED_USERS_FAILURE});
  }
};

export const approveUser = (id) => async (dispatch) => {
  try {
    const {data} = await API.approveUser(id);

    dispatch({type: APPROVE_USER, payload: data});
  } catch (error) {
    console.log(error);
  }
}

export const enableUser = (id) => async (dispatch) => {
  try {
    dispatch({type: ENABLE_USER_REQUEST});
    const {data} = await API.enableUser(id);

    dispatch({type: ENABLE_USER_SUCCESS, payload: data});
    dispatch(snackbar(data?.message, "success"));
  } catch (error) {
    console.log(error);

    dispatch({type: ENABLE_USER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

export const disableUser = (id) => async (dispatch) => {
  try {
    dispatch({type: DISABLE_USER_REQUEST});
    const {data} = await API.disableUser(id);

    dispatch({type: DISABLE_USER_SUCCESS, payload: data});
    dispatch(snackbar(data?.message, "success"));
  } catch (error) {
    console.log(error);

    dispatch({type: DISABLE_USER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
}

export const getBirthday = (id) => async (dispatch) => {
  try {
    const {data} = await API.fetchBirthday(id);

    dispatch({type: FETCH_BIRTHDAY, payload: data});
  } catch (error) {
    console.log(error);
  }
}

export const upsertAttendance = (attendance, eventId) => async (dispatch) => {
  try {
    dispatch({type: MARK_ATTENDANCE_REQUEST});

    const {data} = await API.upsertAttendance(attendance, eventId);
    dispatch({type: MARK_ATTENDANCE_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: MARK_ATTENDANCE_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const updatePassword = (id, formData) => async (dispatch) => {
  try {
    dispatch({type: UPDATE_PASSWORD_REQUEST});

    const {data} = await API.updatePassword(id, formData);
    dispatch({type: UPDATE_PASSWORD_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    dispatch({type: UPDATE_PASSWORD_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const updateProfile = (id, formData, userType = NORMAL_USER) => async (dispatch) => {
  try {
    let data = null;
    const localData = JSON.parse(localStorage.getItem("profile"));
    const currentUser = localData?.data;
    const token = localData?.token;

    if (userType === NORMAL_USER) {
      dispatch({type: UPDATE_PROFILE_REQUEST});
      data = await API.updateProfile(id, formData);
      dispatch({type: UPDATE_PROFILE_SUCCESS, payload: data.data});
    } else {
      dispatch({type: UPDATE_DISABLED_USER_PROFILE_REQUEST});
      data = await API.updateDisabledProfile(id, formData);
      dispatch({type: UPDATE_DISABLED_USER_PROFILE_SUCCESS, payload: data.data});
    }

    data = data.data; //unfortunately had to do this
    if (currentUser._id.toString() === data.data._id.toString()) {
      localStorage.setItem("profile", JSON.stringify({data: data.data, token: token}));
    }
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    if (userType === NORMAL_USER) {
      dispatch({type: UPDATE_PROFILE_FAILURE});
    } else {
      dispatch({type: UPDATE_DISABLED_USER_PROFILE_FAILURE});
    }
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const updateStudies = (id, formData, userType = NORMAL_USER) => async (dispatch) => {
  try {
    let data = null;
    const localData = JSON.parse(localStorage.getItem("profile"));
    const currentUser = localData?.data;
    const token = localData?.token;

    if (userType === NORMAL_USER) {
      dispatch({type: UPDATE_STUDIES_REQUEST});
      data = await API.updateStudies(id, formData);
      dispatch({type: UPDATE_STUDIES_SUCCESS, payload: data.data});
    } else {
      dispatch({type: UPDATE_DISABLED_USER_STUDIES_REQUEST});
      data = await API.updateDisabledStudies(id, formData);
      dispatch({type: UPDATE_DISABLED_USER_STUDIES_SUCCESS, payload: data.data});
    }

    data = data.data; //unfortunately had to do this
    if (currentUser._id.toString() === data.data._id.toString()) {
      localStorage.setItem("profile", JSON.stringify({data: data.data, token: token}));
    }
    dispatch(snackbar(data.message, "success"));

  } catch (error) {
    if (userType === NORMAL_USER) {
      dispatch({type: UPDATE_STUDIES_FAILURE});
    } else {
      dispatch({type: UPDATE_DISABLED_USER_STUDIES_FAILURE});
    }
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getPremiumUsers = (eventId) => async (dispatch) => {
  try {
    dispatch({type: FETCH_PREMIUM_USERS_REQUEST});

    const {data} = await API.fetchPremiumUsers(eventId);
    dispatch({type: FETCH_PREMIUM_USERS_SUCCESS, payload: data.data});
  } catch (error) {
    dispatch({type: FETCH_PREMIUM_USERS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const updateBoardMembers = (formData) => async (dispatch) => {
  try {
    dispatch({type: UPDATE_BOARD_MEMBERS_REQUEST});

    const {data} = await API.updateBoardMembers(formData);
    dispatch({type: UPDATE_BOARD_MEMBERS_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));
  } catch (error) {
    dispatch({type: UPDATE_BOARD_MEMBERS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const getNumberAttendedEvents = (userId, typeEvent) => async (dispatch) => {
  try {
    dispatch({type: FETCH_NUMBER_ATTENDED_EVENTS_REQUEST});

    const {data} = await API.getNumberAttendedEvents(userId, typeEvent);
    dispatch({type: FETCH_NUMBER_ATTENDED_EVENTS_SUCCESS, payload: data.data});
  } catch (error) {
    dispatch({type: FETCH_NUMBER_ATTENDED_EVENTS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const updateValuations = (userId, formData) => async (dispatch) => {
  try {
    dispatch({type: UPDATE_USER_VALUATIONS_REQUEST});

    const {data} = await API.updateValuations(userId, formData);
    dispatch({type: UPDATE_USER_VALUATIONS_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));
  } catch (error) {
    dispatch({type: UPDATE_USER_VALUATIONS_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};

export const deleteDisabledUser = (id) => async (dispatch) => {
  try {
    dispatch({type: DELETE_DISABLED_USER_REQUEST});

    const {data} = await API.deleteDisabledUser(id);
    dispatch({type: DELETE_DISABLED_USER_SUCCESS, payload: data.data});
    dispatch(snackbar(data.message, "success"));
  } catch (error) {
    dispatch({type: DELETE_DISABLED_USER_FAILURE});
    dispatch(snackbar(error.response?.data?.message, "error"));
  }
};
