import {
  FETCH_PUBLISHED_EVENTS,
} from '../constants/actionTypes';

const publishedEventsReducer = (publishedEvents = [], action) => {
  switch (action.type) {
    case FETCH_PUBLISHED_EVENTS:
      return action.payload;

    default:
      return publishedEvents;
  }
};

export default publishedEventsReducer;

