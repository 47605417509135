import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";

import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import EventTabs from './EventTabs';
import ListEvents from './ListEvents';
import IndividualEvent from "./IndividualEvent";

import {getEvent} from "../../actions/events";
import {getBirthday, getNumberAttendedEvents} from "../../actions/users";
import {EVENT, ASSEMBLY, COURSE, BIRTHDAY, ALL_EVENTS} from "../../constants/events";
import {ALL_ATTENDED, MINIMUM_ATTENDED, NOT_MINIMUM_ATTENDED, BOTH} from "../../constants/misc";

import clsx from "clsx";
import useStyles from "./styles";

export default function EventView(props) {
  const classes = useStyles();
  const {
    renderEventTabs = true,
    autoScroll,
    eventType,
    showControlButtons = false,
    listOfEvents = ALL_EVENTS,
    showAttendances = false,
    unlockPaywall = false,
    extraordinary = BOTH,
    useStateUser = false
  } = props;
  const event = useSelector((state) => state.event.event);
  const numberAttendances = useSelector((state) => state.attendances.attended);
  const totalAttendances = useSelector((state) => state.attendances.total);
  const stateUser = useSelector((state) => state.user);

  const location = useLocation();
  let arrPath = location.pathname.split("/");
  const [eventInfo, setEventInfo] = useState({
    type: arrPath[arrPath.length - 2],
    id: arrPath[arrPath.length - 1],
  });

  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [assemblyFilter, setAssemblyFilter] = useState(ALL_ATTENDED);

  // effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (eventInfo.type && eventInfo.id) {
      switch (eventInfo.type) {
        case EVENT:
        case ASSEMBLY:
        case COURSE:
          dispatch(getEvent(eventInfo.id));
          break;

        case BIRTHDAY:
          dispatch(getBirthday(eventInfo.id));
          break;
      }
    }
  }, [dispatch, eventInfo]);

  useEffect(() => {
    if (useStateUser) {
      setUser(stateUser);
    } else {
      setUser(JSON.parse(localStorage.getItem("profile"))?.data);
    }
  }, [useStateUser, stateUser]);

  useEffect(() => {
    if (eventType === ASSEMBLY && user?._id) {
      dispatch(getNumberAttendedEvents(user?._id, eventType));
    }
  }, [dispatch, eventType, user]);

  // effects /////////////////////////////////////////////////////////////////

  // on click ////////////////////////////////////////////////////////////////
  const onEventClick = (event) => {
    setEventInfo({type: event.type, id: event._id});
  }

  const handleChangeAssemblyFilter = (e) => {
    setAssemblyFilter(e.target.value);
  }
  // on click ////////////////////////////////////////////////////////////////

  return (
    <>
      <Grid
        container
        spacing={3}
        className={classes.root}
        justifyContent="center"
        alignItems="stretch"
      >
        {renderEventTabs &&
          <Grid item xs={12} className={classes.section} >
            <EventTabs />
          </Grid>
        }

        {eventType === ASSEMBLY && showAttendances &&
          (<>
            <Grid item xs={12} className={classes.section} >
              <Typography gutterBottom variant="h3" component="h3" align="center" >
                Asistencias: {numberAttendances} / {totalAttendances}
              </Typography>
            </Grid>

            <Grid container justifyContent="center" >
              <Grid item >
                <FormControl component="fieldset">
                  <FormLabel component="legend">Filtrar Asambleas</FormLabel>
                  <RadioGroup row aria-label="assemblyFilter" name="assemblyFilter" value={assemblyFilter} onChange={handleChangeAssemblyFilter}>
                    <FormControlLabel value={ALL_ATTENDED} control={<Radio />} label="Todos los asistidos" />
                    <FormControlLabel value={MINIMUM_ATTENDED} control={<Radio />} label="Solo aquellos que cumplen mínimo asistencia" />
                    <FormControlLabel value={NOT_MINIMUM_ATTENDED} control={<Radio />} label="Solo aquellos que no cumplen mínimo asistidos" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </>)
        }

        <Grid item xs={12} md={4} className={clsx(classes.section, classes.sticky)} >
          <ListEvents
            eventType={eventType}
            onEventClick={onEventClick}
            showControlButtons={showControlButtons}
            listOfEvents={listOfEvents}
            parameter={assemblyFilter}
            extraordinary={extraordinary}
          />
        </Grid>

        <Grid item xs={12} md={8} className={classes.section} >
          <IndividualEvent event={event} autoScroll={autoScroll} showEnableButton={unlockPaywall} specificUser={user} />
        </Grid>

      </Grid>

    </>
  );
}
