import {useState, useEffect} from 'react';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  Drawer,
  Divider,
  IconButton,
  Container,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

import useStyles from "./styles";


export default function Dashboard({Content, ListItems, ...rest}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [firstTime, setFirstTime] = useState(true);
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (isMobile && !firstTime) {
      setOpen(false);
      setFirstTime(false);
    }
  }, [isMobile]);

  return (
    <>
      <div className={classes.flex}>
        <Drawer
          variant={isMobile && open ? "temporary" : "permanent"}
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <>
            <div className={classes.toolbarIcon}>
              <IconButton onClick={toggleDrawer}>
                {open
                  ? <ChevronLeftIcon />
                  : <ChevronRightIcon />
                }
              </IconButton>
            </div>
            <Divider />

            {(!isMobile || open) &&
              <>
                <ListItems />
                <Divider />
              </>
            }

          </>
        </Drawer>
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <Content {...rest} />
          </Container>
        </main>
      </div>

    </>
  );
}
