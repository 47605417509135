// fix for not being able to install and run react-leaflet
// https://stackoverflow.com/questions/63423384/you-may-need-an-additional-loader-to-handle-the-result-of-these-loaders
import {makeStyles} from '@material-ui/core/styles';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import LocationMarker from '../../assets/icons/LocationMarker.svg';
import Grid from '@material-ui/core/Grid';

export default function MapView() {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },

    map: {
      height: "401px",
      width: "100%",
    },
  }));

  const classes = useStyles();

  // lat lng location. In this case, this points to UNISON
  const position = [29.061983, -110.988734];

  const myIcon = new L.Icon({
    iconUrl: LocationMarker,
    iconRetinaUrl: LocationMarker,
    popupAnchor: [-0, -0],
    iconSize: [32, 45]
  });

  return (
    <Grid container alignItems="center" justifyContent="center" className={classes.root} >
      <MapContainer className={classes.map} center={position} zoom={15} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} icon={myIcon}>
          <Popup>
            COMAVIIN - Colegio de Maestros en Valuación Inmobiliaria Industrial y de Negocios, COMAVIIN A.C.
          </Popup>
        </Marker>
      </MapContainer>
    </Grid>
  )
}

