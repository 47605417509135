import {useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Paper,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

import DegreeInput from "../../Utilities/DegreeInput";

import CircularProgress from '@material-ui/core/CircularProgress';

import {updateStudies, getUser, getDisabledUser} from "../../../actions/users";
import {resetLoading} from "../../../actions/general";

import {redirectScroll} from "../../../helpers/misc";
import {NORMAL_USER} from "../../../constants/misc";
import {ADMIN} from "../../../constants/roles";

import useStyles from "../styles";

export default function UpdateStudies({userType = NORMAL_USER}) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading?.UPDATE_STUDIES?.request || false);
  const isSuccess = useSelector((state) => state.loading?.UPDATE_STUDIES?.success || false);
  const normalUser = useSelector((state) => state.user);
  const disabledUser = useSelector((state) => state.disabledUser);
  const [user, setUser] = useState(null);
  const [fillForm, setFillForm] = useState(true);

  const userId = location.pathname.split("/").pop();

  const initialState = {
    memberId: user?.memberId,
    memberTitle: user?.memberTitle,
    degrees: user?.degrees?.length ? user.degrees : [],
    masters: user?.masters?.length ? user.masters : [],
    specialties: user?.specialties?.length ? user.specialties : [],
    phds: user?.phds?.length ? user.phds : [],

    isAdmin: user?.roles.includes(ADMIN) || false,
    hidden: user?.hidden || false,
  };

  const [formData, setFormData] = useState({});

  //handlers ////////////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(updateStudies(user?._id, formData, userType));
  };

  const handleChangeGeneral = (name, value) => {
    setFormData({...formData, [name]: value});
  };

  const handleChangeCheckbox = (e) => {
    setFormData({...formData, [e.target.name]: e.target.checked});
  };
  //handlers ////////////////////////////////////////////////////////////////

  //effects /////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (isSuccess) {
      dispatch(resetLoading());
      const returnRoute = userType === NORMAL_USER
        ? "/administrador/usuarios"
        : "/administrador/usuarios/deshabilitados";
      redirectScroll(history)(returnRoute);
    }
  }, [dispatch, isSuccess]);

  useEffect(() => {
    if (fillForm && user) {
      setFormData(initialState);
      setFillForm(false);
    }
  }, [initialState, fillForm, user]);

  useEffect(() => {
    if (userType === NORMAL_USER) {
      dispatch(getUser(userId));
    } else {
      dispatch(getDisabledUser(userId));
    }
  }, [dispatch, userId, userType]);

  useEffect(() => {
    if (userType === NORMAL_USER) {
      setUser(normalUser);
    } else {
      setUser(disabledUser);
    }
  }, [userType, normalUser, disabledUser]);
  //effects /////////////////////////////////////////////////////////////////


  return (
    <>
      <Grid container justifyContent="center" alignItems="center" >
        <Grid item xs={12} >
          <Grid container direction="column" justifyContent="center" alignItems="center" >
            <Typography gutterBottom variant="h2" component="h2" align="center" className={classes.typography} >
              Editar datos como administrador
            </Typography>
          </Grid>
        </Grid>


        {Object.keys(formData).length
          ? <form className={classes.form} noValidate onSubmit={handleSubmit} >
            <Grid container spacing={3} >

              <Grid item xs={12}>
                <FormGroup row>
                  <Tooltip title="Si selecciona el usuario tendrá derechos de administrador. Podrá crear/modificar/borrar usuarios, eventos y más. Solo haga administrador a usuarios en los que confie." arrow >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isAdmin"
                          id="isAdmin"
                          inputProps={{'aria-label': 'Administrador'}}
                          checked={formData.isAdmin}
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label="Administrador"
                    />
                  </Tooltip>
                </FormGroup>
              </Grid>

              <Grid item xs={12}>
                <FormGroup row>
                  <Tooltip title="Si selecciona, el usuario no se mostrara en el directorio de miembros. Pero todavia se puede ver su perfil usando su ID." arrow >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="hidden"
                          id="hidden"
                          inputProps={{'aria-label': 'Esconder'}}
                          checked={formData.hidden}
                          onChange={handleChangeCheckbox}
                        />
                      }
                      label="Esconder"
                    />
                  </Tooltip>
                </FormGroup>
              </Grid>



              <Grid item xs={12}>
                <Tooltip title="Identificador del miembro." arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="memberId"
                      id="memberId"
                      label="Número de miembro"
                      onChange={(e) => handleChangeGeneral(e.target.name, e.target.value)}
                      defaultValue={formData.memberId}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <Tooltip title="Título del miembro únicamente editable por administrador. Se mostrará en la información del miembro en el directorio" arrow >
                  <Paper>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="memberTitle"
                      id="memberTitle"
                      label="Título"
                      onChange={(e) => handleChangeGeneral(e.target.name, e.target.value)}
                      defaultValue={formData.memberTitle}
                    />
                  </Paper>
                </Tooltip>
              </Grid>

              <Grid item xs={12}>
                <DegreeInput
                  fullWidth={true}
                  variant="outlined"
                  name="degrees"
                  title="Licenciaturas"
                  handleChange={handleChangeGeneral}
                  defaultValue={formData.degrees}
                />
              </Grid>


              <Grid item xs={12}>
                <DegreeInput
                  fullWidth={true}
                  variant="outlined"
                  name="masters"
                  title="Maestrias"
                  handleChange={handleChangeGeneral}
                  defaultValue={formData.masters}
                />
              </Grid>


              <Grid item xs={12}>
                <DegreeInput
                  fullWidth={true}
                  variant="outlined"
                  name="specialties"
                  title="Especialidades"
                  handleChange={handleChangeGeneral}
                  defaultValue={formData.specialties}
                />
              </Grid>


              <Grid item xs={12}>
                <DegreeInput
                  fullWidth={true}
                  variant="outlined"
                  name="phds"
                  title="Doctorados"
                  handleChange={handleChangeGeneral}
                  defaultValue={formData.phds}
                />
              </Grid>


              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isLoading}
              >
                {isLoading
                  ? (<>
                    <CircularProgress />
                  </>)
                  : "Editar"
                }
              </Button>

            </Grid>
          </form>
          : <CircularProgress />
        }
      </Grid>
    </>
  );
}
